import { TabHeadingProps, tabConfigProps } from 'pages/Dashboard/AllDynamicWidgets/domain/types';
import React from 'react'; 
import classNames from 'utilities/ClassNames';
import TextOverflow from 'utilities/TextOverflow';



const TabsHeading:React.FC<TabHeadingProps> = ({ tabs, currentTab, setCurrentTab}) => {
  return (
    <>
      {tabs.map((tab: tabConfigProps, index: number) => (
        <div
          onClick={() => setCurrentTab(index)}
          key={`${tab.id}-${index}`}
          className={classNames(
            currentTab === index ? "text-green" : "text-white",
            "text-sm cursor-pointer whitespace-nowrap duration-300 capitalize hover:text-green font-bold"
          )}
        >
          <span className={""}>
            {TextOverflow(tab.name, 20)}
          </span>
        </div>
      ))}
    </>
  );
};

export default TabsHeading; 
