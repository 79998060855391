import { chartConfigurationsProps, configDataProps } from "../domain/types";

export   const handleChange = (key: string, value: string, graphtype:string, setgraph:React.Dispatch<React.SetStateAction<chartConfigurationsProps | configDataProps>>) => {
    setgraph((prev: any) => {
      if (key === "name" || graphtype === "area") {
        return {
          ...prev,
          name: value,
        };
      } else {
        const keys = key.split(".");
        return {
          ...prev,
          [keys[0]]: {
            ...prev[keys[0]],
            [keys[1]]: value,
          },
        };
      }
    });
  };