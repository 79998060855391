import { ModalProps } from "./domain/types";
import classNames from "utilities/ClassNames";
import { useEffect, useState, useContext } from "react";
import { useLocation } from 'react-router-dom';
import { ModalContext } from "hooks/ModalProvider";
import DangerModal from "./DangerModal/DangerModal";
import ShowTicketModal from "./ShowTicketModal/ShowTicketModal";
import ChartModal from "./ChartModal/ChartModal";
import ActionModal from "./ActionModal/ActionModal";
import MappingModal from "./MappingModal/MappingModal";
import InfoModal from "./InfoModal/InfoModal";
import PdfPreviewModal from "./PdfPreviewModal/PdfPreviewModal";

const Modal: React.FC<ModalProps> = ({ page, selectedTag, setSelectedTag, isLoading, refresh, isAlarm, listing }) => {
  const { modalType, setModalType } = useContext(ModalContext);
  const [isTicket, setIsTicket] = useState(false);
  const [isBigGraph, setIsBigGraph] = useState(false);
  const { action } = selectedTag;
  const location = useLocation();

  const actionClasses: any = {
    chart: isBigGraph ? "w-[60%] h-[70vh] gradient" : isTicket ? "w-[35%] h-[70vh]" : "w-[50%] xl:w-[35%] h-max gradient",
    delete: "w-[20%] min-w-[300px]",
    reset: "w-[20%] min-w-[540px]",
    new: "w-[45%]",
    edit: "w-[60%] xl:w-[45%] min-w-[540px]",
    mapping: "w-[63%]",
    mappingDocs: "w-[63%]",
    report: "w-[63%]",
    settings: "w-[82%] xl:h-[75vh] h-[74vh]",
    gantt: "w-[95%]",
    map: "w-[63%]",
    pdfViewer: "w-[60%]",
  };

  const containerClasses = classNames(
    actionClasses[action] || "",
    action === 'pdfViewer' ? "top-[54.5%] 2xl:top-[54%] 3xl:top-[55%]" : "top-[55%]",
    "h-auto mx-auto p-0.5 rounded-md z-50 absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2"
  );

  const handleClick = () => {
    if (!isTicket) {
      setModalType('');
      setSelectedTag({ action: "", value: null });
    }
    if (isTicket) {
      setIsTicket(false);
      setSelectedTag({ action: "chart", value: selectedTag.value });
    }
  };

  useEffect(() => {
    setModalType('');
    setSelectedTag({ action: "", value: null });
  }, [location]);

  if (!action) return null;


  return (
    <div className="fixed inset-0 z-10 w-full h-screen">
      <div
        onClick={handleClick}
        className="h-full absolute z-20 inset-0 w-full"
      />
      <div className={containerClasses}>
        {action === 'pdfViewer' && <PdfPreviewModal setSelectedTag={setSelectedTag} setModalType={setModalType} selectedTag={selectedTag} />}
        {(action === 'delete' || action === 'reset' || action === 'reject' || action === 'deleteAll' || action === 'unmap') && <DangerModal type={action} page={page} selectedTag={selectedTag} setSelectedTag={setSelectedTag} isLoading={isLoading} refresh={refresh} />}
        {(action === 'showTicket') && <ShowTicketModal selectedTag={selectedTag} />}
        {(action === 'close' || action === 'acknowledge' || action === 'acknowledgeAll') && <InfoModal type={action} page={page} selectedTag={selectedTag} setSelectedTag={setSelectedTag} isLoading={isLoading} refresh={refresh} />}
        {!isTicket && (action === 'chart' && (page === 'tags' || page === 'expressions' || page === 'alarms')) &&
          <ChartModal page={page} refresh={refresh} selectedTag={selectedTag} setSelectedTag={setSelectedTag} isAlarm={isAlarm} setTicket={setIsTicket} setIsBigGraph={setIsBigGraph} isBigGraph={isBigGraph} />
        }
        {(action === 'edit' || action === 'new') && <ActionModal page={page} type={action} selectedTag={selectedTag} setSelectedTag={setSelectedTag} refresh={refresh} listing={listing} />}
        {(action === 'mapping') && <MappingModal page='tags' listing={listing} setSelectedTag={setSelectedTag} refresh={refresh} />}
        {(action === 'mappingDocs') && <MappingModal page='docs' tag={selectedTag.value} listing={listing} setSelectedTag={setSelectedTag} refresh={refresh} />}
        {isTicket && <ActionModal page='tickets' type='new' selectedTag={selectedTag} setSelectedTag={setSelectedTag} refresh={refresh} />}
      </div>
    </div>
  );
};

export default Modal;
