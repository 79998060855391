import { getTagData } from "api/tags";
import { getExpressionData } from "api/expression";

const graphContainerConfig = {
  tag: {
    apiCall: getTagData
  },
  expression: {
    apiCall: getExpressionData
  }
};

export default graphContainerConfig;