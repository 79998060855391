import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { cn } from "@/lib/utils";
import useScreen from "hooks/useScreen";
import {
  FiEdit,
  FiMoreVertical,
  FiPlusSquare,
  FiSettings,
  FiTrash,
  FiTrash2,
} from "react-icons/fi";
import classNames from "utilities/ClassNames";

export default function Menu(props: any) {
  const { isScaled } = useScreen();
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="hover:text-blue duration-300 cursor-pointer">
          <FiMoreVertical size={isScaled ? 18 : 20} />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className={classNames(
          isScaled ? "w-40" : "w-44",
          "bg-dark border-slate absolute -right-2 top-2"
        )}
      >
        <DropdownMenuGroup>
          {props.tabsLength < 4 && props.tabsLength !== 0 && (
            <DropdownMenuItem
              onClick={props.newTab}
              className="cursor-pointer group p-0"
            >
              <div className={cn(isScaled?"py-1":"py-1.5","w-full h-full px-1.5  flex items-center gap-2 rounded-md group-hover:bg-black")}>
                <FiPlusSquare
                  className={classNames(isScaled ? "h-3 w-3" : "h-4 w-4")}
                />
                <span className={classNames(isScaled ? "text-xs" : "")}>
                  New Tab
                </span>
              </div>
            </DropdownMenuItem>
          )}
          <DropdownMenuItem
            onClick={props.editTab}
            className="cursor-pointer group p-0"
          >
            <div className={cn(isScaled?"py-1":"py-1.5","w-full h-full px-1.5  flex items-center gap-2 rounded-md group-hover:bg-black")}>
              <FiEdit
                className={classNames(isScaled ? "h-3 w-3" : "h-4 w-4")}
              />
              <span className={classNames(isScaled ? "text-xs" : "")}>
                Edit Tab
              </span>
            </div>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={props.configureTab}
            className="cursor-pointer group p-0"
          >
            <div className={cn(isScaled?"py-1":"py-1.5","w-full h-full px-1.5  flex items-center gap-2 rounded-md group-hover:bg-black")}>
              <FiSettings
                className={classNames(isScaled ? "h-3 w-3" : "h-4 w-4")}
              />
              <span className={classNames(isScaled ? "text-xs" : "")}>
                Configure Tab
              </span>
            </div>
          </DropdownMenuItem>

          <DropdownMenuItem
            onClick={props.deleteTab}
            className="cursor-pointer group p-0"
          >
            <div className={cn(isScaled?"py-1":"py-1.5","w-full h-full px-1.5  flex items-center gap-2 rounded-md group-hover:bg-black")}>
              <FiTrash
                className={classNames(isScaled ?"h-3 w-3" : "h-4 w-4")}
              />
              <span className={classNames(isScaled ? "text-xs" : "")}>
                Delete Tab
              </span>
            </div>
          </DropdownMenuItem>
          {props.deleteWidget && (
            <DropdownMenuItem
              onClick={props.deleteWidget}
              className="cursor-pointer group p-0"
            >
              <div className={cn(isScaled?"py-1":"py-1.5","w-full h-full px-1.5  flex items-center gap-2 rounded-md group-hover:bg-black")}>
                <FiTrash
                  className={classNames(isScaled ? "h-3 w-3" : "h-4 w-4")}
                />
                <span className={classNames(isScaled ? "text-xs" : "")}>
                  Delete Widget
                </span>
              </div>
            </DropdownMenuItem>
          )}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
