import { getTagsPastData, getExpressionPastData } from "api/dashboard";
import { ChartDataFetchParams, Point } from "./types";
import { areaConfig } from "../config/configurations";

const getChartData = async ({ type, itemId, isDaily, areaType }: ChartDataFetchParams): Promise<{ dataList?: Point[]; predictionList?: Point[]; valueSet?: number[]; }> => {

  const { days } = areaConfig[areaType];

  try {
    const response: any = type === "tag"
      ? await getTagsPastData(itemId, days, isDaily ? "D" : "H")
      : await getExpressionPastData(itemId, days, isDaily ? "D" : "H");

    const { data, status } = response || {};

    if (data && status === 200) {
      const dataList = data
        .map((item: any) => ({
          x: new Date(item.createdOn).getTime(),
          y: type === 'tag' ? item.tagValue : item.expressionValue,
        }));

      const valueSet = data
        .map((item: any) => type === 'tag' ? item.tagValue : item.expressionValue);

      return { dataList, valueSet };
    }

    return {};
  } catch (e) {
    console.error('Error fetching the request:', e);
    throw e;
  }
};

export default getChartData;
