import { ColorCodes } from "types/common";

const colorCodes: ColorCodes = {
  white: "#555555",
  red: "#f95460",
  orange: "#ffb554",
  redShadow: "#f95460",
  green: "#4799E9",
  greenShadow: "#4799E9",
};

export default colorCodes;