import { Item } from "types/common";

function getHighestValue(dataArray: Item[]) {
  let highestValue = -Infinity; // start with the smallest possible number

  dataArray.forEach((item) => {
    if (item.value > highestValue) {
      highestValue = item.value;
    }
  });

  return highestValue;
}

export default getHighestValue;