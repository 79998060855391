import { getExpressionData } from "api/expression";
import { getTagData } from "api/tags";
import { createContext, useContext, useEffect, useState } from "react";

export const WidgetsDataContext = createContext<any | null>(null);

export const WidgetsDataProvider = ({ children }: { children: any }) => {
  const [expressionsIds, setExpressionsIds] = useState<any>(
    JSON.parse(localStorage.getItem("expressionsIds") ?? "[]")
  );
  const [tagsIds, setTagsIds] = useState<any>(
    JSON.parse(localStorage.getItem("tagsIds") ?? "[]")
  );
  const [expressions, setExpressions] = useState(null);
  const [tags, setTags] = useState(null);

  useEffect(() => {
    (async () => {
      const response:any = await getExpressionData(expressionsIds);
      if (response.status === 200) {
        setExpressions(response.data);
      }
    })();
  }, [expressionsIds]);

  useEffect(() => {
    (async () => {
      const response:any = await getTagData(tagsIds);
      if (response.status === 200) {
        setTags(response.data);
      }
    })();
  }, [tagsIds]);

  return (
    <WidgetsDataContext.Provider
      value={{
        expressionsIds,
        setExpressionsIds,
        tagsIds,
        setTagsIds,
        expressions,
        tags,
      }}
    >
      {children}
    </WidgetsDataContext.Provider>
  );
};

export default function useWidgetData() {
  return useContext(WidgetsDataContext);
}
