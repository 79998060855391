import { OutlinedInput } from "@mui/material";
import { fontSize, styled } from "@mui/system";

export const StyledOutlinedInput = styled(OutlinedInput)({
    "&.MuiOutlinedInput-root": {
      border: "1px solid rgb(22,245,211)",
      "&:hover": {
        border: "none",
      },
      "&.Mui-focused": {
        border: "none",
      },
    },
  });  