import { Checkbox } from "@radix-ui/react-checkbox";
import { Popover, PopoverTrigger, PopoverContent } from "@radix-ui/react-popover";
import { getEquipmentsByUnitId } from "api/equipments";
import { getBubble, updateBubble, createBubble, deleteBubble } from "api/unity";
import { Command, CommandInput, CommandEmpty, CommandGroup, CommandItem } from "cmdk";
import { SmallLoader } from "components/shared/Loader";
import { useState, useEffect } from "react";
import { FiX, FiChevronDown } from "react-icons/fi";
import classNames from "utilities/ClassNames";
import TextOverflow from "utilities/TextOverflow";

const Bubble = ({
  bubbleId,
  bubbleDetails,
  closeHandler,
  updateBubbles,
  units,
  equipments,
  pauseUnity,
  resumeUnity,
  selectedUnit,
}: any) => {
  // const { isScaled } = useScreen();
  const [loading, setLoading] = useState(true);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [values, setValues] = useState<any>();
  const [openSelectUnit, setOpenSelectUnit] = useState(false);
  const [openSelectEquipment, setOpenSelectEquipment] = useState(false);
  const [selectUnitLabel, setSelectUnitLabel] = useState("");
  const [selectEquipmentLabel, setSelectEquipmentLabel] = useState("");
  const [showModelId, setShowModelId] = useState<any>(false);
  const [bubbleData, setBubbleData] = useState<any>(null);
  const [allEquipments, setAllEquipments] = useState<any>(equipments);
  const editMode = Boolean(bubbleId);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    unit: null,
    equipment: null,
    modelID: "",
    showModelId: false,
  });

  useEffect(() => {
    pauseUnity();
    return () => resumeUnity();
  }, []);

  useEffect(() => {
    (async () => {
      if (formData.unit) {
        const result = await getEquipmentsByUnitId(formData.unit);
        setAllEquipments(result.data);
      }
    })();
  }, [formData]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (editMode) {
        const bubble = await getBubble(bubbleId);
        setBubbleData(bubble.data);
        setValues({
          ...values,
          id: bubble.data.id,
          x: bubble.data.x,
          y: bubble.data.y,
          z: bubble.data.z,
          prefabParentId: bubble.data.prefabParentId,
          prefabId: bubble.data.prefabId,
          equipmentId: bubble.data.equipmentId,
          unitId: bubble.data.unitId,
        });
        setFormData((prevData) => ({
          ...prevData,
          name: bubble.data.name,
          description: bubble.data.desc,
          modelID: bubble.data.unityModelId,
        }));
        if (bubble.data.unitId) {
          setSelectUnitLabel(
            units.filter((unit: any) => unit.id === bubble.data.unitId)[0].name
          );
          setFormData((prevData) => ({
            ...prevData,
            unit: units.filter((unit: any) => unit.id === bubble.data.unitId)[0].id,
          }));
        }
        if (selectedUnit) {
          setSelectUnitLabel(
            units.filter((unit: any) => unit.id === selectedUnit)[0].name
          );
          setFormData((prevData) => ({
            ...prevData,
            unit: units.filter((unit: any) => unit.id === selectedUnit)[0].id,
          }));
        }
        if (bubble.data.equipmentId) {
          setSelectEquipmentLabel(
            equipments.filter(
              (equipment: any) => equipment.id === bubble.data.equipmentId
            )[0].name
          );

          setFormData((prevData) => ({
            ...prevData,
            equipment: equipments.filter(
              (equipment: any) => equipment.id === bubble.data.equipmentId
            )[0].id
          }));
        }
      }
    })();
    setLoading(false);
  }, [bubbleId]);


  const bubbleHandler = async (e: any) => {
    setSubmittingForm(true);
    e.preventDefault();
    if (editMode) {
      await updateBubble({
        name: formData.name,
        desc: formData.description,
        unitId: formData.unit,
        equipmentId: formData.equipment,
        unityModelId: formData.modelID,
        x: values.x,
        y: values.y,
        z: values.z,
        id: values.id,
        prefabParentId: values.prefabParentId,
        prefabId: values.prefabId,
      });
      updateBubbles();
      setSubmittingForm(false);
    } else {
      await createBubble({
        name: formData.name,
        desc: formData.description,
        unitId: formData.unit,
        equipmentId: formData.equipment,
        unityModelId: formData.modelID,
        x: bubbleDetails.x,
        y: bubbleDetails.y,
        z: bubbleDetails.z,
      });
      updateBubbles();
      setSubmittingForm(false);
    }
    closeHandler();
  };

  const deleteHandler = async () => {
    setSubmittingForm(true);
    if (bubbleId) {
      const text =
        "Closing the window will delete the bubble. Are you sure you want to close ?";
      if (window.confirm(text) === true) {
        await deleteBubble(bubbleId);
        setValues(null);
        updateBubbles();
      }
    }
    closeHandler();
  };

  return (
    <div className="fixed inset-0 z-10 w-full h-screen">
      <div
        className={classNames("h-full",
          "absolute z-20 inset-0 w-full bg-black/70"
        )}
      />

      <div className={classNames("top-1/2", "w-full h-auto rounded-md z-30 absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:max-w-[650px] opacity-95")}>        <div className="bg-dark p-3 w-full h-full rounded-md">
        {loading && (
          <div className="w-full h-[300px] grid place-content-center">
            <SmallLoader />
          </div>
        )}
        {!loading && (
          <>
            {/* Header */}
            <div className="flex justify-between">
              <h1 className="text-2xl font-bold text-green">
                Bubble Configuration
              </h1>
              <div
                className="bg-green p-1 rounded-md cursor-pointer grid place-content-center"
                onClick={closeHandler}
              >
                <FiX size={20} />
              </div>
            </div>
            {/* Content */}
            <div className="mt-5">
              <form className="mt-3" onSubmit={bubbleHandler}>
                <div className="mt-3">
                  <label htmlFor="name">Name</label>
                  <input
                    id="name"
                    name="name"
                    placeholder="Name"
                    type="text"
                    required={true}
                    className="input mt-1"
                    value={formData.name}
                    onChange={(e: any) => setFormData((prevData) => ({ ...prevData, name: e.target.value }))}
                  />
                </div>
                <div className="mt-3">
                  <label htmlFor="desc">Description</label>
                  <textarea
                    rows={5}
                    id="desc"
                    name="desc"
                    placeholder="Description"
                    className="input mt-1 max-h-[35vh]"
                    value={formData.description}
                    onChange={(e: any) => setFormData((prevData) => ({ ...prevData, description: e.target.value }))}
                  />
                </div>

                <div className="flex items-center gap-3 mt-3">
                  {/* units */}
                  <div className="w-1/2">
                    <div className="mb-2">Unit</div>
                    <Popover
                      open={openSelectUnit}
                      onOpenChange={setOpenSelectUnit}
                    >
                      <PopoverTrigger asChild>
                        <div className="w-full text-sm whitespace-nowrap h-9 px-4 py-2 justify-between cursor-pointer inline-flex items-center rounded-md border-2 border-slate bg-black">
                          {selectUnitLabel && selectUnitLabel !== ""
                            ? TextOverflow(selectUnitLabel, 27)
                            : "Select Unit"}
                          <FiChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </div>
                      </PopoverTrigger>
                      <PopoverContent
                        className="w-[300px] ml-0.5 p-0 bg-black command z-50 border-1 border-white outline outline-1 outline-gray"
                        sideOffset={4}
                        align="start"
                      >
                        <Command className="bg-dark border-slate">
                          <CommandInput
                            placeholder="Search"
                            className="bg-gray border-b-slate w-full pl-1 outline outline-gray"
                          />
                          <CommandEmpty>No units found.</CommandEmpty>
                          {units && (
                            <CommandGroup className="bg-gray border-slate h-[230px] overflow-hidden overflow-y-scroll">
                              <div>
                                {units &&
                                  units.map((item: any) => (
                                    <CommandItem
                                      className="pl-3"
                                      key={item.id}
                                      onSelect={(currentValue) => {
                                        setFormData((prevData) => ({ ...prevData, unit: item.id }));
                                        setSelectUnitLabel(item.name);
                                        setOpenSelectUnit(false);
                                      }}
                                    >
                                      {item.name}
                                    </CommandItem>
                                  ))}
                              </div>
                            </CommandGroup>
                          )}
                        </Command>
                      </PopoverContent>
                    </Popover>
                  </div>

                  {/* equipments */}
                  <div className="w-1/2">
                    <div className="mb-2">Equipment</div>
                    <Popover
                      open={openSelectEquipment}
                      onOpenChange={setOpenSelectEquipment}
                    >
                      <PopoverTrigger asChild>
                        <div className="w-full text-sm whitespace-nowrap h-9 px-4 py-2 justify-between cursor-pointer inline-flex items-center rounded-md border-2 border-slate bg-black">
                          {selectEquipmentLabel && selectEquipmentLabel !== ""
                            ? TextOverflow(selectEquipmentLabel, 27)
                            : "Select Equipment"}
                          <FiChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </div>
                      </PopoverTrigger>
                      <PopoverContent
                        className="w-[300px] ml-0.5 p-0 bg-black command z-50 border-1 border-white outline outline-1 outline-gray"
                        sideOffset={4}
                        align="start"
                      >
                        <Command className="bg-dark border-slate">
                          <CommandInput
                            placeholder="Search"
                            className="bg-gray border-b-slate w-full pl-1 outline outline-gray"
                          />
                          <CommandEmpty>No units found.</CommandEmpty>
                          {allEquipments && (
                            <CommandGroup className="bg-gray border-slate h-[230px] overflow-hidden overflow-y-scroll">
                              <div>
                                {allEquipments &&
                                  allEquipments.map((item: any) => (
                                    <CommandItem
                                      className="pl-3"
                                      key={item.id}
                                      onSelect={(currentValue) => {
                                        setFormData((prevData) => ({ ...prevData, equipment: item.id }));
                                        setSelectEquipmentLabel(item.name);
                                        setOpenSelectEquipment(false);
                                      }}
                                    >
                                      {item.name}
                                    </CommandItem>
                                  ))}
                              </div>
                            </CommandGroup>
                          )}
                        </Command>
                      </PopoverContent>
                    </Popover>
                  </div>
                </div>

                <div className="flex items-center space-x-2 mt-6">
                  <Checkbox
                    id="model"
                    checked={showModelId}
                    onCheckedChange={setShowModelId}
                  />
                  <label
                    htmlFor="model"
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  >
                    Assign 3D Model ID for Highlighting.
                  </label>
                </div>

                {showModelId && (
                  <div className="mt-6">
                    <label htmlFor="modelId">Model ID</label>
                    <input
                      id="modelId"
                      name="modelId"
                      placeholder="Model ID"
                      type="text"
                      required={true}
                      className="input mt-1"
                      value={formData.modelID}
                      onChange={(e: any) => setFormData((prevData) => ({ ...prevData, modelID: e.target.value }))}
                    />
                  </div>
                )}

                <div className="flex flex-row-reverse mt-5 gap-5">
                  <input
                    name="create"
                    id="create"
                    key="create"
                    value={editMode ? "Update" : "Create"}
                    type="submit"
                    className="btn btn-fill btn-primary"
                    disabled={submittingForm}
                  />
                  {editMode && (
                    <input
                      name="delete"
                      id="delete"
                      key="delete"
                      value="Delete"
                      type="button"
                      onClick={deleteHandler}
                      className="btn"
                    />
                  )}
                  {submittingForm && <SmallLoader />}
                </div>
              </form>
            </div>
          </>
        )}
      </div>
      </div>
    </div>
  );
};
export default Bubble;
