import { deleteTag } from "api/tags";

const deleteTagService = async (id: any) => {
  const response: any = await deleteTag(id);
  if (response.status === 200) {
    return { message: "success" };
  }
  if (response.status !== 200) {
    return { message: "failure" };
  }
};

export default deleteTagService;