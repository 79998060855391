import { createContext, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

export const TabsContext = createContext<any | null>(null);

export const TabsProvider = ({ children }: { children: any }) => {
  const [current, setCurrent] = useState(0);
  const [newTab, setNewTab] = useState<any>(null);
  const [screen, setScreen] = useState("");
  const [error, setError] = useState("");
  const [tabs, setTabs] = useState<any>(
    JSON.parse(localStorage.getItem("tabs") ?? "{}")
  );

  useEffect(() => {
    localStorage.setItem("tabs", JSON.stringify(tabs));
  }, [tabs]);

  const getTabs = (widgetId: any) => {
    return tabs.filter((tab: any) => tab.widgetId === widgetId)[0];
  };

  const createTab = (widgetId: any, data: any) => {
    const newTabDetails = { id: uuidv4(), widgetId, ...data };
    setTabs([...tabs, newTabDetails]);
  };

  const updateTab = (tabId: any, data: any) => {
    const filteredTabs = tabs.filter((tab: any) => tab.id !== tabId);
    setTabs([...filteredTabs, data]);
  };

  const removeTab = (tabId: any) => {
    const filteredTabs = tabs.filter((tab: any) => tab.id !== tabId);
    setTabs([...filteredTabs]);
  };

  return (
    <TabsContext.Provider
      value={{
        tabs,
        createTab,
        updateTab,
        removeTab,
        getTabs,
        screen,
        setScreen,
        error,
        setError,
        current,
        setCurrent,
        newTab,
        setNewTab,
      }}
    >
      {children}
    </TabsContext.Provider>
  );
};

export default function useTabs() {
  return useContext(TabsContext);
}
