export const appear = {
  enter: {
    opacity: 1,
    transition: {
      duration: 0.3,
    },
    display: "block",
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.1,
      delay: 0.3,
    },
    transitionEnd: {
      display: "none",
    },
  },
};
