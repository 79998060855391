export default {
  fontFamily: "Cera-Pro",
  primary: "#65F4CD",
  text_grey: "white",
  "teal-color": "#00999f",
  secondary: "#4799E9",
  lightBlue: "#3f5173",
  red: "#f95460",
  orange: "#ffb554",
  danger: "#dc3545",
  grey: "#acb2c0",
  bodyBg: "#232323",
  bodyColor: "#434343",
  themeGradient:
    "linear-gradient(180deg, rgba(90, 85, 202, 0.6) 0%, rgba(90, 85, 202, 0.9) 22.58%, #5a55ca 100%)",
};