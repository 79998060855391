import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import React from "react";

const SelectInput: React.FC<any> = ({ label, options, onValueChange, value }) => {
  return (
    <div className="flex flex-col gap-1">
      <label>{label}</label>
      <Select onValueChange={onValueChange}>
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder={value} />
        </SelectTrigger>
        <SelectContent className="w-full bg-dark">
          {options.map((option: any) => (
            <SelectItem key={option.id} value={option.id}>
              {option.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

export default SelectInput;