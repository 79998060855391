import { ChartArea } from "chart.js";

const createGradient = (ctx: CanvasRenderingContext2D, area: ChartArea, startColor: string, endColor: string) => {
  if (!area) {
    return;
  }
  const gradient = ctx.createLinearGradient(0, area.bottom, 1, area.top);
  gradient.addColorStop(0, startColor);
  gradient.addColorStop(1, endColor);
  return gradient;
};

export default createGradient;