import { useEffect, useState } from 'react';
import { getFile } from 'api/fileManager';

const PdfPreview = ({ id, style }: any) => {
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await getFile(id);
        if (response.data) {
          const base64Pdf = response.data.data;
          const mimeType = response.data.mimeType;

          // Convert base64 string to a binary array
          const byteCharacters = atob(base64Pdf);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          // Create a Blob from the binary data
          const blob = new Blob([byteArray], { type: mimeType });
          const blobUrl = URL.createObjectURL(blob);

          // Set the Blob URL as the src for the iframe
          setPdfUrl(blobUrl);
        }
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    };

    fetchPdf();

    return () => {
      if (pdfUrl) {
        // Revoke the object URL to free memory when the component unmounts
        URL.revokeObjectURL(pdfUrl);
        setPdfUrl(null);
      }
    };
  }, [id]);

  return (
    <>
      {pdfUrl ? (
        <iframe
          src={pdfUrl}
          title="PDF Preview"
          width="100%"
          height="100%"
          style={style}
          className='lg:h-[64vh] 2xl:h-[65.5vh]'
        />
      ) : (
        <p style={{
          width: "50%",
          zIndex: "99999",
          position: "absolute",
          top: "50%",
          marginTop: "10px",
          right: 0,
        }}>Loading PDF...</p>
      )}
    </>
  );
};

export default PdfPreview;
