import { borderColor } from "@mui/system";

const getOptions = ({ fontSize, highestValue, maxRange, data }: any) => {
  return {
    scales: {
      x: {
        grid: {
          offset: true,
          display: false,
        },
        ticks: {
          color: 'white',
          font: {
            size: fontSize,
          },
        },
      },
      y: {
        min: 0,
        max: highestValue,
        grid: {
          display: false,
        },
        ticks: {
          color: 'white',
          font: {
            size: 14,
          },
        },
      }
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        labels: {
          color: '#ffffff',
          font: {
            size: 17,
          },
        },
      },
      tooltip: {
        enabled: false
      },
      datalabels: {
        formatter: (value: any) => {
          return `${value} ${data[0]?.uom}`;
        },
        color: '#ffffff',
        backgroundColor: '#111111',
        borderColor: '#14b8a6',
        borderWidth: 1,
        borderRadius: 5,
        anchor: 'end' as const,
        align: 'end' as const,
        font: {
          size: fontSize
        }
      },
      annotation: {
        annotations: {
          xAxisLine: {
            type: 'line' as const,
            scaleID: 'x' as const,
            borderColor: 'white' as const,
            borderWidth: 2,
            mode: 'horizontal' as const,
            value: -0.49,
          },
          anotherXAxisLine: {
            type: 'line' as const,
            scaleID: 'y' as const,
            borderColor: '#8B0000' as const,
            borderWidth: 2,
            mode: 'horizontal' as const,
            value: maxRange,
          },
          yAxisLine: {
            type: 'line' as const,
            scaleID: 'y' as const,
            borderColor: 'white' as const,
            borderWidth: 4,
            mode: 'vertical' as const,
            value: 0,
          },
        },
      },
    }
  };
};

export default getOptions;