import PdfPreview from "components/PdfViewer/PdfViewer";
import { FiX } from "react-icons/fi";
import BorderGradient from "components/shared/BorderGradient/BorderGradient";

const PdfPreviewModal = ({ setSelectedTag, setModalType, selectedTag }: any) => {
  return (
    <div className="z-10 w-full opacity-95">
      {/* <BorderGradient className="w-full h-max"> */}
      <div className="w-full h-max p-3 bg-dark rounded-md">
        <div className="flex justify-between pb-2">
          <h3 className="text-2xl">View Pdf File</h3>
          <button
            className="cursor-pointer bg-green rounded-md p-1"
            onClick={() => {
              setSelectedTag({ action: "", value: null });
              setModalType('');
            }}
          >
            <FiX size={20} />
          </button>
        </div>
        <div className="h-[70vh] w-full">
          <PdfPreview id={selectedTag.value} />
        </div>
      </div>
      {/* </BorderGradient> */}
    </div>
  );
};

export default PdfPreviewModal;