import React, { useEffect, useState, useRef, ChangeEvent } from "react";
import CustomizedTimeline from "./Timeline";
import { Button } from "@/components/ui/button";
import useScreen from "hooks/useScreen";
import { ILogs } from "./domain/logsProp";
import { getData } from "./domain/getLogsData";
import { downloadPopup } from "./config/downloadPopup";


const Logs: React.FunctionComponent = () => {
  const [data, setdata] = useState<ILogs[]>([]);
  const [showpopup, setshowpopup] = useState(false);
  const { isScaled } = useScreen();

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      handleUpload(file);
    }
  };

  const handleUpload = (file: File) => {
    const reader = new FileReader();
  
    reader.onload = (event) => {
      try {
        if (event.target?.result) {
          const base64String = event.target.result.toString();
          const jsonString = atob(base64String.slice(0, -3)); // Removing the "ajx" suffix before decoding
          const jsonData = JSON.parse(jsonString);
          setdata(jsonData);
        } else {
          setdata([]);
          throw new Error('No data received');
        }
      } catch (error) {
        
        console.error('Error reading file:', error);
      }
    };
  
    reader.readAsText(file);
  };

  useEffect(() => {
    (async () => {
      try {
        const logsdata = await getData({});
        setdata(logsdata);
      } catch (error) {
        console.error("error logs: ", error);
      }
    })();
  }, []);

  const fileInputRef: any = useRef(null);
 const handleUploadclick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };   

  const ToggleDownloadPopup = () => {
    setshowpopup(() => !showpopup);
  };

  return (
    <>
      <div className="row mt-10">
        <div className="flex mt-9 ml-3">
          <Button className="btn mr-3" onClick={ToggleDownloadPopup}>
            {" "}
            Download
          </Button>
          <Button className="btn mr-3" onClick={handleUploadclick}>
            {" "}
            Upload
          </Button>
          {showpopup && (
            <div className="absolute bg-black border-2 border-darkcyan rounded-lg w-32 h-16 left-0 top-32 z-50">
              {downloadPopup.map((item: any, index: any) => (
                <div
                  key={index}
                  className="text-base px-2 text-darkcyan cursor-pointer"
                  onClick={() => {
                    item.onclick(data);
                    ToggleDownloadPopup();
                  }}
                >
                  {item.name}
                </div>
              ))}
            </div>
          )}
        </div>
        {/* <Button className='tags-config-button' type="file" onClick={handleUpload}>Upload</Button> */}
        <input
          ref={fileInputRef}
          type="file"
          accept=".ph"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </div>
      <h1 style={{ textAlign: "center", marginTop: "-45px", fontSize: "35px" }}>
        LOGS
      </h1>
      <div className="mt-0 xl-2xl:mt-[15px] 2xl:mt-[50px] ml-[10px] h-[78%] lg:h-[85%] 2xl:h-[77%] 4xl:h-[83%]" style={{marginLeft: "10px" }}>
        <CustomizedTimeline logs={data} />
      </div>
    </>
  );
};

export default Logs;
