import React, { createContext, useState, ReactNode } from 'react';

interface TabContextType {
  activeTab: 'docs' | 'tags';
  setActiveTab: (tab: 'docs' | 'tags') => void;
}

export const TabContext = createContext<TabContextType | null>(null);

interface TabProviderProps {
  children: ReactNode;
}

export const TabProvider: React.FC<TabProviderProps> = ({ children }) => {
  const [activeTab, setActiveTab] = useState<'docs' | 'tags'>('tags');

  return (
    <TabContext.Provider value={{ activeTab, setActiveTab }}>
      {children}
    </TabContext.Provider>
  );
};
