import { cn } from '@/lib/utils';
import { initialTab } from 'pages/Dashboard/AllDynamicWidgets/config/initialtab';
import { EditConfirmationButtonsProps } from 'pages/Dashboard/AllDynamicWidgets/domain/types';

export function EditConfirmationButtons({
  tabError,
  tabMode,
  setScreen,
  setNewTab,
  setTabError,
  submit,
}: EditConfirmationButtonsProps) {
  return (
    <div className={cn("mt-5","flex gap-3 mr-6")}>
      <div className="text-red pl-2">{tabError}</div>
      <div className="flex-1" />
      <div className="flex items-center gap-3">
        <div
          className="btn-sec"
          onClick={() => {
            if (tabMode === 'new') {
              setScreen('');
              setNewTab(initialTab);
              setTabError('');
            } else {
              setScreen('');
            }
          }}
        >
          Cancel
        </div>
        <button className="btn" onClick={submit}>
          {tabMode === 'new' ? 'Add' : 'Update'}
        </button>
      </div>
    </div>
  );
}
