import { Item } from "types/common";

function getHighestMaxRange(dataArray: Item[]) {
  let highestMaxRange = -Infinity; // start with the smallest possible number

  dataArray.forEach((item) => {
    if (item.maxRange > highestMaxRange) {
      highestMaxRange = item.maxRange;
    }
  });

  return highestMaxRange;
}

export default getHighestMaxRange;
