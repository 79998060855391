import { createContext, useContext, useState } from "react";

export interface pointCloud {
  name: string;
  id: string;
  scannedAt: string;
  attribute: string;
}

export const UnityContext = createContext<any | null>(null);

export const UnityProvider = ({ children }: { children: any }) => {
  const [IsBubbleChart, setIsBubbleChart] = useState<boolean>(false);

  return (
    <UnityContext.Provider
      value={{
        IsBubbleChart,
        setIsBubbleChart,
      }}
    >
      {children}
    </UnityContext.Provider>
  );
};

export default function useUnity() {
  return useContext(UnityContext);
}
