import UpdateIcon from "@mui/icons-material/Update";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import PriorityHighOutlinedIcon from "@mui/icons-material/PriorityHighOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import TimelineDot from "@mui/lab/TimelineDot";
export const getIconForAction = (
  action: any,
  iconsColor: any,
  iconsSizes: any
) => {
  switch (action) {
    case "Viewed":
      return (
        <TimelineDot style={{ backgroundColor: "grey" }}>
          <RemoveRedEyeOutlinedIcon style={{ ...iconsColor, ...iconsSizes }} />
        </TimelineDot>
      );
    case "Modified":
      return (
        <TimelineDot style={{ backgroundColor: "#09289A" }}>
          <UpdateIcon style={{ ...iconsColor, ...iconsSizes }} />
        </TimelineDot>
      );
    case "Information":
      return (
        <TimelineDot style={{ backgroundColor: "#08AEB2" }}>
          <PriorityHighOutlinedIcon style={{ ...iconsColor, ...iconsSizes }} />
        </TimelineDot>
      );
    case "Created":
      return (
        <TimelineDot style={{ backgroundColor: "#099A52" }}>
          <AddIcon style={{ ...iconsColor, ...iconsSizes }} />
        </TimelineDot>
      );
    case "Deleted":
      return (
        <TimelineDot style={{ backgroundColor: "#9A0909" }}>
          <DeleteIcon style={{ ...iconsColor, ...iconsSizes }} />
        </TimelineDot>
      );
    default:
      return null;
  }
};
