import moment from "moment";

export const checkSaudiDayNight = (dateTime: Date, userTimeZone:any, formatter:any, setDaySkyBox:any,day:any) => {
    const timeSaudi = moment
      .tz(moment(dateTime).format(formatter), userTimeZone)
      .tz("Asia/Kuwait");
    const hour = timeSaudi.hours();
    if (hour > 5 && hour < 18) {
      if (!day) {
        setDaySkyBox(1);
      }
    } else if (day) {
      setDaySkyBox(0);
    }
  };