import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { User } from "oidc-client-ts";

export const SignalRContext = createContext<any | null>(null);

export const SignalRProvider = ({ children }: { children: any }) => {
  const user = getUser();
  const token = user?.access_token;
  const [connection, setConnection] = useState<null | HubConnection>(null);

  function getUser() {
    const oidcStorage = sessionStorage.getItem(
      `oidc.user:${process.env.REACT_APP_AUTH_APP}:${process.env.REACT_APP_CLIENT_ID}`
    );
    if (!oidcStorage) {
      return null;
    }
    return User.fromStorageString(oidcStorage);
  }

  useEffect(() => {
    (async () => {
      if (token) {
        const BASE_URL = 'https://energyhub-api.processhub.ai';
        const connectionSignalR = new HubConnectionBuilder()
          .withUrl(`${BASE_URL}/MainHub`, {
            accessTokenFactory: () => token,
          })
          .build();
        connectionSignalR
          .start()
          .then(function () { })
          .catch((error) => {
            console.error(error.message);
          });
        setConnection(connectionSignalR);
      }
    })();
  }, [token]);

  const FileUploadHandler = useCallback(
    (data: any) => {
      if (connection) {
        connection.on("GenericFunc", function (e) {
          data(e);
        });
      }
    },
    [connection]
  );


  const unityDataHandler = useCallback(
    (data: any) => {
      if (connection) {
        connection.on("TagValueChanged", function () {
          data();
        });
      }
    },
    [connection]
  );

  return (
    <SignalRContext.Provider value={{ FileUploadHandler, unityDataHandler }}>
      {children}
    </SignalRContext.Provider>
  );
};

export default function useSignalR() {
  return useContext(SignalRContext);
}
