import { Splits } from "../../domain/types";

const drawHiddenSplits = ({
  chart,
  args,
  options,
  values
}: Splits) => {
  const {
    ctx,
    data,
    chartArea: { width, height, left, right },
  } = chart;
  ctx.save();

  const cx = width / 2;
  const cy = chart._metasets[0].data[0].y;

  const Iy = chart._metasets[0].data[0].y;

  // Define a scale factor based on the width of the component
  const scaleFactor = width / 200 * 2; // Doubling the scale factor

  // stroke line definition
  ctx.beginPath();
  ctx.lineWidth = 0.5;
  ctx.strokeStyle = "rgba(0, 0, 0, 0)";

  // drawing lines - starts
  ctx.moveTo(cx - 68 * scaleFactor, Iy - 56 * scaleFactor);
  ctx.lineTo(cx - 57 * scaleFactor, Iy - 47 * scaleFactor);
  ctx.stroke();

  ctx.moveTo(cx - 38 * scaleFactor, Iy - 80 * scaleFactor);
  ctx.lineTo(cx - 32 * scaleFactor, Iy - 67 * scaleFactor);
  ctx.stroke();

  ctx.moveTo(cx, Iy - 88 * scaleFactor);
  ctx.lineTo(cx, Iy - 73 * scaleFactor);
  ctx.stroke();

  ctx.moveTo(cx + 68 * scaleFactor, Iy - 56 * scaleFactor);
  ctx.lineTo(cx + 57 * scaleFactor, Iy - 47 * scaleFactor);
  ctx.stroke();

  ctx.moveTo(cx + 38 * scaleFactor, Iy - 80 * scaleFactor);
  ctx.lineTo(cx + 32 * scaleFactor, Iy - 67 * scaleFactor);
  ctx.stroke();
  // drawing lines - ends

  ctx.restore();

  ctx.font = `normal ${9 * scaleFactor}px Cera-Pro`;
  ctx.fillStyle = "rgba(0, 0, 0, 0)";

  let temp = 0;
  if (values.minValue != 0) {
    temp = values.minValue;
  }

  let sectionValue = Math.round(values.total / 6);

  if (sectionValue < 10) {
    sectionValue = Number(
      (Math.floor((values.total / 6) * 10) / 10).toFixed(1)
    );
  }

  // in between values
  ctx.fillText(sectionValue + temp, cx - 45 * scaleFactor, Iy - 35 * scaleFactor);
  ctx.fillText(sectionValue * 2 + temp, cx - 20 * scaleFactor, Iy - 52 * scaleFactor);
  ctx.fillText(sectionValue * 4 + temp, cx + 35 * scaleFactor, Iy - 52 * scaleFactor);
  ctx.fillText(sectionValue * 5 + temp, cx + 57 * scaleFactor, Iy - 35 * scaleFactor);

  // minValue
  ctx.textAlign = "left";
  ctx.fillText(values.minValue, left + 45 * scaleFactor, cy - 10 * scaleFactor);

  // maxValue
  ctx.textAlign = "right";
  ctx.fillText(values.maxValue, right - 40 * scaleFactor, cy - 10 * scaleFactor);

  ctx.restore();
};

export default drawHiddenSplits;
