export const unitNames = ['ExprName', 'EquipName', 'UnitName', 'TrainName', 'PlantName'];

export const sampleFile = "Date, 141-FI-320.PV, 500-FI-611. PV, 500-FI-611A. PV\n1/1/2023 0:00, 157.9499355,834.0295041,788.3192724\n1/3/2023 0:00,158.1374796,773.6364545,733.5062088\n1/4/2023 0:00,157.9176099,850.7646894,801.0766662";

export const sampleFileFormat = "Date (mm/dd/yyyy hh:mm) , TagName1 or ExpressionName1 , TagName2 or ExpressionName2 ,....";

export const status: any = {
  1: "Start",
  2: "Progress",
  3: "Completed",
  4: "Canceled",
  5: "Failed",
};