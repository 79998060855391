import { Gradient } from "../domain/types";

const getGradient = ({ ctx, chartArea, dataType }: Gradient) => {
  if (!chartArea) {
    return;
  }
  const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);

  if (dataType === 'min') {
    gradient.addColorStop(0, 'rgb(253, 79, 105)');
    gradient.addColorStop(0.6, 'transparent');
    gradient.addColorStop(1, 'transparent');
  }

  if (dataType === 'max') {
    gradient.addColorStop(1, 'rgb(253, 79, 105)');
    gradient.addColorStop(0.4, 'transparent');
    gradient.addColorStop(0, 'transparent');
  }
  return gradient;
};

export default getGradient;
