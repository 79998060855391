export const status = [
  { value: 'Open', label: "Open", id: 1 },
  { value: 'Closed', label: "Closed", id: 2 },
  { value: 'Rejected', label: "Rejected", id: 3 },
];

export const priorityStatus = [
  { value: 'High', label: "High", id: 1 },
  { value: 'Medium', label: "Medium", id: 2 },
  { value: 'Low', label: "Low", id: 3 },
];

export const severityStatus = [
  { value: 'Critical', label: "Critical", id: 1 },
  { value: 'Major', label: "Major", id: 2 },
  { value: 'Minor', label: "Minor", id: 3 },
];