import Area from "components/charts/Area/Area";
import { usePins } from "hooks/usePins";
import { useState, useEffect } from "react";
import { BiAlarm, BiExpand } from "react-icons/bi";
import { BsPinAngle, BsPinAngleFill } from "react-icons/bs";
import { MdOutlineDoDisturbOn, MdQueryStats } from "react-icons/md";
import { RxIdCard } from "react-icons/rx";
import classNames from "utilities/ClassNames";
import { LuGauge } from "react-icons/lu";
import { updateAlarm } from "api/alarm";
import { disableTag } from "api/tags";
import Gauge from "components/charts/Gauge/Gauge";

const defaultDataDetails = {
  name: null,
  nameIdentifier: null,
  uom: null,
  id: null,
  days: 0,
  type: "",
  tagNameIdentifier: "",
};

const Chart = ({
  data,
  dataCollection,
  screen,
  setScreen,
  setSelected,
  refreshUnityData,
}: any) => {
  // const { isScaled } = useScreen();
  const { unityPins, pin, unPin, pinLoading } = usePins();
  const [areaChart, setAreaChart] = useState(false);

  const [isAlertTagStatus, setIsAlertTagStatus] = useState<any>(null);
  const [isDisabledStatus, setIsDisabledStatus] = useState<any>(null);

  useEffect(() => {
    setIsAlertTagStatus(data.isAlertTag);
    setIsDisabledStatus(data.isDisabled);
  }, [data]);

  const [isPinned, setIsPinned] = useState<boolean>(false);

  useEffect(() => {
    if (data.tagNameIdentifier) {
      if (unityPins?.filter((pin: any) => pin.meta.tagId === data.id).length) {
        setIsPinned(true);
      } else {
        setIsPinned(false);
      }
    } else {
      if (
        unityPins?.filter((pin: any) => pin.meta.expressionId === data.id)
          .length
      ) {
        setIsPinned(true);
      } else {
        setIsPinned(false);
      }
    }
  }, [unityPins, pinLoading]);

  const alarm = async (id: any, isAlertTag: any) => {
    const response: any = await updateAlarm(id, isAlertTag ? 2 : 1);
    if (response.status === 200) {
      setIsAlertTagStatus(!isAlertTagStatus);
      refreshUnityData();
    }
  };

  const disable = async (id: any, isDisabled: any) => {
    const response: any = await disableTag(id, isDisabled ? false : true);
    if (response.status === 200) {
      setIsDisabledStatus(!isDisabledStatus);
      refreshUnityData();
    }
  };

  return (
    <>
      {screen === "normal" && (
        <div
          key={data.id}
          className="lg:p-1 2xl:p-3 border-2 border-white flex flex-col rounded-md h-full"
        >
          <div className="flex gap-2 justify-between z-50">
            <h4 className="font-bold lg:text-sm 2xl:text-lg">
              {data.tagNameIdentifier ? data.tagNameIdentifier : data.name}
            </h4>
            <div className="flex items-center gap-1">
              {/* pins */}
              <div className={classNames(isPinned ? "bg-lightRed" : "bg-green", "grid place-content-center duration-300 p-1.5 leading-3 rounded-md cursor-pointer")}>
                {isPinned ? (
                  <BsPinAngleFill
                    size={17} className="lg:size-3 2xl:size-5"
                    onClick={() =>
                      unPin(
                        data.tagNameIdentifier ? data.id : null,
                        data.tagNameIdentifier ? null : data.id,
                        2
                      )
                    }
                  />
                ) : (
                  <BsPinAngle
                    size={17} className="lg:size-3 2xl:size-5"
                    onClick={() =>
                      pin(
                        data.tagNameIdentifier ? data.id : null,
                        data.tagNameIdentifier ? null : data.id,
                        2
                      )
                    }
                  />
                )}
              </div>
              {/* Alarm */}
              {data.tagNameIdentifier && (
                <div
                  onClick={() => {
                    alarm(data.id, isAlertTagStatus);
                    setIsAlertTagStatus(!isAlertTagStatus);
                  }}
                  className={classNames(
                    isAlertTagStatus ? "bg-lightRed" : "bg-green",
                    "grid place-content-center duration-300 bg-green p-1.5 leading-3 rounded-md cursor-pointer"
                  )}
                >
                  <BiAlarm size={18} className="lg:size-3 2xl:size-5" />
                </div>
              )}
              {/* Disable */}
              {data.tagNameIdentifier && (
                <div
                  onClick={() => {
                    disable(data.id, isDisabledStatus);
                    setIsDisabledStatus(!isDisabledStatus);
                  }}
                  className={classNames(
                    isDisabledStatus ? "bg-lightRed" : "bg-green",
                    "grid place-content-center duration-300 bg-green p-1.5 leading-3 rounded-md cursor-pointer"
                  )}
                >
                  <MdOutlineDoDisturbOn size={18} className="lg:size-3 2xl:size-5" />
                </div>
              )}
              {/* Ticket */}
              {data.tagNameIdentifier &&
                !data.isNormal &&
                !data.isTicketCreated && (
                  <div
                    onClick={() => {
                      setScreen("ticket");
                      setSelected(data);
                    }}
                    className="grid place-content-center duration-300 bg-green p-1.5 leading-3 rounded-md cursor-pointer"
                  >
                    <RxIdCard size={18} className="lg:size-3 2xl:size-5" />
                  </div>
                )}
              {/* Expand */}
              {areaChart && (
                <div
                  onClick={() => {
                    setScreen("big");
                    setSelected(data);
                  }}
                  className="grid place-content-center duration-300 bg-green p-1.5 leading-3 rounded-md cursor-pointer"
                >
                  <BiExpand size={18} className="lg:size-3 2xl:size-5" />
                </div>
              )}
              {/* Switch to area */}
              <div
                onClick={() => setAreaChart(!areaChart)}
                className="cursor-pointer z-20 text-white p-1 rounded-md bg-green duration-300"
              >
                {!areaChart && <MdQueryStats size={20} className="lg:size-4 2xl:size-6" />}
                {areaChart && <LuGauge size={20} className="lg:size-4 2xl:size-6" />}
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center h-full w-full">
            {!areaChart && <Gauge data={data} gaugeType={dataCollection.length < 3 ? 'regular' : 'wide'} />}
            {areaChart && (
              <div className={classNames("flex flex-col w-full h-[26vh] mb-2 3xl:mb-0")}>
                <Area
                  item={data}
                  areaType='unity'
                  renderType="lazyLoading"
                  maintainAspectRatio={true}
                  type={data.tagNameIdentifier ? 'tag' : 'expression'}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Chart;
