/* eslint-disable @typescript-eslint/no-unused-expressions */
import { energyApi } from "utilities/Axios";

export const getUserTickets = async () => {
  return energyApi.get("/ticket/getmyticket");
};

export const getTickets = async () => {
  return energyApi.get("/ticket/getallticket");
};

export const createTicket = async (data: any) => {
  return energyApi.post("/ticket/createticket", data);
};

export const closeTicket = (data: any) => {
  return energyApi.post("/ticket/closeticket" + `?id=${data}`);
};

export const rejectTicket = (data: any) => {
  return energyApi.post("/ticket/rejectticket" + `?id=${data}`);
};
export const deleteTicket = (data: any) => {
  return energyApi.post("/ticket/deleteticket" + `?id=${data}`);
};
