const getItemDataService = async (api: any, id: string) => {
  try {
    const response = await api([id]);
    return response.data[0];
  }
  catch {
    return {};
  }
};

export default getItemDataService;