import { deleteAlarm } from "api/alarm";

const deleteAlarmService = async (id: any) => {
  const response: any = await deleteAlarm(id);
  if (response.status === 200) {
    return { message: "success" };
  }
  if (response.status !== 200) {
    return { message: "failure" };
  }
};

export default deleteAlarmService;