const splitLabels = (names: string[], maxLength: number) => {
  return names.map((name: string) => {
    if (name.length > maxLength) {
      const middleIndex = Math.floor(name.length / 2);
      const lastIndex = name.lastIndexOf(' ', middleIndex);
      return [name.substring(0, lastIndex), name.substring(lastIndex + 1)];
    }
    return name;
  });
};

export default splitLabels;