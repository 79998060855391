const chartInfoConfig = [
  {
    label: 'Tag Name:',
    value: 'name',
  },
  {
    label: 'Description:',
    value: 'desc',
  },
  {
    label: 'Engineering Unit:',
    value: 'uom',
  },
];

export default chartInfoConfig;