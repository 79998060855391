/* eslint-disable @typescript-eslint/no-unused-expressions */
import { energyApi, authApi, commonApi } from "utilities/Axios";

// export const getUser = () => {
// 	return api.get("/user/get");
// };

export const getUserInfo = () => {
    return authApi.get("/connect/userinfo");
};

export const signOut = () => {
    return authApi.get("/api/auth/sign-out",);
};

export const callbacklogin = () => {
    return authApi.get("api/auth/external-auth-callback",);
};

export const getAvatar = () => {
    return authApi.get("/user/getavatar",);
};


export const uploadPicture = (module:number,  data: any) => {
    return commonApi.post("/Api/File/Upload?module="+module, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      } 
    });
  };
export const mapPicture = (data: any) => {
    return authApi.post("/api/user/mappicture", data);
};

export const updateProfile = (data: any) => {
    return authApi.post("/api/user/edituser", data,);
};

export const resetPassword = (data: any) => {
    const details = {
        oldPassword: data.current_password,
        newPassword: data.new_password,
    };
    return authApi.post("/auth/resetpasswordfromprofile", details);
};
