export const editTitleInit=[
    {
      title: false,
      type: 1,
    },
    {
      title: false,
      type: 2,
    },
    {
      title: false,
      type: 3,
    },
  ];

  export const TitleInit=[
    {
      title: "Layout",
      type: 1,
    },
    {
      title: "Layout",
      type: 2,
    },
    {
      title: "Layout",
      type: 3,
    },
  ];

  export const TitleInputInit=[
    {
      title: "",
      type: 1,
    },
    {
      title: "",
      type: 2,
    },
    {
      title: "",
      type: 3,
    },
  ];



