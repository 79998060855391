import Menu from "components/widgets/Menu";
import TabsHeading from "components/DynamicWidgets/TabsHeading";
import React from "react";
import { cn } from "@/lib/utils";



export const TabContainer = ({ config, children }: { config: string; children: React.ReactNode }) => {
  return (
    <div className="h-full">
      <div className="flex items-center relative bg-stone-950 pl-3 py-2 pr-1 rounded-tl-md rounded-tr-md text-white">
        <div className="flex gap-4 items-center">
          {config !== "unconfigured" && children && React.Children.toArray(children).find((child: any) => child.type === TabsHeading)}
        </div>
        <div style={{ flexGrow: 1 }}></div>
        <div className="flex justify-end w-full relative">
          {config !== "unconfigured" && children && React.Children.toArray(children).find((child: any) => child.type === Menu)}
        </div>
      </div>
      <div className={cn("relative", "h-[100%]")}>
        {children && React.Children.toArray(children).find((child: any) => child.type !== Menu && child.type !== TabsHeading)}
      </div>
    </div>
  );
};