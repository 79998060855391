import { updateAlarmHistory } from "api/alarm";

const acknowledgeAlarmService = async ({ id, status }: any) => {
  const response: any = await updateAlarmHistory(id, status);
  if (response.status === 200) {
    return { message: "success" };
  }
  if (response.status !== 200) {
    return { message: "failure" };
  }
};

export default acknowledgeAlarmService;