import { updateAlarm } from "api/alarm";
import {
  disableTag} from "api/tags";
import { getAssetDocs } from "api/docs";
import Loader from "components/shared/Loader";
import { useEffect, useMemo, useState } from "react";
import BorderGradient from "components/shared/BorderGradient/BorderGradient";
import Debounce from "utilities/Debounce";
import { filterArray } from "utilities/FilterArray";
import { parseFilters } from "utilities/ParseFilters";
import DataTable from "components/DataTable/DataTable";
import { usePins } from "hooks/usePins";
import classNames from "utilities/ClassNames";
import useApp from "hooks/useApp";
import TableManager from "components/TableManager/TableManager";
import PopoverContainer from "components/Popover/PopoverContainer";
import Modal from "components/modal/Modal";
import { getFile } from "api/fileManager";
import { ListingProps } from "pages/all-assets/domain/types";
import processDocsData from "pages/all-assets/utils/processDocsData";

export default function TagContainer() {
  const { isScaled } = useApp();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState<any>(null);
  const [sortedData, setSortedData] = useState<any>(null);
  const [rawData, setRawData] = useState<any>(null);
  const [isDataSorted, setIsDataSorted] = useState(false);

  const [equipmentsFilters, setEquipmentsFilters] = useState<any>([]);
  const [equipmentsOptions, setEquipmentsOptions] = useState<any>();
  const [unitsFilters, setUnitsFilters] = useState<any>([]);
  const [unitsOptions, setUnitsOptions] = useState<any>();
  const [trainsFilters, setTrainsFilters] = useState<any>([]);
  const [trainsOptions, setTrainsOptions] = useState<any>([]);
  const [listing, setListing] = useState<ListingProps>(
    {
      selectedTrainId: null,
      selectedUnitId: null,
      selectedEquipmentId: null,
      selectedEquipment: null,
    }
  );
  const [tag, setTag] = useState<any>({
    action: "",
    value: null,
  });

  const { dashboardPins } = usePins();

  // table
  const ITEMS_PER_PAGE = 300;
  const [currentPage, setCurrentPage] = useState(1);

  const getData = async () => {
    setLoading(true);
    const response: any = await getAssetDocs();
    if (response.status === 200) {
      setRawData(processDocsData(response.data));
      const equipments = response.data.map((item: any) => item.equipmentName);
      setEquipmentsOptions(parseFilters(equipments));
      const units = response.data.map((item: any) => item.unitName);
      setUnitsOptions(parseFilters(units));
      const trains = response.data.map((item: any) => item.trainName);
      setTrainsOptions(parseFilters(trains));
    } else {
      setError(true);
    }
    setLoading(false);
  };

  const refreshData = async () => {
    const response: any = await getAssetDocs();
    if (response.status === 200) {
      setRawData(processDocsData(response.data));
    }
  };

  useEffect(() => {
    refreshData();
  }, [dashboardPins, tag]);

  const alarm = async (id: any, isAlertTag: any) => {
    const response: any = await updateAlarm(id, isAlertTag ? 2 : 1);
    if (response.status === 200) {
      refreshData();
    }
  };

  const disable = async (id: any, isDisabled: any) => {
    const response: any = await disableTag(id, isDisabled ? false : true);
    if (response.status === 200) {
      refreshData();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (rawData && rawData.length) {
      if (unitsFilters && unitsFilters?.length) {
        const result = filterArray(unitsFilters, rawData, "unitName");
        if (result.length > 0) setData(result);
      } else if (equipmentsFilters && equipmentsFilters?.length) {
        const result = filterArray(equipmentsFilters, rawData, "equipmentName");
        if (result.length > 0) setData(result);
      } else if (trainsFilters && trainsFilters?.length) {
        const result = filterArray(trainsFilters, rawData, "trainName");
        if (result.length > 0) setData(result);
      }
      else {
        setData(rawData);
      }
    }
  }, [rawData]);

  useEffect(() => {
    if (trainsFilters && trainsFilters?.length) {
      const result = filterArray(trainsFilters, rawData, "trainName");
      if (result.length > 0) setData(result);
    } else {
      setData(rawData);
    }
  }, [trainsFilters]);

  useEffect(() => {
    if (unitsFilters && unitsFilters?.length) {
      const result = filterArray(unitsFilters, rawData, "unitName");
      if (result.length > 0) setData(result);
    } else {
      setData(rawData);
    }
  }, [unitsFilters]);

  console.log("DATA", data);

  useEffect(() => {
    if (equipmentsFilters && equipmentsFilters?.length) {
      const result = filterArray(equipmentsFilters, rawData, "equipmentName");
      if (result.length > 0) setData(result);
    } else {
      setData(rawData);
    }
  }, [equipmentsFilters]);

  useEffect(() => {
    if (data && data.length > 0) {
      if (!isDataSorted) {
        setSortedData(data);
      }
    }
  }, [data, isDataSorted]);

  // Table
  const [keyword, setKeyword] = useState("");
  const [debouncedKeyword, setDebouncedKeyword] = useState("");
  const [filteredData, setfilteredData] = useState<any>(data);

  Debounce(() => setDebouncedKeyword(keyword), [keyword], 200);

  const rows = useMemo(() => {
    let items = sortedData;
    const keyword = debouncedKeyword.toString().toLowerCase();
    if (keyword !== "") {
      items = data?.filter(
        (item: any) =>
          item.fileName.toLowerCase().includes(keyword) ||
          item.fileName.toLowerCase() === keyword
      );
    }

    if (items) {
      setfilteredData(items);
      return items?.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    }
  }, [sortedData, debouncedKeyword, currentPage]);



  const handleClick = (e: any) => {
    setTag({ action: 'pdfViewer', value: e.fileId });
  };

  const handleManagerBtnClick = () => {
    setTag({ action: "new", value: null });
  };

  const filterItems = [
    {
      title: 'Units',
      filters: unitsFilters,
      options: unitsOptions,
      setFilters: setUnitsFilters,
    },
    {
      title: 'Equipments',
      filters: equipmentsFilters,
      options: equipmentsOptions,
      setFilters: setEquipmentsFilters,
    },
    {
      title: 'Trains',
      filters: trainsFilters,
      options: trainsOptions,
      setFilters: setTrainsFilters,
    },
  ];

  const onClear = () => { };

  const handleDocsActionClick = (itemValue: any, itemAction: any) => {
    setTag({
      action: itemAction,
      value: {
        ...itemValue,
      }
    });
  };

  useEffect(() => {
    if (tag.action === 'download') {
      onDownloadClick(tag.value);
    }
  }, [tag]);

  const onAddDocs = () => {
    setTag({ action: "new", value: { ...listing, isMappingEnabled: false } });
  };

  const onDownloadClick = async (e: any) => {
    try {
      const response = await getFile(e.fileId);

      if (response.status === 200) {
        const base64Data = response.data.data;
        const title = response.data.fileName || 'download';

        const binaryString = window.atob(base64Data);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        const blob = new Blob([bytes], { type: 'application/pdf' });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${title}.pdf`;
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
      } else {
        console.error('Failed to download file');
      }
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const downloadFilterData = () =>{
    if (filteredData && filteredData.length>0){
      filteredData.forEach((item:any) => {
        onDownloadClick(item);
      });
    }
  };

  return (
    <>
      {loading && (
        <div className="w-full h-[80vh] grid place-content-center">
          <Loader />
        </div>
      )}
      {error && (
        <div className="w-full h-[80vh] grid place-content-center">
          <p>Error retrieving Tags, try refreshing the page</p>
        </div>
      )}
      {!loading && !error  && (
        <div
          className={classNames(
            isScaled ? "text-2xl" : "text-base",
            "pl-3 pt-3 pb-3 overflow-hidden overflow-y-scroll"
          )}
        >
          <TableManager setKeyword={setKeyword} btnAction={onAddDocs} downloadfiltereddata={downloadFilterData}>
            <PopoverContainer items={filterItems} onClear={onClear} />
          </TableManager>
          <div className="mt-3 h-[78vh]">
            <BorderGradient className=" overflow-hidden h-full">
              <DataTable type="docs" hasPins={true} hasActions={true} rows={rows} onClickHandler={handleClick} onActionClickHandler={handleDocsActionClick} refresh={refreshData} />
            </BorderGradient>
          </div>
          <Modal page='docs' selectedTag={tag} setSelectedTag={setTag} isLoading={loading} refresh={refreshData} isAlarm={alarm} isDisabled={disable} />
        </div>
      )}
    </>
  );
}
