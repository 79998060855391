import { getEquipmentsByUnitId } from "api/equipments";
import {
  getTagsByEquipmentsId,
} from "api/tags";
import { getTrains as apiGetTrains } from "api/trains";
import { getUnitsByTrainId } from "api/units";
import { useEffect, useState, useContext } from "react";
import { TabContext } from "hooks/useAssetsActiveTab";
import Asset from "components/Asset/Asset";
import classNames from "utilities/ClassNames";
import { Item, SelectedItem } from "types/common";
import Modal from "components/modal/Modal";
import { updateAlarm } from "api/alarm";
import { ListingProps, FetchAssetsDataProps, CheckSelectionProps } from "./domain/types";
import { TrainProps, UnitProps, EquipmentsProps } from "types/assetTypes";
import processDocsData from "./utils/processDocsData";
import { FaFilePdf } from 'react-icons/fa';
import { getMappedAssetDocs } from "api/docs";
import { getFile } from "api/fileManager";


export default function AllAssets() {
  const [tag, setTag] = useState<SelectedItem>({
    action: "",
    value: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPage, setSelectedPage] = useState<"trains" | "tags" | "expressions" | "units" | "equipments" | "docs">("trains");
  const [listing, setListing] = useState<ListingProps>(
    {
      selectedTrainId: null,
      selectedUnitId: null,
      selectedEquipmentId: null,
      selectedEquipment: null,
    }
  );

  // show status
  const [showUnits, setShowUnits] = useState(false);
  const [showEquipments, setShowEquipments] = useState(false);
  const [showTags, setShowTags] = useState(false);

  //   selected
  const [selectedTrain, setSelectedTrain] = useState<TrainProps | null>(null);
  const [selectedUnit, setSelectedUnit] = useState<UnitProps | null>(null);
  const [selectedEquipment, setSelectedEquipment] = useState<EquipmentsProps | null>(null);

  //   data
  const [trains, setTrains] = useState<TrainProps[] | null>(null);
  const [units, setUnits] = useState<UnitProps[] | null>(null);
  const [equipments, setEquipments] = useState<EquipmentsProps[] | null>(null);
  const [tagsByEquipment, setTagsByEquipment] = useState<Item[] | null>(null);
  const context = useContext(TabContext);
  const [docsList, setDocsList] = useState([]);
  const [processedDocsList, setProcessedDocsList] = useState<any>([]);
  const [docsViewMode, setDocsViewMode] = useState('');

  const getTrains = async () => {
    const response = await apiGetTrains();
    if (response.status === 200) {
      setTrains(response.data);
    }
    else setTrains(null);
  };

  if (!context) {
    throw new Error('TabContext must be used within a TabProvider');
  }

  const { activeTab, setActiveTab } = context;

  const handleTabChange = (tab: 'docs' | 'tags') => {
    setActiveTab(tab);
  };

  const getUnits = async () => {
    const response = await getUnitsByTrainId(selectedTrain?.id);
    if (response.status === 200) {
      setUnits(response.data);
    }
  };

  const getEquipments = async () => {
    const response = await getEquipmentsByUnitId(selectedUnit?.id);
    if (response.status === 200) {
      setEquipments(response.data);
    }
    else setEquipments(null);
  };

  const getTagsById = async () => {
    const response = await getTagsByEquipmentsId(selectedEquipment?.id);
    if (response.status === 200) {
      setTagsByEquipment(response.data);
    }
    else setTagsByEquipment(null);
  };

  useEffect(() => {
    getTrains();
  }, []);

  const fetchData = async ({ fetchFunction, setShowFunction }: FetchAssetsDataProps) => {
    try {
      setIsLoading(true);
      await fetchFunction();
      setShowFunction(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedTrain) {
      fetchData({ fetchFunction: getUnits, setShowFunction: setShowUnits });
    }
  }, [selectedTrain]);

  useEffect(() => {
    if (selectedUnit) {
      fetchData({ fetchFunction: getEquipments, setShowFunction: setShowEquipments });
    }
  }, [selectedUnit]);

  useEffect(() => {
    if (selectedEquipment) {
      fetchData({ fetchFunction: getTagsById, setShowFunction: setShowTags });
    }
  }, [selectedEquipment]);

  useEffect(() => {
    if (selectedTrain || selectedUnit || selectedEquipment) {
      setListing({
        selectedTrainId: selectedTrain?.id || null,
        selectedEquipmentId: selectedEquipment?.id || null,
        selectedUnitId: selectedUnit?.id || null,
        selectedEquipment: selectedEquipment,
      }
      );
    }
  }, [selectedTrain, selectedUnit, selectedEquipment]);

  const checkSelection = <T extends TrainProps | UnitProps | EquipmentsProps>({ list, selectedItem, setSelected }: CheckSelectionProps<T>) => {
    if (list !== null) {
      const listIds = list.map((item: T) => item.id);
      if (!listIds.includes(selectedItem.id)) {
        setSelected(null);
      }
    }
  };
  useEffect(() => {
    if (trains && trains !== null && selectedTrain !== null) checkSelection({ list: trains, selectedItem: selectedTrain, setSelected: setSelectedTrain });
    if (units && units !== null && selectedUnit !== null) checkSelection({ list: units, selectedItem: selectedUnit, setSelected: setSelectedUnit });
    if (equipments && equipments !== null && selectedEquipment !== null) checkSelection({ list: equipments, selectedItem: selectedEquipment, setSelected: setSelectedEquipment });
  }, [trains, units, equipments]);

  useEffect(() => {
    if (selectedTrain === null) {
      setShowUnits(false);
      setShowEquipments(false);
      setShowTags(false);
    }
    if (selectedUnit === null) {
      setShowEquipments(false);
      setShowTags(false);
    }
    if (selectedEquipment === null) {
      setShowTags(false);
    }
  }, [selectedTrain, selectedUnit, selectedEquipment]);

  const fetchDocsData = async () => {
    try {
      if (listing.selectedEquipmentId) {
        setDocsViewMode('equipment');
        const response = await getMappedAssetDocs({ equipmentId: listing.selectedEquipmentId });
        setDocsList(processDocsData(response.data.mappedAssetDocs));
        return;
      }
      if (listing.selectedUnitId) {
        setDocsViewMode('unit');
        const response = await getMappedAssetDocs({ unitId: listing.selectedUnitId });
        setDocsList(processDocsData(response.data.mappedAssetDocs));
        return;
      }
      if (listing.selectedTrainId) {
        setDocsViewMode('train');
        const response = await getMappedAssetDocs({ trainId: listing.selectedTrainId });
        setDocsList(processDocsData(response.data.mappedAssetDocs));
        return;
      }
      setDocsList([]);
      return;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const processedDocs = docsList.map((item: any) => {
      if (item.ext === '.pdf') {
        return { ...item, ext: <FaFilePdf /> };
      }
      return item;
    });
    setProcessedDocsList(processedDocs);
  }, [docsList]);


  useEffect(() => {
    fetchDocsData();
  }, [listing]);

  const refreshData = async () => {
    setIsLoading(true);
    await getTrains();
    await getUnits();
    await getEquipments();
    await fetchDocsData();
    if (selectedEquipment) {
      await getTagsById();
    }
    setIsLoading(false);
  };

  const handleActionClick = (itemValue: Item, itemAction: 'chart' | 'edit' | 'reset' | 'delete') => {
    setTag({
      action: itemAction,
      value: itemValue
    });
  };

  const handleDocsActionClick = (itemValue: Item, itemAction: 'chart' | 'edit' | 'reset' | 'delete') => {
    setSelectedPage('docs');
    const genParent = () => {
      if (selectedEquipment) {
        return {
          equipmentid: selectedEquipment.id
        };
      }
      if (selectedUnit) {
        return {
          unitId: selectedUnit.id
        };
      }
      if (selectedTrain) {
        return {
          trainId: selectedTrain.id
        };
      }
    };
    setTag({
      action: itemAction,
      value: {
        ...itemValue,
        ...genParent()
      }
    });
  };

  const handleTagsClick = (itemValue: Item, itemAction: 'chart' | 'edit' | 'reset' | 'delete') => {
    setSelectedPage('tags');
    handleActionClick(itemValue, itemAction);
  };

  const handleDocsClick = (e: any) => {
    setSelectedPage('docs');
    setTag({ action: 'pdfViewer', value: e.fileId });
  };

  const onAssetsClick = (e: TrainProps) => {
    setSelectedTrain(e);
    setSelectedUnit(null);
    setSelectedEquipment(null);
    setShowUnits(true);
    setShowEquipments(false);
    setShowTags(false);
  };

  const onUnitsClick = (e: UnitProps) => {
    setSelectedUnit(e);
    setSelectedEquipment(null);
    setShowEquipments(true);
    setShowTags(false);
  };

  const onEquipmentsClick = (e: EquipmentsProps) => {
    if (selectedEquipment === null) {
      handleTabChange('tags');
    }
    setSelectedEquipment(e);
    setShowTags(true);
  };

  const onAddItem = () => {
    setTag({ action: "new", value: null });
  };

  const onAddDocs = () => {
    setSelectedPage('docs');
    setTag({ action: "new", value: { ...listing, isMappingEnabled: false } });
  };

  const onMapping = () => {
    setTag({ action: 'mapping', value: null });
  };

  const onDocsMapping = () => {
    setTag({ action: 'mappingDocs', value: { parent: selectedEquipment || selectedUnit || selectedTrain, items: processedDocsList } });
  };

  useEffect(() => {
    if (tag.action === 'download') {
      onDownloadClick(tag.value);
    }
  }, [tag]);

  const alarm = async (id: number, isAlertTag: boolean) => {
    const response = await updateAlarm(id, isAlertTag ? 2 : 1);
    if (response.status === 200) {
      refreshData();
    }
  };

  const onDownloadClick = async (e: any) => {
    try {
      const response = await getFile(e.fileId);

      if (response.status === 200) {
        const base64Data = response.data.data;
        const title = response.data.fileName || 'download';

        const binaryString = window.atob(base64Data);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        const blob = new Blob([bytes], { type: 'application/pdf' });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${title}.pdf`;
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
      } else {
        console.error('Failed to download file');
      }
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };


  useEffect(() => {
    if (!showTags) {
      handleTabChange('docs');
    }
  }, [showTags]);
  return (
    <>
      <div className="p-3 flex flex-col gap-2 h-[85vh]">
        <div className="h-[50%]">
          <div className="w-full h-full grid grid-cols-3 grid-rows-1 gap-2">
            <Asset data={trains} dataType='single' isLoading={isLoading} type="trains" onClickHandler={onAssetsClick} onActionClickHandler={handleActionClick} onAddItem={onAddItem} setSelectedPage={setSelectedPage} selectedItem={selectedTrain} />
            {showUnits && <Asset data={units} dataType='single' isLoading={isLoading} type="units" onClickHandler={onUnitsClick} onActionClickHandler={handleActionClick} onAddItem={onAddItem} setSelectedPage={setSelectedPage} selectedItem={selectedUnit} />}
            {showEquipments && <Asset data={equipments} dataType='single' isLoading={isLoading} type="equipments" onClickHandler={onEquipmentsClick} onActionClickHandler={handleActionClick} onAddItem={onAddItem} setSelectedPage={setSelectedPage} selectedItem={selectedEquipment} />}
          </div>
        </div>
        <div className={classNames("mt-0", "h-[50%] relative")}>
          <div className="absolute left-4 lg:top-2 2xl:top-4 flex gap-4 text-lg font-bold">
            {showTags && <button className={activeTab === 'tags' ? "border-b-2 border-t-2 border-green" : "text-green"} onClick={() => handleTabChange('tags')}>Tags</button>}
            {selectedTrain && <button className={activeTab === 'docs' ? "border-b-2 border-t-2 border-green" : "text-green"} onClick={() => handleTabChange('docs')}>Docs</button>}
          </div>
          {showTags && activeTab === 'tags' && <Asset data={tagsByEquipment} dataType='multiple' isLoading={isLoading} type="tags" onClickHandler={handleTagsClick} onActionClickHandler={handleActionClick} onAddItem={onAddItem} onMapping={onMapping} setSelectedPage={setSelectedPage} />}
          {activeTab === 'docs' && selectedTrain && <Asset data={processedDocsList} type="mappedDocs" onClickHandler={handleDocsClick} onActionClickHandler={handleDocsActionClick} onAddItem={onAddDocs} onMapping={onDocsMapping} />}
        </div>
      </div>
      <Modal page={selectedPage} isAlarm={alarm} selectedTag={tag} setSelectedTag={setTag} refresh={refreshData} isLoading={isLoading} listing={listing} />
    </>
  );
}
