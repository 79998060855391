import React, { useEffect, useState } from "react";
import Area from "components/charts/Area/Area";
import Bar from "components/charts/Bar/Bar";
import graphContainerConfig from "./config/graphContainerConfig";
import getItemDataService from "./domain/getDataService";
import { GraphContainerProps } from "./domain/types";
import GraphToolTip from "components/GraphToolTip/GraphToolTip";

const GraphContainer: React.FC<GraphContainerProps> = ({
  data,
  type,
  isAlarm,
  setTicket,
  setBigGraph,
}) => {

  const [areaData, setAreaData] = useState();
  const { apiCall } = graphContainerConfig[type];
  const [chartName, setChartName] = useState();

  useEffect(() => {
    (async () => {
      try {
        if (data?.id) {
          const newData = await getItemDataService(apiCall, data.id);
          setAreaData(newData);
          if (type === 'tag') {
            setChartName(newData?.tagNameIdentifier);
          }
          if (type === 'expression') {
            setChartName(newData?.name);
          }
        }
      } catch {
        throw new Error('No data available');
      }
    })();
  }, [data]);

  return (
    <>
      <div className="flex flex-col justify-center h-full gap-1">
        <div className="bg-dark px-1 py-2 rounded-md h-full flex-grow">
          <div className="w-full h-[80%] mb-2 flex flex-wrap">
            <div className="flex justify-between px-3 py-2 w-full ">
              <p className="font-bold block w-max text-lg">{chartName}</p>
              <div>
                <GraphToolTip data={data} alarm={isAlarm} type={type} setTicket={setTicket} setBigGraph={setBigGraph} />
              </div>
            </div>
            <div className="w-full min-h-full">
              <Area
                item={data}
                type={type}
                areaType="regular"
                sidebarType="big"
                renderType="lazyLoading"
                maintainAspectRatio={true}
              />
            </div>
          </div>
          <div className="w-[80%] mx-auto h-max mb-10 -mt-2 2lg:mt-0">
            {areaData && (
              <Bar
                meta={areaData}
                barType="medium"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GraphContainer;
