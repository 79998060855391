const chartModalConfig = {
  tags: {
    showChartMenu: true,
    containerClasses: 'min-h-[70vh] h-full',
  },
  expressions: {
    showChartMenu: false,
    containerClasses: 'min-h-[60vh] ',
  },
  alarms: {
    showChartMenu: true,
    containerClasses: 'h-[70vh]',
  }
};

export default chartModalConfig;