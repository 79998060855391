/* eslint-disable @typescript-eslint/no-unused-expressions */
import { energyApi } from "utilities/Axios";

export const getAllEquipments = () => {
  return energyApi.get("/TagMeta/GetAllEquipments");
};

export const getEquipmentsByUnitId = (unitId = 0) => {
  return energyApi.get(`/TagMeta/GetEquipmentsByUnitId?id=${unitId}`);
};

export const createEquipment = (equipment: any) => {
  return energyApi.post("/TagMeta/CreateEquipment", equipment);
};

export const updateEquipment = (equipment: any) => {
  return energyApi.post("/TagMeta/UpdateEquipment", equipment);
};

export const deleteEquipment = (id: any) => {
  return energyApi.get(`/TagMeta/DeleteEquipment?id=${id}`);
};
