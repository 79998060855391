import PopoverItem from "./PopoverItem";
import { PopoverProps } from "./domain/types";

const PopoverContainer: React.FC<PopoverProps> = ({ items, onClear }) => {
  return (
    <div className="flex items-center gap-5">
      {items.map((item, index) => {
        return (<PopoverItem key={index} title={item.title} onClear={onClear} options={item.options} filters={item.filters} setFilters={item.setFilters} />);
      })}
    </div>
  );
};


export default PopoverContainer;