import { ChangeEvent, useEffect } from "react";
import classNames from "utilities/ClassNames";
import { AlltabTypes } from "../../pages/Dashboard/AllDynamicWidgets/config/alltabs";
import { TabType, tabConfigProps } from "../../pages/Dashboard/AllDynamicWidgets/domain/types";
import { cn } from "@/lib/utils";

export default function TabConfiguration({
  newTab,
  setNewTab,
  widget,
  tabMode,
  type
}: any) {
  useEffect(() => {
    if ((type === 2 && widget.position === 5) || (type === 1 && widget.position === 1)) {
      setNewTab((prev: tabConfigProps) => ({ ...prev, type: "large" }));
    }
  }, [widget]);

  const tabTypes = AlltabTypes.map((item: { name: string, graphtype: string }) => ({
    name: item.name,
    value: item.graphtype,
    action: () => setNewTab((prev: tabConfigProps) => ({ ...prev, type: item.graphtype })),
  }));

  return (
    <div className="w-full flex items-center p-3">
      <form
        className={classNames(
          tabMode === "new" ? "gap-3" : "justify-between",
          "w-full flex flex-col"
        )}
      >
        <div className="flex flex-col gap-1">
          <label
            htmlFor="name"
            className={classNames("")}
          >
            Tab Name
          </label>
          <input
            className={classNames("", "input w-1/2")}
            name="name"
            id="name"
            placeholder="Name"
            type="text"
            required
            value={newTab.name}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setNewTab((prev: tabConfigProps) => ({ ...prev, name: e.target.value }))
            }
          />
          {tabMode === "new" && (
            <p
              className={classNames("text-xs ",
                "text-stone-400"
              )}
            >
              Name longer than 10 characters will be reformated
            </p>
          )}
        </div>

        {((type === 2 && widget.position !== 5) || (type === 1 && widget.position !== 1) || type === 3) && tabMode === "new" && (
          <div className={cn("lg:mt-1 xl:mt-3 2xl:mt-5", "grid grid-cols-4 gap-1 w-full")}>
            {tabTypes.map((type: TabType, index: number) => (
              <div
                key={`${type.name}-${index}`}
                onClick={type.action}
                className={classNames(
                  newTab.type === type.value ? "gradient" : "text-white", "p-1",
                  index > 3 ? "col-span-2" : "col-span-1",
                  "bg-stone-700 flex justify-center items-center rounded-md w-full cursor-pointer hover:bg-slate font-bold duration-300 border-slate border-2 p-4"
                )}
              >
                <p
                  className={classNames("",
                    "text-center lg:text-[10px] xl:text-xs 2xl:text-md"
                  )}
                >
                  {type.name}
                </p>
              </div>
            ))}
          </div>
        )}
      </form>
    </div>
  );
}
