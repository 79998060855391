import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Tabs from "./Tabs";
import useApp from "hooks/useApp";
import { WidgetProps, tabConfigProps } from "../../pages/Dashboard/AllDynamicWidgets/domain/types";
import { initialTab } from "pages/Dashboard/AllDynamicWidgets/config/initialtab";
import { DashboardChartContainer } from "./DashboardContainer";

export default function Widget({ widget, widgets, type }: { widget: WidgetProps; widgets: WidgetProps[]; type: number }) {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [screen, setScreen] = useState<string>("");
  const [newTab, setNewTab] = useState(initialTab);
  const { dashboardWidgets, updateDashboardWidgets } = useApp();
  const updateWidgets = (id: string, newWidget: WidgetProps) => {
    const othersWidgets = widgets.filter((w: WidgetProps) => w.id !== id);
    const newWidgets = [...othersWidgets, newWidget];
    if (type === 2) {
      updateDashboardWidgets({ ...dashboardWidgets, widget2: newWidgets });
    }
    else if (type === 1) {
      updateDashboardWidgets({ ...dashboardWidgets, widget1: newWidgets });
    }
    else if (type === 3) {
      updateDashboardWidgets({ ...dashboardWidgets, widget3: newWidgets });
    }
    setScreen("");
  };

  const removeWidget = async (widgetId: string) => {
    const widgetDeleted = widgets.find((w: WidgetProps) => w.id === widgetId);
    if (!widgetDeleted) return;
    widgetDeleted.tabs = [];
    const updatedWidgets = widgets.filter((w: WidgetProps) => w.id !== widgetId);
    const updatedDashboardWidgets = { ...dashboardWidgets };
    if (type === 2 || type === 1) {
      updatedDashboardWidgets[`widget${type}`] = [...updatedWidgets, widgetDeleted];
    } else if (type === 3) {
      updatedDashboardWidgets.widget3 = updatedWidgets;
    }
    updateDashboardWidgets(updatedDashboardWidgets);
  };

  const addNewTab = (widgetId: string) => {
    const widget = widgets.filter((w: WidgetProps) => w.id === widgetId)[0];
    const newTabDetails: tabConfigProps = { ...newTab, id: uuidv4() };
    const newTabs: tabConfigProps[] = [...widget.tabs, newTabDetails];
    const newWidget: WidgetProps = { ...widget, tabs: newTabs };
    updateWidgets(widget.id, newWidget);
    setCurrentTab(widget.tabs.length);
  };

  const updateTab = (widgetId: string) => {
    const widget = widgets.filter((w: WidgetProps) => w.id === widgetId)[0];
    const newTabs = [...widget.tabs];
    newTabs[currentTab] = {
      ...newTab,
      configurations: widget.tabs[currentTab].configurations,
      configured: widget.tabs[currentTab].configurations,
    };
    const newWidget = { ...widget, tabs: newTabs };
    updateWidgets(widget.id, newWidget);
  };

  const removeTab = (widgetId: string, tabId: string) => {
    setCurrentTab(0);
    setNewTab(initialTab);
    const widget = widgets.filter((w: WidgetProps) => w.id === widgetId)[0];
    const newTabs = widget.tabs.filter((tab: tabConfigProps) => tab.id !== tabId);
    const newWidget = { ...widget, tabs: newTabs };
    updateWidgets(widget.id, newWidget);
  };

  return (
    <DashboardChartContainer type={type} position={widget.position}>
      <Tabs
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        screen={screen}
        setScreen={setScreen}
        newTab={newTab}
        setNewTab={setNewTab}
        tabs={widget.tabs}
        addNewTab={addNewTab}
        updateTab={updateTab}
        removeTab={removeTab}
        widget={widget}
        widgets={widgets}
        updateWidgets={updateWidgets}
        removeWidget={removeWidget}
        type={type}
      />
    </DashboardChartContainer>
  );
}
