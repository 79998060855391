import { getAllEquipments } from "api/equipments";
import { getAllUnits } from "api/units";
import { getBubblesMeta, getBubblesData, getNotes, getLabelsMeta } from "api/unity";
import { UnityData } from "types/unitytypes";

export const getData = async (isRefresh=false) => {
  const Data: UnityData = {
    BubblesMeta: null,
    BubblesData: null,
    Notes: null,
    Equipments: null,
    Units: null,
    LabelsMeta:null
  };

  try {
    const bubblesMetaResponse = await getBubblesMeta();
    const bubblesDataResponse = await getBubblesData();
    const notesResponse =  isRefresh?null: await getNotes();
    const equipmentsResponse = isRefresh?null:  await getAllEquipments();
    const unitsResponse =  isRefresh?null: await getAllUnits();
    const LabelsMetaResponse= await getLabelsMeta();

    if (bubblesMetaResponse.status === 200) {
      Data.BubblesMeta = bubblesMetaResponse.data;
    }
    if (bubblesDataResponse.status === 200) {
      Data.BubblesData = bubblesDataResponse.data;
    }

    if (notesResponse && notesResponse.status === 200) {
      Data.Notes = notesResponse.data;
    }

    if (equipmentsResponse && equipmentsResponse.status === 200) {
      Data.Equipments = equipmentsResponse.data;
    }
    if (unitsResponse && unitsResponse.status === 200) {
      Data.Units = unitsResponse.data;
    }
    if (LabelsMetaResponse.status === 200) {
      Data.LabelsMeta = LabelsMetaResponse.data;
    }
  } catch (error) {
    // Handle errors here if needed
    console.error("Error fetching data:", error);
  }
  return Data;
};


