import { createContext, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

export const WidgetsContext = createContext<any | null>(null);

export const WidgetsProvider = ({ children }: { children: any }) => {
  const [widgets, setWidgets] = useState<any>(
    JSON.parse(localStorage.getItem("widgets") ?? "[]")
  );

  useEffect(() => {
    localStorage.setItem("widgets", JSON.stringify(widgets));
  }, [widgets]);

  const createWidget = () => {
    setWidgets([
      ...widgets,
      { id: uuidv4(), tabs: [], position: widgets.length - 1 },
    ]);
  };

  const updateWidgets = (id: any, newWidget: any) => {
    const othersWidgets = widgets.filter((w: any) => w.id !== id);
    const newWidgets = [...othersWidgets, newWidget];
    setWidgets(newWidgets);
  };

  const removeWidget = (widgetId: string) => {
    const newWidgets = widgets.filter((w: any) => w.id !== widgetId);
    setWidgets(newWidgets);
  };

  return (
    <WidgetsContext.Provider
      value={{
        widgets,
        createWidget,
        updateWidgets,
        removeWidget,
      }}
    >
      {children}
    </WidgetsContext.Provider>
  );
};

export default function useWidget() {
  return useContext(WidgetsContext);
}
