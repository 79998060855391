import useData from "hooks/useData";
import { useEffect, useState } from "react";
import ExpressionCalculationMenu from "./ExpressionCalculationMenu";
import { SmallLoader } from "components/shared/Loader";

const Info = () => (
  <div className="sample-expression mt-2">
    <p className="text-[15px] 2xl:text-[18px]">
      <label>Supports:</label> <span>+</span>, <span>-</span>, <span>*</span>,{" "}
      <span>/</span>, <span>IIF</span>, <span>ISNULL</span>, <span>LN</span>,{" "}
      <span>EXP</span>, <span>TG</span>, <span>EX</span>, <span>DaysFrom</span>
    </p>
    <p className="leading-4 3xl:leading-6">
      <label>TagName: </label> <span>{`{`}</span>TG:PITagName<span>{`}`}</span>,{" "}
      <label>ExpressionName: </label>
      <span> {`{`}</span>EX:ExprName<span>@</span>EquipName<span>{`}`}</span> or
      <span> {`{`}</span>EX:ExprName<span>@</span>UnitName<span>{`}`}</span> or{" "}
      <span> {`{`}</span>EX:ExprName<span>@</span>TrainName<span>{`}`}</span> or{" "}
      <span> {`{`}</span>EX:ExprName<span>@</span>PlantName<span>{`}`}</span> or
      <span> {`{`}</span>EX:ExprName<span>{`}`}</span>
      <label>(constant type)</label>{" "}
    </p>
    <p className="leading-4 3xl:leading-6">
      <label>DaysFrom Format:</label> <span>DaysFrom{`(`}</span> <span>W</span>
      (Week) / <span>M</span>(Month) / <span>Q</span>(Quarter) / <span>Y</span>
      (Year)<span>{`) `}</span>
    </p>
    <p className="leading-4 3xl:leading-6">
      <label>LN / EXP Format:</label> <span>LN{`(`}</span> Number / TagName /
      ExpName<span>{`) `}</span> OR <span>EXP{`(`}</span> Number / TagName /
      ExpName<span>{`) `}</span>
    </p>

    <p className="leading-4 3xl:leading-6">
      <label>Sample Expression:</label> <span>•</span>{" "}
      {`(IIF(ISNULL({TG:120-FI-7.VD},-1)=-1,1,20)+{TG:106-PI-803.PV}) + {EX:Sales Gas Value}+{EX:LF APS-7 GC GM-106A}`}{" "}
      <br></br> <span>•</span>{" "}
      {`DaysFrom(Q)+(EXP({EX:GC TVP temp@Abqaiq}))+(LN({TG:106-PI-803.PV}))`}
    </p>
  </div>
);

const Info2 = () => (
  <div className="sample-expression mt-2 ">
    <p className="text-[15px] 2xl:text-[18px]">
      <label>Supports:</label> <span>+</span>, <span>-</span>, <span>*</span>,{" "}
      <span>/</span>, <span>IIF</span>, <span>ISNULL</span>, <span>AVG</span>,{" "}
      <span>LN</span>, <span>EXP</span>, <span>TG</span>, <span>EX</span>,{" "}
      <span>DaysFrom</span>
    </p>
    <p className="leading-4 3xl:leading-6">
      <label>TagName: </label> <span>{`{`}</span>TG:PITagName<span>{`}`}</span>,{" "}
      <label>ExpressionName: </label>
      <span> {`{`}</span>EX:ExprName<span>@</span>EquipName<span>{`}`}</span> or
      <span> {`{`}</span>EX:ExprName<span>@</span>UnitName<span>{`}`}</span> or{" "}
      <span> {`{`}</span>EX:ExprName<span>@</span>TrainName<span>{`}`}</span> or{" "}
      <span> {`{`}</span>EX:ExprName<span>@</span>PlantName<span>{`}`}</span> or
      <span> {`{`}</span>EX:ExprName<span>{`}`}</span>
      <label>(constant type)</label>{" "}
    </p>
    <p className="leading-4 3xl:leading-6">
      <label>AVG Format:</label> <span>AVG{`({`}</span>TagName/ExprName
      <span>{`}, `}</span>Frequency<span>{`)`}</span>
    </p>
    <p className="leading-4 3xl:leading-6">
      <label>AVG Frequency Format:</label> <span>YTD</span> : Year to Day,{" "}
      <span>MTD</span>: Month to Day, <span>WTD</span>: Week to Day,{" "}
      <span>DTD</span>: Day to Day,<span>Y</span>: Previous Year, <span>W</span>
      : Previous Week,<span>D</span>: Previous Day{" "}
    </p>
    <p className="leading-4 3xl:leading-6">
      <label>DaysFrom Format:</label> <span>DaysFrom{`(`}</span> <span>W</span>
      (Week) / <span>M</span>(Month) / <span>Q</span>(Quarter) / <span>Y</span>
      (Year)<span>{`) `}</span>
    </p>
    <p className="leading-4 3xl:leading-6">
      <label>LN / EXP Format:</label> <span>LN{`(`}</span> Number / TagName /
      ExpName<span>{`) `}</span> OR <span>EXP{`(`}</span> Number / TagName /
      ExpName<span>{`) `}</span>
    </p>
    <p className="leading-4 3xl:leading-6">
      <label>Sample Expression:</label> <span>•</span>{" "}
      {`(IIF(ISNULL({TG:120-FI-7.VD},-1)=-1,1,20)+{TG:106-PI-803.PV}) + {EX:Sales Gas Value}+{EX:LF APS-7 GC GM-106A}`}{" "}
      <br></br> <span>•</span>{" "}
      {`DaysFrom(Q)+(EXP({EX:GC TVP temp@Abqaiq}))+(LN({TG:106-PI-803.PV}))`}
    </p>
  </div>
);

const RadioPoint = ({ label, action, isSelected }: any) => {
  return (
    <div
      onClick={action}
      className="flex items-center space-x-1 cursor-pointer"
    >
      <div className="w-4 h-4 rounded-full border-2 border-white grid place-content-center">
        {isSelected && <div className="w-2 h-2 rounded-full bg-green" />}
      </div>
      <p>{label}</p>
    </div>
  );
};

export default function ExpressionCalculation({
  setExpression,
  expression1,
  setExpression1,
  expression2,
  setExpression2,
  expression3,
  setExpression3,
  equipmentId,
  setEquipmentId,
  unitId,
  setUnitId,
  trainId,
  setTrainId,
  assetType,
  setAssetType,
  type,
  setType,
  setPlantId,
  validationLoading,
  message,
  handleValidation,
  expressionMessage,
  validationResult,
  validationErrors,
  errors,
  setValidationResult,
  setMessage,
  expression,
  data,
}: any) {
  const [calculationBox, setCalculationBox] = useState(false);
  const { trainsSet, unitsSet, equipmentsSet } = useData();

  useEffect(() => {
    if (type === 1 && data?.expression) {
      setExpression(data?.expression);
    }

    if (type === 2 && data?.expression) {
      setExpression(data?.expression);
    }

    if (type === 3 && data?.expression) {
      setExpression(data?.expression);
    }
  }, []);

  useEffect(() => {
    if (type) {
      setCalculationBox(true);
    }
  }, [type]);

  useEffect(() => {
    setValidationResult(null);
    setMessage("");
  }, []);

  return (
    <div className="py-2">
      <div className="flex gap-3">
        <p className="text-[16px] 2xl:text-[19px]">Calculation Type</p>
        {expressionMessage !== "" && !errors && (
          <span className="text-red text-[16px] 2xl:text-[19px]">{expressionMessage}</span>
        )}
      </div>
      <div className="flex gap-5 text-[15px] 2xl:text-[18px] 2xl:pt-4">
        <RadioPoint
          action={() => setType(1)}
          label="Pre-Calculated Expression"
          isSelected={type === 1}
        />
        <RadioPoint
          action={() => setType(2)}
          label="Instant Calculation"
          isSelected={type === 2}
        />
        <RadioPoint
          action={() => setType(3)}
          label="Constant"
          isSelected={type === 3}
        />
      </div>
      {/* Content */}
      {calculationBox && (
        <div className="bg-dark border-2 border-green rounded-md p-3 my-5 relative">
          <div className="bg-dark absolute -top-3 px-2 text-[16px] 2xl:text-[19px]">Expression</div>
          {type === 1 && (
            <div className="">
              <p className="text-[16px] 2xl:text-[19px] pt-1">Type</p>
              <div className="flex justify-between items-center -mt-2 2xl:mt-0">
                <div className="flex gap-5 py-2 text-[16px] 2xl:text-[19px]">
                  <RadioPoint
                    action={() => {
                      setAssetType("1");
                      setPlantId(1);
                      setTrainId(null);
                      setUnitId(null);
                      setEquipmentId(null);
                    }}
                    label="Plant"
                    isSelected={assetType === "1"}
                  />
                  <RadioPoint
                    action={() => {
                      setPlantId(null);
                      setUnitId(null);
                      setEquipmentId(null);
                      setAssetType("2");
                    }}
                    label="Train"
                    isSelected={assetType === "2"}
                  />
                  <RadioPoint
                    action={() => {
                      setAssetType("3");
                      setPlantId(null);
                      setTrainId(null);
                      setEquipmentId(null);
                    }}
                    label="Unit"
                    isSelected={assetType === "3"}
                  />
                  <RadioPoint
                    action={() => {
                      setAssetType("4");
                      setPlantId(null);
                      setTrainId(null);
                      setUnitId(null);
                    }}
                    label="Equipment"
                    isSelected={assetType === "4"}
                  />
                </div>
                {(assetType === "2" ||
                  assetType === "3" ||
                  assetType === "4") && (
                    <div className="">
                      <ExpressionCalculationMenu
                        data={
                          assetType === "2"
                            ? trainsSet
                            : assetType === "3"
                              ? unitsSet
                              : equipmentsSet
                        }
                        title={
                          assetType === "2"
                            ? "Trains"
                            : assetType === "3"
                              ? "Units"
                              : "Equipments"
                        }
                        value={
                          assetType === "2"
                            ? trainId
                            : assetType === "3"
                              ? unitId
                              : equipmentId
                        }
                        setValue={
                          assetType === "2"
                            ? setTrainId
                            : assetType === "3"
                              ? setUnitId
                              : setEquipmentId
                        }
                      />
                    </div>
                  )}
              </div>
              {/* Text Area */}
              <div className="py-1">
                <p className="text-[16px] 2xl:text-[18px]">Expression</p>
                <textarea
                  rows={5}
                  className="input text-[16px] 2xl:text-[18px] max-h-40"
                  value={expression}
                  onChange={(event: any) => {
                    setExpression(event.target.value);
                    setExpression1(event.target.value);
                  }}
                />
              </div>
              <div className="flex gap-2 items-center">
                <span onClick={handleValidation} className="btn">
                  Validate
                </span>
                {validationResult &&
                  message !== "" &&
                  !validationLoading &&
                  !validationErrors && (
                    <>
                      <span>Result: {validationResult}</span>
                      <span className="ml-3 text-green">{message}</span>
                    </>
                  )}
                {validationLoading && <SmallLoader />}
                {validationErrors?.length > 0 && (
                  <div>
                    {validationErrors.map((error: string) => (
                      <p key={error} className="text-red">
                        {error}
                      </p>
                    ))}
                  </div>
                )}
              </div>
              <Info />
            </div>
          )}
          {type === 2 && (
            <div className="">
              <p className="text-[16px] 2xl:text-[19px] pt-1">Type</p>
              <div className="flex justify-between items-center -mt-2 2xl:mt-0">
                <div className="flex gap-5 py-2 text-[16px] 2xl:text-[19px]">
                  <RadioPoint
                    action={() => {
                      setAssetType("1");
                      setPlantId(1);
                    }}
                    label="Plant"
                    isSelected={assetType === "1"}
                  />
                  <RadioPoint
                    action={() => setAssetType("2")}
                    label="Train"
                    isSelected={assetType === "2"}
                  />
                  <RadioPoint
                    action={() => setAssetType("3")}
                    label="Unit"
                    isSelected={assetType === "3"}
                  />
                  <RadioPoint
                    action={() => setAssetType("4")}
                    label="Equipment"
                    isSelected={assetType === "4"}
                  />
                </div>
                {(assetType === "2" ||
                  assetType === "3" ||
                  assetType === "4") && (
                    <div className="pt-2">
                      <ExpressionCalculationMenu
                        data={
                          assetType === "2"
                            ? trainsSet
                            : assetType === "3"
                              ? unitsSet
                              : equipmentsSet
                        }
                        title={
                          assetType === "2"
                            ? "Trains"
                            : assetType === "3"
                              ? "Units"
                              : "Equipments"
                        }
                        value={
                          assetType === "2"
                            ? trainId
                            : assetType === "3"
                              ? unitId
                              : equipmentId
                        }
                        setValue={
                          assetType === "2"
                            ? setTrainId
                            : assetType === "3"
                              ? setUnitId
                              : setEquipmentId
                        }
                      />
                    </div>
                  )}
              </div>
              <div className="py-1">
                <p className="text-[16px] 2xl:text-[18px]">Expression</p>
                <textarea
                  rows={5}
                  className="input text-[16px] 2xl:text-[18px]"
                  value={expression}
                  onChange={(event: any) => {
                    setEquipmentId(null);
                    setUnitId(null);
                    setTrainId(null);
                    setPlantId(1);
                    setExpression(event.target.value);
                    setExpression2(event.target.value);
                  }}
                />
              </div>
              <div className="flex gap-2 items-center">
                <span onClick={handleValidation} className="btn">
                  Validate
                </span>
                {validationResult &&
                  message !== "" &&
                  !validationLoading &&
                  !validationErrors && (
                    <>
                      <span>Result: {validationResult}</span>
                      <span className="ml-3 text-green">{message}</span>
                    </>
                  )}
                {validationLoading && <SmallLoader />}
                {validationErrors?.length > 0 && (
                  <div>
                    {validationErrors.map((error: string) => (
                      <p key={error} className="text-red">
                        {error}
                      </p>
                    ))}
                  </div>
                )}
              </div>
              <Info2 />
            </div>
          )}
          {type === 3 && (
            <div className="mt-3">
              <p>Expression</p>
              <input
                type="number"
                className="input mt-3"
                value={expression}
                onChange={(event: any) => {
                  setEquipmentId(null);
                  setUnitId(null);
                  setTrainId(null);
                  setExpression(event.target.value);
                  setExpression3(parseInt(event.target.value));
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
