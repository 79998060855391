/* eslint-disable @typescript-eslint/no-unused-expressions */
import { energyApi } from "utilities/Axios";
import { AlertHistoryProps } from "../types/alarmtypes";
import { alarmsAPI } from "./types/apiTypes";

export const updateAlarm = (tagId: number, status: number) => {
  return energyApi.get(`/AlertTag/SetAlertStatus?tagId=${tagId}&status=${status}`);
};

export const getAlarms= (): Promise<alarmsAPI> => {
  return energyApi.get("/AlertTag/GetAlertTagHistories");
};

export const updateAlarmHistory = (alarmId: number, status: number) => {
  return energyApi.get(
    `/AlertTag/SetAlertHistoryStatus?tagHistoryId=${alarmId}&status=${status}`
  );
};

export const deleteAlarm = (alarmId: number) => {
  return energyApi.get(`/AlertTag/DeleteAlertHistory?tagHistoryId=${alarmId}`);
};

export const getCount = (): Promise<number> => {
  return energyApi.get("/AlertTag/GetActiveAlertCount");
};

export const deleteAllAlarm = () => {
  return energyApi.get("/AlertTag/DeleteAllAlerts");
};

export const acceptAllAlarm = () => {
  return energyApi.get("/AlertTag/AcceptAllAlerts");
};

