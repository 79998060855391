
export default (data: any) => {
  return data.map((single: any) => {
    return {
      ...single,
      ...(single.createdOnUTC && { createdOnUTC: new Date(single.createdOnUTC).toISOString().split('T')[0] }),
      ...(single.size && { size: single.size > 999 ? `${(single.size / 1000).toFixed(1)} KB` : `${single.size} B` }),
    };
  });
};
