import { IFile } from "../utils/constants";

export const handleUpload = (
  uploadedFiles: FileList | null,
  acceptedFormats: string[]
): IFile[] | null => {
  let expectedFileFormat = true;
  const fileCollection: IFile[] = [];

  if (uploadedFiles && uploadedFiles.length) {
    for (let i = 0; i < uploadedFiles.length; i++) {
      if (!acceptedFormats.includes(uploadedFiles[i].type)) {
        expectedFileFormat = false;
        break;
      }
      const fileDetail: IFile = {
        fileName: uploadedFiles[i].name,
        file: uploadedFiles[i],
      };
      fileCollection.push(fileDetail);
    }
  }

  return expectedFileFormat ? fileCollection : null;
};
