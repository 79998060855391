import React from "react";
import { ActionFormItemProps } from "./domain/types";
import classNames from "utilities/ClassNames";

const genValue = (type: string, val: string) => {
  if (type === 'number') {
    return Number(val);
  }
  return val;
};

const ActionFormItem: React.FC<ActionFormItemProps> = ({ item, tag, setTag }) => {
  const { name, id, placeHolder, isRequired, type, itemWidth } = item;
  const value = tag[id];

  const handleChange = (e: { target: { value: string } }) => {
    const selValue = genValue(type, e.target.value);
    setTag({ ...tag, [id]: selValue });
  };

  return (
    <div className={classNames(itemWidth === 'half' ? "w-[48%]" : "w-full", "flex flex-col gap-0.5 2xl:gap-2")}>
      <label htmlFor={id} className="text-[16px] 2xl:text-[20px]">{name}</label>
      <input
        id={id}
        type={type}
        placeholder={placeHolder}
        required={isRequired}
        value={value?.toString()}
        onChange={handleChange}
        className="col-span-3 text-[14px] 2xl:text-[18px] input h-8"
      />
    </div>
  );
};

export default ActionFormItem;