import { resetTag } from "api/tags";

const resetTagService = async (id: any) => {
  const response: any = await resetTag(id);
  if (response.status === 200) {
    return { message: "success" };
  }
  if (response.status !== 200) {
    return { message: "failure" };
  }
};

export default resetTagService;