import { deleteExpression } from "api/expression";

const deleteExpressionService = async (id: any) => {
  const response: any = await deleteExpression(id);
  if (response.status === 200) {
    return { message: "success" };
  }
  if (response.status !== 200) {
    return { message: "failure" };
  }
};

export default deleteExpressionService;