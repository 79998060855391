import { getCount } from "api/alarm";

export const getAlarmCount = async (): Promise<number | undefined> => {
  try {
    const response: any = await getCount();
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    console.error("Error fetching the request", e);
    throw e;
  }

  return undefined;
};

