export default async ({ tag, api }: any) => {
  const response: any = await api(tag);
  if (response.status === 200) {
    return { message: "success" };
  }

  if (response.status === 400) {
    if (response.data.Validations) {
      return { message: "failure", value: response.data.Validations };
    } else {
      return { message: "failure", value: response.data.title };
    }
  }
};