import { gaugeConfig } from "../../config/configurations";
import { DrawSplitsProps } from "../../domain/types";

export const drawSplits = ({
  chart,
  values,
  gaugeType,
}: DrawSplitsProps) => {
  const {
    ctx,
    data,
    chartArea: { width, height, left, right },
  } = chart;
  ctx.save();

  const { splitsWidth } = gaugeConfig[gaugeType];

  const cx = width / 2;
  const cy = chart._metasets[0].data[0].y;

  const Iy = chart._metasets[0].data[0].y;

  // stroke line definition
  ctx.beginPath();
  ctx.lineWidth = splitsWidth;
  ctx.strokeStyle = "#fff";

  // Define a scale factor based on the width of the component
  const scaleFactor = width / 230; // Assuming the original width was 200

  // drawing lines - starts
  ctx.moveTo(cx - 85 * scaleFactor, Iy - 25.5 * scaleFactor);
  ctx.lineTo(cx - 72 * scaleFactor, Iy - 21.5 * scaleFactor);
  ctx.stroke();

  ctx.moveTo(cx - 53 * scaleFactor, Iy - 72 * scaleFactor);
  ctx.lineTo(cx - 44.7 * scaleFactor, Iy - 60.4 * scaleFactor);
  ctx.stroke();

  ctx.moveTo(cx, Iy - 88 * scaleFactor);
  ctx.lineTo(cx, Iy - 74.5 * scaleFactor);
  ctx.stroke();

  ctx.moveTo(cx + 53 * scaleFactor, Iy - 72 * scaleFactor);
  ctx.lineTo(cx + 43.8 * scaleFactor, Iy - 59.5 * scaleFactor);
  ctx.stroke();

  ctx.moveTo(cx + 84.5 * scaleFactor, Iy - 25 * scaleFactor);
  ctx.lineTo(cx + 70.5 * scaleFactor, Iy - 21 * scaleFactor);
  ctx.stroke();

  ctx.restore();

  const { maxValue } = values;
  const mediumValue = maxValue >= 100;
  const bigValue = maxValue >= 1000;
  const biggestValue = maxValue >= 10000;
  ctx.font = `normal ${(biggestValue ? 10 : bigValue ? 11 : mediumValue ? 12 : 13) * scaleFactor}px Cera-Pro`;
  ctx.fillStyle = "rgba(255, 255, 255, 0.4)";
  let temp = 0;
  if (values.minValue != 0) {
    temp = values.minValue;
  }

  const sectionValue = values.total / 4;


  // in between values
  ctx.fillText(Math.round(sectionValue + temp), cx - (biggestValue ? 26 : bigValue ? 20 : mediumValue ? 35 : 39) * scaleFactor, Iy - 45 * scaleFactor);
  ctx.fillText(Math.round(sectionValue * 2 + temp), cx + (biggestValue ? 16 : bigValue ? 14 : mediumValue ? 7 : 5) * scaleFactor, Iy - 62 * scaleFactor);
  ctx.fillText(Math.round(sectionValue * 3 + temp), cx + (biggestValue ? 50 : bigValue ? 48 : mediumValue ? 48 : 48) * scaleFactor, Iy - 45 * scaleFactor);

  // minValue
  ctx.textAlign = "left";
  ctx.fillText(values.minValue, left + 45 * scaleFactor, cy - 10 * scaleFactor);

  // maxValue
  ctx.textAlign = "right";
  ctx.fillText(values.maxValue, right - 40 * scaleFactor, cy - 10 * scaleFactor);

  ctx.restore();
};

export default drawSplits;
