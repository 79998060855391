export const tagsState = {
  equipmentId: null,
  name: "",
  tagNameIdentifier: "",
  desc: "",
  uom: "",
  minValue: 0,
  maxValue: 0,
  minRange: 0,
  maxRange: 0,
};

export const expressionsState = {
  equipmentId: null,
  name: "",
  desc: "",
  uom: "",
  minValue: 0,
  maxValue: 0,
  minRange: 0,
  maxRange: 0,
  seqOrder: 0,
};

export const assetsState = {
  name: "",
  plantId: 1,
  plantName: null,
  desc: "",
  seqOrder: 0,
};

export const ticketsState = {
  comments: '',
  descriptions: '',
  priority: null,
  severity: null,
  tagId: null,
  title: '',
  createdByName: 'string',
  modifiedByName: 'string',
};