import useApp from "hooks/useApp";
import useScreen from "hooks/useScreen";
import { Navigate, Outlet } from "react-router-dom";

export const AuthGuard = () => {
  const { isLoggedIn } = useApp();

  let content;

  if (isLoggedIn === "loggedIn") {
    content = <Outlet />;
  } else {
    content = <Navigate to="*" />;
  }

  return content;
};
