import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import calculateData from "utilities/calculateData";
import { BarProps, MetaData } from "./domain/types";
import calcPercentage from "./utils/calcPercentage";
import getBarLimits from "./config/getBarLimits";
import classNames from "utilities/ClassNames";
import barConfig from "./config/configuration";
import calcBarLength from "./utils/calcBarLength";

const Bar: React.FC<BarProps> = ({ meta, name, barType }) => {
  const [operatingBG, setOperatingBG] = useState<string[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [inRange, setInRange] = useState<any>(0);
  const [calculatedDataMeta, setCalculatedDataMeta] = useState<MetaData>();
  const [barLength, setBarLength] = useState<string>();
  const [scales, setScales] = useState<any>([]);
  const [scalesPoints, setScalesPoints] = useState<any>([]);

  const { maxValue, minRange, maxRange, value, minValue } = meta;
  const {
    containerClasses,
    titleClasses,
    titleContainerClasses,
    barDisplayClasses,
    barDisplayHeight,
    scalesSize,
    scalesValuesClasses,
    unitSize,
    valueSize,
    barScalesAmount
  } = barConfig[barType];

  useEffect(() => {
    if (meta && meta.value !== null && !calculatedDataMeta) {
      setCalculatedDataMeta(calculateData(meta, barScalesAmount));
    }
    if (calculatedDataMeta) {
      setTotal(calculatedDataMeta.totalValue);
      setOperatingBG(calculatedDataMeta.rangeSet.bg);
      const less = value < minRange;
      const more = value > maxRange;
      setInRange(!less && !more);
    }
  }, [meta, calculatedDataMeta]);

  useEffect(() => {
    setBarLength(calcBarLength(value, minValue, maxValue));
  }, [meta, value, calculatedDataMeta]);

  useEffect(() => {
    const [_scalesPoints, _scales] = getBarLimits(minRange, maxRange, total, operatingBG);
    setScalesPoints(_scalesPoints);
    setScales(_scales);
  }, [operatingBG, calculatedDataMeta]);

  return (
    <div className={classNames(containerClasses, "relative w-full")}>
      <div className={classNames(titleContainerClasses, "flex justify-between")}>
        <h1 className={classNames(titleClasses, 'font-bold inline-block')}>
          {name ? name : meta.name}
        </h1>
        <div className={classNames(valueSize, "text-md text-stone-100 flex gap-1 items-center pl-1")}>
          {value ? value?.toFixed(2) : "N/A"}
          <span className={classNames(unitSize)}>{meta?.uom}</span>
        </div>
      </div>

      <div className={classNames(barDisplayClasses, "text-md rounded-xl bg-gradient-to-r from-[#3D5E7C] to-[#3D5E7C]")}>
        {barLength && (
          <motion.div
            initial={{ width: 0 }}
            animate={{ width: barLength }}
            transition={{ duration: 1 }}
            className={classNames(
              inRange
                ? "from-[#64F1CE] to-[#489BE7]"
                : "from-[#FEA855] to-[#FA5C5F]",
              barDisplayHeight,
              "bg-gradient-to-r rounded-xl relative"
            )}
          />
        )}
      </div>

      {operatingBG && operatingBG.length > 0 && (
        <div className="flex justify-evenly mt-1 ml-1">
          {operatingBG.map((bit: any, index: number) => (
            <div
              key={`${index}-bit`}
              className={classNames(scalesSize, "rounded-full")}
              style={{ background: bit }}
            ></div>
          ))}
        </div>
      )}

      {scales && scales.length > 0 && scalesPoints && (
        <div className="flex justify-between px-1">
          {scales.map((line: any, index: number) => {
            const rightClass = index === (scales.length - 1) ? 'right-0' : 'right-5';
            return (
              <div
                key={`${index}-${line.value}-line-value`}
                style={{
                  width: calcPercentage(line.value, barScalesAmount),
                }}
                className="rounded-xl h-5 flex justify-between relative"
              >
                {scales.length && (
                  <div className={classNames(scalesValuesClasses, index + 1 === scales.length ? "right-0" : "", "absolute font-bold")}>{scalesPoints[index]}</div>
                )}
              </div>
            );
          })}
        </div>
      )}
      {
        (!scales || !scales.length || !scalesPoints || !operatingBG) && (
          <div className="lg:text-xs xl-2xl:text-[14px] 2xl:text-[16.5px] py-1.5">No Min/Max values available</div>
        )
      }
    </div >
  );
};

export default Bar;
