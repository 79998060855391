export const iconsColor = { color: "white" };

export const iconsSizes = {};

export const fontStyles = {};

export const inputLabelStyles = { marginTop: "-6px", color: "white" };


export const Actions = ["Information", "Created", "Viewed", "Modified", "Deleted"];
export const Modules = [
  "System",
  "User",
  "Ticket",
  "Alarm",
  "Tags",
  "Expression",
  "Train",
  "Unit",
  "Equipment",
  "Bubble",
  "Note",
];

