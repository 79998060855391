import Bar from "components/charts/Bar/Bar";
import Area from "components/charts/Area/Area";
import { useState, useEffect } from "react";
import { getTagData } from "api/tags";
import { getExpressionData } from "api/expression";
import useData from "hooks/useData";

export default function AreaPage({ tab }: any) {
  const { pastDate } = useData();
  const [expression, setExpression] = useState<any>(tab?.configurations?.value);
  const [areaData, setAreaData] = useState<any>(null);
  const graphid = tab?.configurations?.id;

  useEffect(() => {
    if (tab?.configurations?.value === '') {
      setExpression(null);
    }
    else {
      setExpression(tab?.configurations?.value);
    }
  }, [tab]);

  useEffect(() => {
    const fetchData = async () => {
      if (tab.configurations.type === "tags") {
        const response = await getTagData([graphid], pastDate);
        if (response.status === 200) {
          setAreaData(response.data[0]);
        }
        else {
          setAreaData(null);
        }
      } else {
        const response = await getExpressionData([graphid], pastDate);
        if (response.status === 200) {
          setAreaData(response.data[0]);
        }
        else {
          setAreaData(null);
        }
      }
    };
    fetchData();
    const intervalId = setInterval(fetchData, 15000);
    return () => clearInterval(intervalId);
  }, [tab, pastDate]);

  return (
    <>
      <div className="flex flex-col justify-center h-full gap-1">
        <div className="bg-dark px-1 py-2 rounded-md h-full">
          <div className="w-full h-[75%] xl-2xl:h-[80%]">
            {expression === null ?
              null :
              <Area
                item={expression}
                type={tab.configurations.type === "tags" ? "tag" : "expression"}
                areaType="regular"
                renderType="lazyLoading"
                maintainAspectRatio={false}
              />
            }
          </div>
          <div className="w-[80%] mx-auto h-max pt-1">
            {areaData && (
              <Bar
                meta={areaData}
                barType={"small"}
                name={tab?.configurations?.name}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
