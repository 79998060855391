import { Bar } from "react-chartjs-2";
import 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);
import getHighestMaxRange from "utilities/getHighestMaxRange";
import getHighestValue from "utilities/getHighestValue";
import getOptions from "./config/getOptions";
import getBarChartData from "./utils/getBarChartData";
import { useEffect, useState } from "react";

const Column = ({ rawData, names }: any) => {
  const [maxValueGradientHeight, setMaxValueGradientHeight] = useState(0);
  const data = rawData.filter((item: any) => item !== undefined);
  const maxRange = getHighestMaxRange(data);
  const highestValue = Math.round(getHighestValue(data) * 1.1);
  const rangeHeight = (highestValue - maxRange) / highestValue;
  const rangeHeightPercentage = ((rangeHeight * 100) * 1.28);
  const MAX_LENGTH = 10;
  const windowWidth = window.innerWidth;

  let fontSize;
  if (windowWidth < 1500) {
    fontSize = 12;
  } else if (windowWidth <= 1920) {
    fontSize = 14;
  } else {
    fontSize = 16;
  }

  const chartData = getBarChartData({ names, maxLabelsLength: MAX_LENGTH, data, maxRange });
  const options = getOptions({ fontSize, highestValue, maxRange, data });

  useEffect(() => {
    if (rangeHeightPercentage < 5) {
      setMaxValueGradientHeight(rangeHeightPercentage + 4);
    }
    else {
      setMaxValueGradientHeight(rangeHeightPercentage);
    }
  }, [rangeHeightPercentage]);

  return (
    <div className="h-full p-2 relative overflow-hidden rounded-lg">
      {rangeHeight > 0 && <span
        className="absolute top-0 left-0 right-0 z-0"
        style={{
          height: `${maxValueGradientHeight}%`,
          background: 'linear-gradient(to top, #111111, #e11d48)',
        }}
      ></span>}
      <div className="absolute h-full top-0 left-0 right-0 z-10 p-2 outline">
        <Bar data={chartData} options={options} />
      </div>
    </div>
  );
};

export default Column;
