import { deleteExplorerFiles } from "api/fileManager";

const deleteFiles = async (data: any) => {
  const response = await deleteExplorerFiles(data);
  if (response.status === 200) {
    return { message: "success" };
  }
  if (response.status !== 200) {
    return { message: "failure" };
  }
};

export default deleteFiles;