import React, { useCallback, useState, useEffect } from "react";
import defaultSignalR from "./config/defaultSignalR";
import { FiX } from "react-icons/fi";
import useApp from "hooks/useApp";
import useSignalR from "hooks/useSignalR";
import { status } from "./utils/constants";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { User } from "oidc-client-ts";
import { SmallestLoader } from "components/shared/Loader";
import BorderGradient from "components/shared/BorderGradient/BorderGradient";

const ProgressPopup = ({ setProgressPopup, setFileLoading, setId, uuid, abortController }: any) => {

  const [signalRDetails, setSignalRDetails] = useState<any>(defaultSignalR);
  const { isLoggedIn } = useApp();
  const { FileUploadHandler } = useSignalR();

  const closeHandler = useCallback(() => {
    const text =
      "Closing the window will abort the file upload. Are you sure you want to close ?";
    if (window.confirm(text) === true) {
      setProgressPopup(false);
      abortController?.abort();
      setFileLoading(false);
      setId("");
      setSignalRDetails(defaultSignalR);
    }
  }, [abortController]);

  const updateSignalRDetails = useCallback(
    (data: any) => {
      if (data.id === uuid) {
        setSignalRDetails(data);
      }
    },
    [uuid]
  );

  function getUser() {
    const oidcStorage = sessionStorage.getItem(
      `oidc.user:${process.env.REACT_APP_AUTH_APP}:${process.env.REACT_APP_CLIENT_ID}`
    );
    if (!oidcStorage) {
      return null;
    }
    return User.fromStorageString(oidcStorage);
  }

  useEffect(() => {
    if (isLoggedIn === "loggedIn") {
      FileUploadHandler(updateSignalRDetails);
    }
  }, [isLoggedIn, updateSignalRDetails, FileUploadHandler]);

  return (
    <>
      <div className="absolute z-40 inset-0 w-full h-full bg-black/70" />
      <BorderGradient className="w-full h-auto p-1 rounded-md z-50 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:max-w-[600px]">
        <div className="bg-black p-3 rounded-md">
          <div className="flex justify-between items-center">
            <div className="flex gap-2">
              <h1>{signalRDetails.currentMessage === '' ? 'File Uploading...' : signalRDetails.currentMessage}</h1>
              <SmallestLoader />
            </div>
            <span
              className="cursor-pointer"
              onClick={() => closeHandler()}
            >
              <FiX size={20} />
            </span>
          </div>
          <p>
            {status[signalRDetails.status]}
            {`${signalRDetails.currentPercentage} `}%
          </p>
          <div className="py-[15px] px-[30px]">
            <div className="Progressbar">
              <div
                className="Progressbar__value"
                style={{
                  width: `${signalRDetails.currentPercentage}%`,
                }}
              ></div>
              <progress
                value={signalRDetails.currentPercentage}
                max="100"
              >
                100%
              </progress>
            </div>
          </div >
          <div style={{ textAlign: "center" }}>
            <p>
              {" "}
              <span>
                {signalRDetails.currentMessage}{" "}
              </span>{" "}
              <span>{signalRDetails.currentCount} </span> /{" "}
              <span id="TotalCount">
                {signalRDetails.totalCount}
              </span>{" "}
            </p>
            <label id="CurrentStage">
              {signalRDetails.currentStage}
            </label>
            /
            <label id="TotalStage">
              {signalRDetails.totalStage}
            </label>
          </div>
          <div className="flex justify-end">
            <button
              onClick={() => closeHandler()}
              className="btn-sec"
              type="button"
            >
              Cancel Upload
            </button>
          </div>
        </div >
      </BorderGradient >
    </>
  );
};

export default ProgressPopup;