import Loader from "components/shared/Loader";
import { usePins } from "hooks/usePins";
import Pin from "./Pin";
import useScreen from "hooks/useScreen";
import classNames from "utilities/ClassNames";
import { BsPinAngle } from "react-icons/bs";
import { useEffect, useState } from "react";

export default function PinnedWidgets() {
  const { dashboardPins, pinLoading } = usePins();
  const [allPins, setAllPins] = useState<any>([]);

  useEffect(() => {
    setAllPins(dashboardPins);
  }, [dashboardPins]);

  const genWidgets = (pins: any) => {
    if (pins !== null) {
      if (pins.length > 0) {
        return (
          pins.map((pin: any) => {
            if (pin.data.length !== 0) {
              return (
                <Pin key={pin.meta.id} data={pin} />
              );
            }
          })
        );
      }
      if (pins.length === 0) {
        return (
          <div className="h-min-[120px] bg-black rounded-md p-0.5 gradient 2xl:w-full 2xl:min-w-[150px] flex items-center flex-wrap">
            <div className="bg-black h-full rounded text-xl">
              <p className="p-4 text-center">
                In order to pin the widgets, go to the tags tab in the menu, choose the selected tag and press a
                <span className="inline-block relative w-6 h-5 pt-1 p-0.5">
                  <span className="inline-block absolute bg-dark top-1 left-1 p-0.5">
                    <BsPinAngle size={17} />
                  </span>
                </span> button
              </p>
            </div>
          </div >
        );
      }
    }
  };

  return (
    <div className="flex flex-row 2xl:flex-col w-full">
      {pinLoading && (
        <div className="w-full h-full p-3 grid place-content-center">
          <Loader />
        </div>
      )}
      {allPins && (
        <div
          className={classNames("flex-col",
            "w-full flex flew-wrap gap-3 flex-row overflow-hidden h-full p-3 pb-0 pt-2 2xl:pr-0 2xl:mb-3 overflow-x-scroll 2xl:overflow-y-scroll 2xl:overflow-x-hidden"
          )}
        >
          {genWidgets(allPins)}
        </div>
      )}
    </div>
  );
}
