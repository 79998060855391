import { Chart as ChartJS } from "chart.js";
import { Point } from "../domain/types";
import { Item } from "types/common";
import jsPDF from "jspdf";
import dayjs from "dayjs";

export const resetZoom = (chartRef: React.RefObject<ChartJS<"line", Point[]> | null>) => {
  chartRef?.current?.resetZoom();
};

export const zoomIn = (chartRef: React.RefObject<ChartJS<"line", Point[]> | null>) => {
  chartRef?.current?.zoom(1.1);
};

export const zoomOut = (chartRef: React.RefObject<ChartJS<"line", Point[]> | null>) => {
  chartRef?.current?.zoom(0.9);
};

export const downloadPng = (chartRef: React.RefObject<ChartJS<"line", Point[]> | null>, item: Item) => {
  const link = document.createElement("a");
  link.download = item.name;
  link.href = chartRef?.current?.toBase64Image() || '';
  link.click();
};

export const downloadPdf = (chartRef: React.RefObject<ChartJS<"line", Point[]> | null>, item: Item) => {
  const image = chartRef?.current?.canvas.toDataURL("image/jpg", 1.0);
  const pdf = new jsPDF("landscape");
  pdf.setFontSize(20);
  if (image) {
    pdf.addImage(image, "JPEG", 15, 15, 260, 150);
    pdf.save(item.name);
  }
};

export const downloadCsv = (title: string, dataList: Point[], item: Item) => {
  const csvData = [
    ["Date", `${title} Value`],
    ...dataList.map((item: any) => [
      dayjs(item.x).format("YYYY-MM-DDTHH:mm:ss"),
      item.y,
    ]),
  ]
    .map((e: any) => e.join(","))
    .join("\n");

  const link = document.createElement("a");
  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
  link.href = URL.createObjectURL(blob);
  link.download = item.name;
  link.click();
};