import { getEquipment, getUnit } from "api/unity";
import { Item } from "types/common";
import { bubbleDetails, bubbleTagExpressionProps } from "types/unitytypes";

export const TagExpressionHandler = async (
  bubbleId: number,
  assertId: number,
  type: string,
  tagorexpression: string
) => {
  const details: bubbleTagExpressionProps = {
    unitId: null,
    details: null,
    collections: null,
  };

  const unitequipment: any =
    type === "equipment"
      ? await getEquipment(assertId)
      : await getUnit(assertId);


  
  const collections: Item[] =
    tagorexpression === "expression"
      ? unitequipment.data.expressions
      : unitequipment.data.tags;
  let bubbledetails: bubbleDetails | null = null;
  bubbledetails = {
    name: unitequipment.data.name,
    nameIdentifier: unitequipment.data.nameIdentifier,
    id: unitequipment.data.id,
    type: tagorexpression,
  };
  details.unitId = unitequipment.data.unitId;
  details.details = bubbledetails;
  details.collections = collections;

  return details;
};
