import { cn } from "@/lib/utils";
import useScreen from "hooks/useScreen";
import { useState, useEffect } from "react";

const BrainImg = () => {
  const [value, setValue] = useState<any>(94);
  const{isScaled} = useScreen();
  function randomIntFromInterval() {
    const min = 88,
      max = 95;
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  useEffect(() => {
    const setRandomAccuracy = setInterval(() => {
      setValue(randomIntFromInterval);
    }, 0.5 * 1000);

    return () => {
      clearInterval(setRandomAccuracy);
    };
  }, []);

  return (
    <div className={cn("lg:w-[230px]  2xl:w-[270px]","2xl:p-3 lg:p-1.5")}>
      <div>
        <div
          className={cn("lg:h-[195px] 2xl:h-[200px]")}
          style={{
            position: "relative",
            backgroundImage:
              "linear-gradient(145deg, rgba(101,244,205,1) 0%, rgba(71,153,233,1) 100%)",

            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
            padding: "0",
            color: "white",
            boxShadow: "0px 0px 19px 4px rgba(16,18,62,0.64)",
          }}
        >
          <div
            style={{
              paddingBottom: "1rem",
              fontSize: "16px",
              marginTop: "-40px",
            }}
          >
            AI Model
          </div>
          <div>
            <div
              style={{
                padding: "2rem",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ fontSize: "16px", marginTop: "-10px" }}>
                {value + "%"}
              </div>
              <div>Accuracy</div>
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  background: "white",
                  width: "100px",
                  height: "100px",
                  padding: "2rem",
                  opacity: "0.27",
                  borderRadius: "50%",
                  animation: "pulse-ai 1s infinite",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  borderRadius: "50%",
                  height: "100px",
                  width: "100px",
                  border: "2px solid white",
                  transform: "scale(0.9)",
                }}
              />
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "2.3rem",
              width: "150px",
              height: "90px",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 67.05 45.96"
              style={{ fill: "#fff" }}
            >
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <g
                    id="buttonLeft"
                    className="svg-button-left"
                    style={{
                      transition: "opacity .3s ease-in-out",
                      animation: "fade-bar 1s infinite",
                      animationDelay: "1.8s",
                    }}
                  >
                    <g id="Layer_1-2-2" data-name="Layer 1-2">
                      <path
                        className="cls-1"
                        d="M9,24.27A27.43,27.43,0,0,0,21.92,37.08l-2.68,5.69A33.63,33.63,0,0,1,3.32,27Z"
                      />
                    </g>
                  </g>
                  <g
                    id="bottomRight"
                    className="svg-button-right"
                    style={{
                      transition: "opacity .4s ease-in-out",
                      animation: "fade-bar 1s infinite",
                      animationDelay: "1.4s",
                    }}
                  >
                    <g id="Layer_1-2-3" data-name="Layer 1-2">
                      <path
                        className="cls-1"
                        d="M59.09,21.83,65,24A33.62,33.62,0,0,1,50.69,41.24l-3.23-5.41A27.44,27.44,0,0,0,59.09,21.83Z"
                      />
                    </g>
                  </g>
                  <g
                    id="bottom"
                    className="svg-button"
                    style={{
                      transition: "opacity .5s ease-in-out",
                      animation: "fade-bar 1s infinite",
                      animationDelay: "1.6s",
                    }}
                  >
                    <g id="Layer_1-2-4" data-name="Layer 1-2">
                      <path
                        className="cls-1"
                        d="M43.86,37.63l2.4,5.83A33.41,33.41,0,0,1,33.51,46a33.89,33.89,0,0,1-9.65-1.41l1.81-6a27.38,27.38,0,0,0,7.84,1.08A27,27,0,0,0,43.86,37.63Z"
                      />
                    </g>
                  </g>
                  <g
                    id="TopLeft"
                    className="svg-top-left"
                    style={{
                      transition: "opacity .6s ease-in-out",
                      animation: "fade-bar 1s infinite",
                      animationDelay: "2s",
                    }}
                  >
                    <g id="Layer_1-2-5" data-name="Layer 1-2">
                      <path
                        className="cls-1"
                        d="M2.37,0,8.22,2.34A27.35,27.35,0,0,0,7.5,20.52l-6,1.87a33.9,33.9,0,0,1-1.5-10A33.28,33.28,0,0,1,2.37,0Z"
                      />
                    </g>
                  </g>
                  <g
                    id="topRight"
                    className="svg-top-right"
                    style={{
                      transition: "opacity .2s ease-in-out",
                      animation: "fade-bar 1s infinite",
                      animationDelay: "1.2s",
                    }}
                  >
                    <g id="Layer_1-2-6" data-name="Layer 1-2">
                      <path
                        className="cls-1"
                        d="M64.66.59A33.15,33.15,0,0,1,67.05,13a33.67,33.67,0,0,1-.69,6.8l-6.17-1.23a27.84,27.84,0,0,0,.56-5.54A27,27,0,0,0,58.81,2.93Z"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrainImg;
