export const handleDownload = (data:any) => {
    const jsonString = JSON.stringify(data, null, 2);
    const b64 = window.btoa(jsonString) + "ajx";
    const blob = new Blob([b64], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "logs.ph";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };