import { Point } from "../domain/types";
import { Item } from "types/common";
import { TooltipItem } from "chart.js";
import genMinValue from "../utils/genMinValue";

function getOptions(minValue: number, maxValue: number, transitionPoint: Point, item: Item, maxTicksLimit: number, maintainAspectRatio: boolean, firstY: any) {
  const differenceVal = (maxValue - minValue) * 0.15;
  return {
    animation: {
      duration: 0,
    },
    interaction: {
      mode: 'nearest' as const,
      axis: 'x' as const,
      intersect: false,
    },
    responsive: true,
    maintainAspectRatio: maintainAspectRatio,
    scales: {
      x: {
        type: "time" as const,
        time: {
          unit: "day" as const,
        },
        distribution: 'series',
        grid: {
          display: false,
        },
        ticks: {
          color: "#f1f5f9",
          font: {
            size: window.innerWidth < 1500 ? 9 : (window.innerWidth < 1800 ? 10 : 13),
          },
          maxTicksLimit,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#f1f5f9",
          crossAlign: "far" as const,
          font: {
            size: window.innerWidth < 1500 ? 9 : (window.innerWidth < 1800 ? 10 : 13),
          },
          callback: function (value: any) {
            if (value < 50 && value >= 10) {
              return value.toFixed(1);
            }
            if (value > 50) {
              return Math.round(value);
            } else {
              return value.toFixed(2);
            }
          },
        },
        min: genMinValue(minValue, differenceVal),
        max: maxValue + differenceVal,
      },
    },
    elements: {
      line: {
        tension: 0,
        borderWidth: 2,
      },
    },
    plugins: {
      datalabels: {
        // hide datalabels for all datasets
        display: false,
      },
      hover: {
        mode: 'index' as const,
        animationDuration: 0,
      },
      annotation: {
        annotations: {
          breakPoint: {
            type: 'point' as const,
            xScaleID: 'x' as const,
            yScaleID: 'y' as const,
            xValue: transitionPoint.x,
            yValue: transitionPoint.y,
            backgroundColor: 'rgb(170, 255, 255)',
            radius: 4,
            label: {
              enabled: true,
              content: 'Break Point' as const,
            },
          },
          xAxisLine: {
            type: 'line' as const,
            scaleID: 'x' as const,
            borderColor: 'white' as const,
            borderWidth: 2,
            mode: 'horizontal' as const,
            value: firstY.x,
          },
          yAxisLine: {
            type: 'line' as const,
            scaleID: 'y' as const,
            borderColor: 'white' as const,
            borderWidth: 2,
            mode: 'vertical' as const,
            value: genMinValue(minValue, differenceVal),
          },
        },
      },
      tooltip: {
        enabled: true,
        mode: "nearest" as const,
        position: "nearest" as const,
        bodyFont: {
          size: 11,
        },
        titleFont: {
          size: 11,
        },
        borderColor: '#14b8a6',
        borderWidth: 1,
        axis: "x" as const,
        intersect: false,
        filter: function (tooltipItem: any) {
          return tooltipItem.datasetIndex < 2;
        },
        callbacks: {
          label: function (tooltipItem: TooltipItem<"line">) {
            const { label } = tooltipItem.dataset;
            const tooltipNames: { [key: string]: string } = {
              'Tag Value': 'Hist. Data',
              'Prediction Value': 'Prediction',
              'minSet': 'Min',
              'maxSet': 'Max',
            };
            if (label) {
              const tooltipName = tooltipNames[label];
              return `${tooltipName} ${tooltipItem.formattedValue}`;
            }
          }
        }
      },
      legend: {
        display: false,
      },
      zoom: {
        zoom: {
          drag: {
            enabled: true,
            backgroundColor: "rgba(62, 252, 173, 0.2)",
            borderColor: "rgb(19, 146, 93)",
            borderWidth: 1,
          },
          wheel: {
            enabled: true,
            modifierKey: "ctrl" as const,
          },
          mode: "x" as const, // Only allow zooming on the x axis
        },
        limits: {
          x: {
            min: item.minRange,
          },
        },
      },
    },
  };
}

export default getOptions;