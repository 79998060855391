import useData from "hooks/useData";
import React, { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import useScreen from "hooks/useScreen";
import classNames from "utilities/ClassNames";
import GraphContainer from "components/GraphContainer/GraphContainer";
import chartInfoConfig from "./config/chartInfoConfig";
import chartModalConfig from "./config/chartModalConfig";
import { ChartModalProps, InfoItemProps } from "./domain/types";
import { Item } from "types/common";
import Area from "components/charts/Area/Area";
import { BiExpand } from "react-icons/bi";

const genInfoItem: React.FC<InfoItemProps> = ({ item, tag }) => {
  const { label, value } = item;
  return (
    <div className="flex items-center">
      <p>{label}</p>
      <div className="flex-1" />
      <p>{tag[value as keyof typeof tag]}</p>
    </div>
  );
};

const ChartModal: React.FC<ChartModalProps> = ({
  page,
  selectedTag,
  setSelectedTag,
  isAlarm,
  setTicket,
  setIsBigGraph,
  isBigGraph,
}) => {
  const { isScaled } = useScreen();
  const { getTagInfo } = useData();
  const [tag, setTag] = useState<Item>();
  const { containerClasses } = chartModalConfig[page];

  useEffect(() => {
    if (selectedTag.action === "chart") {
      const result = getTagInfo(selectedTag?.value?.id);
      setTag(result);
    }
  }, [selectedTag]);

  return (
    <>
      <div className={classNames(containerClasses, "w-full flex")}>
        <div className="bg-dark p-3 w-full min-h-full rounded-md">
          <div className="flex flex-col h-full">
            {/* Header */}
            <div className="flex justify-end">
              {!isBigGraph && (<div
                className="cursor-pointer bg-green rounded-md p-0.5 2xl:p-1"
                onClick={() =>
                  setSelectedTag({ action: "", value: null })
                }
              >
                <FiX size={isScaled ? 25 : 20} />
              </div>)}
              {isBigGraph && (<div
                className="cursor-pointer bg-green rounded-md p-1"
                onClick={() => {
                  if (setIsBigGraph) {
                    setIsBigGraph(false);
                  }
                }}
              >
                <BiExpand size={18} />
              </div>)}
            </div>
            {/* content */}
            {selectedTag && selectedTag.value && (
              <>
                <div className="w-full mx-auto flex-grow gradient p-0.5 rounded-lg mt-2 h-max">
                  {(page === 'tags' || page === 'alarms') && (
                    isBigGraph ?
                      <div className="bg-dark px-1 py-2 rounded-md h-full flex-grow">
                        <Area item={selectedTag.value} type='tag' areaType="yearly" renderType="lazyLoading" maintainAspectRatio={true}/>
                      </div> :
                      <GraphContainer data={selectedTag.value} type='tag' isAlarm={isAlarm} setTicket={setTicket} setBigGraph={setIsBigGraph} />
                  )}
                  {page === 'expressions' && (
                    isBigGraph ?
                      <div className="bg-dark px-1 py-2 rounded-md h-full flex-grow">
                        <Area item={selectedTag.value} type='expression' areaType="yearly" renderType="lazyLoading" maintainAspectRatio={true}/>
                      </div> :
                      <GraphContainer data={selectedTag.value} type='expression' setBigGraph={setIsBigGraph} />)}
                </div>
              </>
            )}
            {/* Footer */}
            {tag && (page === 'tags' || page === 'alarms') && (
              <div className="text-md 2xl:text-lg pt-5">
                {chartInfoConfig.map((item) => {
                  return (
                    genInfoItem({ item, tag })
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div >
    </>
  );
};

export default ChartModal;