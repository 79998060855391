export const AlltabTypes = [
    {
      name: "4 Numbers & unit",
      graphtype: "kpi",
    },
    {
      name: "1 Graph & bar",
      graphtype: "area",
    },
    {
      name: "4 Gauges",
      graphtype: "gauge",
    },
    {
      name: "4 Gauge/Graph & bar",
      graphtype: "gaugeBar",
    },
    {
      name: "1 Gauge/Graph",
      graphtype: "oneGaugeBar",
    },
    {
      name: "4 Column Charts",
      graphtype: "column",
    }
  ];