import Loader from "components/shared/Loader";
import { useEffect, useState } from "react";
import { getAllHealth, refreshHealth } from "api/health";
import BorderGradient from "components/shared/BorderGradient/BorderGradient";
import DataTable from "components/DataTable/DataTable";
import getStatusLabel from "./utils/getStatusLabel";
import classNames from "utilities/ClassNames";
export default function HealthContainer() {
  const [loading, setLoading] = useState(true);
  const [data, setdata] = useState<any>([]);
  const [processedData, setProcessedData] = useState([]);
  const getAllHealthData = async () => {
    const response: any = await getAllHealth();
    if (response && response.status === 200) {
      setdata(response.data);
    }
    setLoading(false);
  };
  const refreshHealthwithType = async (type: any) => {
    const response: any = await refreshHealth(type);
    if (response && response.status === 200) {
      if (data.length) {
        const tempData = data.filter((val: any) => val.id !== type);
        const respData = response.data;
        const DataToBeRefreshed = data.filter(
          (val: any) => val.id === respData.type
        )[0];
        DataToBeRefreshed.message = respData.message;
        DataToBeRefreshed.id = respData.type;
        DataToBeRefreshed.status = respData.status;
        const curr = new Date();
        DataToBeRefreshed.lastCheckedOnUTC = curr;
        const updatedData = [...tempData, DataToBeRefreshed];
        setdata(updatedData);
      }
    }
  };
  useEffect(() => {
    getAllHealthData();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      const newData = data.map((item: any) => {
        return {
          ...item,
          status: getStatusLabel(item.status),
          updateTime: getDateformat(item.id),
        };
      });
      setProcessedData(newData);
    }
  }, [data]);

  const getDateformat = (id: any) => {
    const val = data.filter((obj: any) => obj.id === id);
    if (val.length) {
      const d = new Date(val[0].lastCheckedOnUTC);
      return d.toLocaleString();
    }
  };
  const getstatuscolor = (status: any, ismessage: any) => {
    let color: any = "";
    switch (status) {
      case 1:
        color = ismessage ? "skyblue" : "limegreen";
        break;
      case 2:
        color = "orange";
        break;
      case 3:
        color = "crimson";
        break;
    }
    return color;
  };

  const handleActionClick = (e: any) => {
    refreshHealthwithType(e.id);
  };

  const handleClick = () => {
  };

  return (
    <>
      {loading && (
        <div className="w-full h-[80vh] grid place-content-center">
          <Loader />
        </div>
      )}
      {data && !loading && (
        <div
          className={classNames("text-base pl-3 pt-3 pb-3 overflow-hidden overflow-y-scroll"
          )}
        >
          <div className="mt-3 h-[78vh]">
            <BorderGradient className=" overflow-hidden h-full">
              <DataTable type="health" hasPins={false} hasActions={true} rows={processedData} onClickHandler={handleClick} onActionClickHandler={handleActionClick} />
            </BorderGradient>
          </div>
        </div>
      )}
    </>
  );
}
