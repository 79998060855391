import * as React from "react";
import { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import LogDetails from "./LogDetails";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import useScreen from "hooks/useScreen";
import classNames from "utilities/ClassNames";
import { getData } from "./domain/getLogsData";
import { getIconForAction } from "./config/IconMapping";
import { inputLabelStyles, iconsColor, iconsSizes, Actions, Modules} from "./utils/constants";
import { StyledOutlinedInput } from "./config/DropdownlistStyledInput";

interface ILogs {
  action: string;
  at: string;
  by: string;
  desc: string;
  detail: string;
  module: string;
}



export default function CustomizedTimeline(props: any) {
  const logdata = props.logs;
  const [data, setdata] = useState<any>(null);
  const [Action, setAction] = React.useState<any>(null);
  const [ActionLabel, setActionLabel] = React.useState<any>("Action");
  const [Module, setModule] = React.useState<any>(null);
  const [ModuleLabel, setModuleLabel] = React.useState<any>("Module");
  const [DisplayedLogDetails, setDisplayedLogDetails] = useState<any>(null);
  const [showData, setshowData] = useState(false);
  const [DaystoFilter, setDaystoFilter] = useState<any>(null);
  const { isScaled } = useScreen();

  const handleActionChange = (event: any) => {
    setAction(event.target.value);
    setActionLabel(null);
    setDisplayedLogDetails(null);
  };

  const handleModuleChange = (event: any) => {
    setModule(event.target.value);
    // setModuleLabel(Modules[event.target.value]);
    setModuleLabel(null);
    setDisplayedLogDetails(null);
  };

  const handleClear = () => {
    setModule(null);
    setAction(null);
    setActionLabel("Action");
    setModuleLabel("Module");
    setDaystoFilter(null);
    setDisplayedLogDetails(null);
  };

  const onDaysChange = (event: any) => {
    setDaystoFilter(event.target.value);
  };

  useEffect(() => {
    if (logdata) setdata(logdata);
  }, [logdata]);

  useEffect(() => {
    (async () => {
      try {
        const logsdata = await getData({
          action: Action,
          module: Module,
          DaystoFilter: DaystoFilter,
        });
        setdata(logsdata);
      } catch (error) {
        console.error("error logs: ", error);
      }
    })();
  }, [Action, Module, DaystoFilter]);


  return (
    <>
      <div className="flex h-full">
        <div
          className={classNames(
            "h-full",
            "gradient w-2/3 mr-2.5 p-0.5 rounded-lg"
          )}
        >
          <div
            style={{
              display: "flex",
              padding: "10px",
              borderRadius: "7px",
            }}
            className="bg-black h-full"
          >
            <div
              className="h-auto"
            >
              <div className="xl:flex flex-row" >
                <div className="text-white xl:text-sm 2xl:text-lg ">Filter by Day: </div>
                <input
                className="border-b-2 border-green-500 bg-transparent max-w-16 text-center xl:text-sm 2xl:text-lg"
                  placeholder="10"
                  onChange={onDaysChange}
                ></input>
                <div className="text-white xl:text-sm 2xl:text-lg">days</div>
              </div>
              <div className="xl:flex flex-row mt-6">
                <FormControl style={{ minWidth: "120px" }}>
                  <InputLabel id="action-select-label" style={inputLabelStyles}>
                    {ModuleLabel}
                  </InputLabel>
                  <Select
                    labelId="action-select-label"
                    id="action-select"
                    className="custom-select-style lg:h-[30px] lg:mt-[6px] 2xl:h-[40px] 2xl:mt-0"
                    value={Module}
                    onChange={handleModuleChange}
                    style={{ color: "white"}}
                    input={<StyledOutlinedInput />}
                  >
                    {Modules.map((module, index) => (
                      <MenuItem key={index} value={index}>
                        {module}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl style={{ minWidth: "120px", margin: window.innerWidth<1440?"0 0px":"0 10px" }}>
                  <InputLabel id="action-select-label" style={inputLabelStyles}>
                    {ActionLabel}
                  </InputLabel>
                  <Select
                    labelId="action-select-label"
                    id="action-select"
                    className="custom-select-style  lg:h-[30px] lg:mt-[6px] 2xl:h-[40px] 2xl:mt-0"
                    value={Action}
                    // label="Action"
                    onChange={handleActionChange}
                    style={{color: "white" }}
                    input={<StyledOutlinedInput />}
                  >
                    {Actions.map((action, index) => (
                      <MenuItem key={index} value={index}>
                        {action}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div
                  className={classNames(
                      "lg:mt-2",
                    "underline text-[rgb(22,245,211)] cursor-pointer"
                  )}
                  onClick={handleClear}
                >
                  Clear
                </div>
              </div>
            </div>
            <Timeline
              style={{
                height: "100%",
                width: "1000px",
                overflowY: "scroll",
              }}
            >
              {data &&
                data.map((item: any, index: any) => (
                  <TimelineItem
                    key={index}
                    style={{
                      flexShrink: 0,
                      // width:'530px',
                      background:
                        item === DisplayedLogDetails
                          ? "rgb(22, 245, 211,0.6)"
                          : "",
                      borderRadius: "5px",
                    }}
                    className="hover:opacity-80 cursor-pointer w-[500px] lg:w-[480px] 2xl:w-[700px]"
                    onClick={() => {
                      setDisplayedLogDetails(item);
                      setshowData(true);
                    }}
                  >
                    <TimelineOppositeContent
                      sx={{ m: "auto 0" }}
                      align="left"
                      variant="body2"
                      color="white"
                      className="-ml-[20px]"
                      // style={{ minWidth: "200px", marginLeft: "-20px" }}
                    >
                      <div className={"xl:text-xs 2xl:text-[1rem]"}>{item.at}</div>
                      <div className={"xl:text-xs 2xl:text-[1rem]"}>{item.module}</div>
                      <div className={"xl:text-xs 2xl:text-[1rem]"}>{item.by}</div>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineConnector />
                      {item.action && (
                        <>
                          {getIconForAction(
                            item.action,
                            iconsColor,
                            iconsSizes
                          )}
                        </>
                      )}
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent
                      sx={{ py: "12px", px: 2 }}
                      className=""
                    >
                      <div className={"xl:text-xs 2xl:text-[1rem] mt-4"}>{item.desc}</div>
                      {/* <ReadMore Text={item.detail} /> */}
                    </TimelineContent>
                  </TimelineItem>
                ))}
            </Timeline>
          </div>
        </div>
        {showData && DisplayedLogDetails && (
          <div
            className={classNames(
                "h-full",
              "w-[32.5%] gradient rounded-lg p-0.5"
            )}
          >
            <div
              style={{
                borderRadius: "7px",
              }}
              className="bg-black h-full overflow-scroll"
            >
              <LogDetails LogData={DisplayedLogDetails}></LogDetails>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
