import classNames from "utilities/ClassNames";
import { FaFilePdf } from 'react-icons/fa';
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@/components/ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import capitalizeFirstLetter from "utilities/capitalize";
import dayjs from "dayjs";



export function DocumentList(Files: any, docView: (id: any) => Promise<void>, actionbtns: any, docDownload: (id: any, name: any) => Promise<void>, unMapDoc: (id: any) => Promise<void>) {
    return <div className="bg-dark overflow-auto p-3 mb-2 rounded-md h-full flex">
      <Table className="w-full border-collapse">
        <TableHeader className="border-b-2">
          {<TableRow className="weigh no-underline border-none">
            <TableHead className="font-bold text-[15px] xl:text-[17px] 2xl:text-xl cursor-pointer">
              {" "}
              File
            </TableHead>
            <TableHead className="font-bold text-[15px] xl:text-[17px] 2xl:text-xl cursor-pointer">
              Type
            </TableHead>
            <TableHead className="font-bold text-[15px] xl:text-[17px] 2xl:text-xl cursor-pointer">
              Size
            </TableHead>
            <TableHead className="font-bold text-[15px] xl:text-[17px] 2xl:text-xl cursor-pointer">
              Created On
            </TableHead>
            <TableHead className="font-bold text-[15px] xl:text-[17px] 2xl:text-xl cursor-pointer">
              Actions
            </TableHead>
          </TableRow>}
        </TableHeader>
  
        <TableBody>
          {!Files && (
            <div className="mt-4 text-lg 3xl:text-xl text-lightGray font-semibold">
              No Documents Found!
            </div>
          )}
          {Files &&
            Files.length >= 1 &&
            Files.map((file: any, index: any) => (
              <TableRow
                key={index}
                className="border-b border-b-lightGray hover:bg-dark duration-300 text-md"
              >
                <TableCell
                  className="text-green cursor-pointer font-bold"
                  onClick={() => docView(file.fileId)}
                >
                  {file.fileName}
                </TableCell>
                <TableCell>
                  {<FaFilePdf size={20} />}
                </TableCell>
                <TableCell>
                  {`${file.size * 0.001} KB`}
                </TableCell>
                <TableCell>
                  {dayjs(file.createdOnUTC).format(
                    "MM/DD/YYYY"
                  )}
                  ,{" "}
                  {dayjs(file.createdOnUTC).format(
                    "HH:mm:ss A"
                  )}
                </TableCell>
                <TableCell className="flex gap-2 w-36">
                  {actionbtns.map((single: any, key: any) => (
                    <TooltipProvider
                      delayDuration={0}
                      key={key}
                    >
                      <Tooltip>
                        <TooltipTrigger
                          className={classNames(
                            "hover:text-green font-bold"
                          )}
                          onClick={() => single.name === "download"
                            ? docDownload(
                              file.fileId,
                              file.fileName
                            )
                            : unMapDoc(file.fileId)}
                        >
                          {single.icon}
                        </TooltipTrigger>
                        <TooltipContent>
                          <p
                            className={classNames(
                              "text-[#34d399] text-md pt-1"
                            )}
                          >
                            {capitalizeFirstLetter(single.name)}
                          </p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  ))}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>;
  }