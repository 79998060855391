import { deleteTrain } from "api/trains";

const deleteTrainService = async (id: any) => {
  const response: any = await deleteTrain(id);
  if (response.status === 200) {
    return { message: "success" };
  }
  if (response.status !== 200) {
    return { message: "failure" };
  }
};

export default deleteTrainService;