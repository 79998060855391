import getGradient from "./getGradient";
import { ChartDataSet } from "../domain/types";

const getDataSet = ({ item, dataList, predictionList, minValue, maxValue, isPredictionShown }: ChartDataSet) => {
  const { minRange, maxRange } = item;

  let fullArray = dataList;

  if (predictionList && predictionList.length > 0 && isPredictionShown) {
    fullArray = fullArray?.concat(predictionList);
  }

  const minSetData = minValue < minRange ?
    fullArray?.map((set: any) => ({
      x: set.x,
      y: item.minRange,
    })) : null;

  const maxSetData = maxValue > maxRange ?
    fullArray?.map((set: any) => ({
      x: set.x,
      y: item.maxRange,
    })) : null;

  let minSet = null;
  let maxSet = null;

  if (minSetData) {
    minSet = {
      fill: "start",
      label: "minSet",
      data: minSetData,
      borderColor: 'rgb(253, 79, 105, 0.5)',
      backgroundColor: (context: any) => {
        const ctx = context.chart.ctx;
        const chartArea = context.chart.chartArea;
        return getGradient({ ctx, chartArea, dataType: 'min' });
      },
      lineTension: 0.3,
      pointRadius: 0,
      pointHoverRadius: 0,
      order: 2,
    };
  }

  if (maxSetData) {
    maxSet = {
      fill: "end",
      label: "maxSet",
      data: maxSetData,
      borderColor: 'rgb(253, 79, 105, 0.5)',
      backgroundColor: (context: any) => {
        const ctx = context.chart.ctx;
        const chartArea = context.chart.chartArea;
        return getGradient({ ctx, chartArea, dataType: 'max' });
      },
      lineTension: 0.3,
      pointRadius: 0,
      pointHoverRadius: 0,
      order: 2,
    };
  }

  const defaultDataSet = {
    fill: true,
    label: "Tag Value",
    data: dataList,
    borderColor: "rgb(53, 162, 235)",
    backgroundColor: "rgba(53, 162, 235, 0.5)",
    lineTension: 0,
    pointRadius: 0,
    pointHoverRadius: 6,
    pointHoverBackgroundColor: '#FFA400',
    order: 1,
  };

  const futureDataSet = {
    fill: true,
    label: "Prediction Value",
    data: predictionList,
    borderColor: "rgb(170, 255, 255)",
    backgroundColor: "rgba(170, 255, 255, 0.5)",
    lineTension: 0,
    pointRadius: 0,
    pointHoverRadius: 6,
    pointHoverBackgroundColor: '#FFA400',
    order: 1,
  };

  const datasetsArray = [];
  datasetsArray.push(defaultDataSet);
  isPredictionShown && datasetsArray.push(futureDataSet);

  if (minSet) {
    datasetsArray.push(minSet);
  }

  if (maxSet) {
    datasetsArray.push(maxSet);
  }

  return {
    datasets: datasetsArray,
  };
};

export default getDataSet;
