const TextOverflow = (text: string | undefined | null, letters: number) => {
  if (text && text.toString().length > letters) {
    const slicedText = text.toString().slice(0, letters);
    return (
      <>
        {slicedText}
        <span className="text-xs">...</span>
      </>
    );
  } else if (text) {
    return text;
  } else {
    return null; // Or any other default value you prefer
  }
};

export default TextOverflow;