import { useState, useEffect } from "react";
import SelectInput from "components/shared/SelectInput/SelectInput";

const FormSelects = ({ selects, setTag, tag }: any) => {
  return (
    <div className="flex flew-wrap justify-between">
      {
        selects.map((select: any) => {
          const [selectedItem, setSelectedItem] = useState<null | number>(null);

          const handleChange = (e: any) => {
            setSelectedItem(Number(e));
          };

          useEffect(() => {
            setTag({ ...tag, [select.id]: selectedItem });
          }, [selectedItem]);

          return (
            <SelectInput key={select.name} label={select.name} options={select.items} onValueChange={handleChange} value={tag[select.id]} />
          );
        })}
    </div>
  );
};

export default FormSelects;