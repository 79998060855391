const getStatusLabel = (statusId: any) => {
  const statusLabel = [
    { value: 0, label: "Unknown" },
    { value: 1, label: "Healthy" },
    { value: 2, label: "Warning" },
    { value: 3, label: "Failed" },
  ];
  return statusLabel.find((status) => {
    return status.value === statusId;
  })?.label;
};

export default getStatusLabel;