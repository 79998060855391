import { useState } from "react";
import Menu from "components/widgets/Menu";
import {
  FiEdit,
  FiMoreVertical,
  FiPlusSquare,
  FiSettings,
  FiTrash2,
} from "react-icons/fi";
import classNames from "utilities/ClassNames";
import Configuration from "./ChartConfiguration";
import TabBody from "./TabBody";
import TabConfiguration from "./TabConfiguration";
import { TabContainer } from "components/DynamicWidgets/TabContainers";
import TabsHeading from "./TabsHeading";
import { generateConfirmationDialog } from "components/DynamicWidgets/DeleteWidgetTab";
import { EditTabWidget } from "components/DynamicWidgets/EditTabWidget";
import { initialTab } from "../../pages/Dashboard/AllDynamicWidgets/config/initialtab";

export default function Tabs({
  currentTab,
  setCurrentTab,
  screen,
  setScreen,
  newTab,
  setNewTab,
  tabs,
  widget,
  addNewTab,
  removeTab,
  updateTab,
  updateWidgets,
  removeWidget,
  type,
}: any) {
  const [tabError, setTabError] = useState<string>("");

  const handleUpdateTab = () => {
    if (newTab.name === "") {
      setTabError("All fields are required");
    } else {
      updateTab(widget.id, tabs[currentTab].id);
      setScreen("");
      setTabError("");
    }
  };

  const handleNewTabconfirmation = () => {
    if (newTab.name === "" || newTab.type === "") {
      setTabError("All fields are required");
    } else {
      addNewTab(widget.id);
      setScreen("");
      setNewTab(initialTab);
      setTabError("");
    }
  };

  // Menu
  const handleNewTab = () => {
    setNewTab({
      name: "",
      type: "",
      configurations: null,
      configured: false,
    });
    setTabError("");
    setScreen("newTab");
  };

  const handleEditTab = () => {
    setNewTab({
      name: tabs[currentTab].name,
      type: tabs[currentTab].type,
      configurations: tabs[currentTab].configuration,
    });
    setScreen("editTab");
  };

  const handleConfigureTab = () => {
    setScreen("configuration");
  };

  const handleDeleteTab = () => {
    setScreen("deleteTabConfirm");
  };

  const handleDeleteWidget = () => {
    setScreen("deleteWidgetConfirm");
  };

  const menu = [
    {
      text: "New Tab",
      icon: <FiPlusSquare className="mr-2 h-5 w-5" size={15} />,
      action: () => {
        setNewTab({
          name: "",
          type: "",
          configurations: null,
          configured: false,
        });
        setTabError("");
        setScreen("newTab");
      },
    },
    {
      text: "Edit Tab",
      icon: <FiEdit className="mr-2 h-5 w-5" size={15} />,
      action: () => {
        setNewTab({
          name: tabs[currentTab].name,
          type: tabs[currentTab].type,
          configurations: tabs[currentTab].configuration,
        });
        setScreen("editTab");
      },
    },
    {
      text: "Configure Tab",
      icon: <FiSettings className="mr-2 h-5 w-5" size={15} />,
      action: () => {
        setScreen("configuration");
      },
    },
    {
      text: "Delete Tab",
      icon: <FiTrash2 className="text-red mr-2 h-5 w-5" size={15} />,
      action: () => {
        setScreen("deleteTabConfirm");
      },
    },
  ];

  const menuButton = (
    <div className="cursor-pointer duration-300 hover:text-green">
      <FiMoreVertical size={20} />
    </div>
  );

  if (screen === "newTab") {
    return (
      <EditTabWidget
        tabError={tabError}
        tabMode={"new"}
        setScreen={setScreen}
        setNewTab={setNewTab}
        setTabError={setTabError}
        submit={handleNewTabconfirmation}
      >
        <TabConfiguration
          setTabError={setTabError}
          newTab={newTab}
          addNewTab={addNewTab}
          setNewTab={setNewTab}
          widget={widget}
          tabError={tabError}
          setScreen={setScreen}
          tabMode={"new"}
          type={type}
        />
      </EditTabWidget>
    );
  }

  if (screen === "editTab") {
    return (
      <EditTabWidget
        tabError={tabError}
        tabMode={"edit"}
        setScreen={setScreen}
        setNewTab={setNewTab}
        setTabError={setTabError}
        submit={handleUpdateTab}
      >
        <TabConfiguration
          setTabError={setTabError}
          newTab={newTab}
          updateTab={updateTab}
          setNewTab={setNewTab}
          widget={widget}
          tabError={tabError}
          setScreen={setScreen}
          tabs={tabs}
          currentTab={currentTab}
          tabMode={"edit"}
        />
      </EditTabWidget>
    );
  }
  const widgetConfirmationDialog = generateConfirmationDialog({
    title: "Remove Widget",
    content: "Are you sure you want to remove this widget",
    onCancel: () => setScreen(""),
    onRemove: () => removeWidget(widget.id),
  });

  const tabConfirmationDialog = generateConfirmationDialog({
    title: "Remove Tab",
    content: "Are you sure you want to remove this tab",
    onCancel: () => setScreen(""),
    onRemove: () => removeTab(widget.id, tabs[currentTab].id),
  });

  if (screen === "deleteTabConfirm") {
    return tabConfirmationDialog;
  }

  if (screen === "deleteWidgetConfirm") {
    return widgetConfirmationDialog;
  }

  if (screen === "configuration") {
    return (
      <Configuration
        widget={widget}
        screen={screen}
        setScreen={setScreen}
        tab={widget.tabs[currentTab]}
        tabs={widget.tabs}
        currentTab={currentTab}
        updateTab={updateTab}
        updateWidgets={updateWidgets}
        tabError={tabError}
        setTabError={setTabError}
        setNewTab={setNewTab}
      />
    );
  }

  if (widget.tabs.length < 1 && widget.tabs.length <= 4) {
    return (
      <div className="flex-1 grid place-content-center w-full h-full gap-3 relative z-10">
        <div
          className="text-center flex flex-col gap-3 items-center justify-center cursor-pointer duration-300"
          onClick={() => setScreen("newTab")}
        >
          <FiSettings size={50} />
          <div className={classNames("")}>
            Start configuring Widget
          </div>
        </div>
      </div>
    );
  }

  return (
    <TabContainer config={"configured"}>
      <TabsHeading
        tabs={widget.tabs}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      ></TabsHeading>
      <Menu
        button={menuButton}
        menu={menu}
        tabsLength={tabs.length}
        newTab={handleNewTab}
        editTab={handleEditTab}
        configureTab={handleConfigureTab}
        deleteTab={handleDeleteTab}
        deleteWidget={handleDeleteWidget}
      />
      <TabBody
        screen={screen}
        tabs={widget.tabs}
        currentTab={currentTab}
        setScreen={setScreen}
        type={type}
      />
    </TabContainer>
  );
}
