import { getUserTickets } from "api/tickets";
import Loader from "components/shared/Loader";
import { useEffect, useMemo, useState } from "react";
import Debounce from "utilities/Debounce";
import { priorityStatus, severityStatus, status } from "./utils/constants";
import TableManager from "components/TableManager/TableManager";
import PopoverContainer from "components/Popover/PopoverContainer";
import BorderGradient from "components/shared/BorderGradient/BorderGradient";
import DataTable from "components/DataTable/DataTable";
import { Item, ActionName } from "types/common";
import Modal from "components/modal/Modal";
import { filterArray } from "utilities/FilterArray";


export default function TicketsContainer() {
  const [loading, setLoading] = useState(true);
  const [rawData, setRawData] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const [rawProcessedData, setRawProcessedData] = useState<any>([]);
  const [error, setError] = useState<any>(null);
  const [keyword, setKeyword] = useState("");
  const [debouncedKeyword, setDebouncedKeyword] = useState("");
  const [statusFilters, setStatusFilters] = useState<any>([]);
  const [priorityFilters, setPriorityFilters] = useState<any>([]);
  const [severityFilters, setSeverityFilters] = useState<any>([]);
  const [tag, setTag] = useState<any>({
    action: "",
    value: null,
  });

  Debounce(() => setDebouncedKeyword(keyword), [keyword], 500);

  const rows = useMemo(() => {
    let items = data;
    const keyword = debouncedKeyword.toString().toLowerCase();
    if (keyword !== "") {
      const searchByName = data?.filter(
        (item: any) =>
          item.tagName.toLowerCase().includes(keyword) ||
          item.tagName.toLowerCase() === keyword
      );
      items = [...searchByName];
    }
    return items;
  }, [data, debouncedKeyword]);

  const refreshdata = async () => {
    setLoading(true);
    const response: any = await getUserTickets();
    if (response?.status === 200) {
      setRawData(response.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    refreshdata();
  }, []);

  useEffect(() => {
    if (rawData && rawData.length > 0) {
      const processedData = rawData.map((tag: any) => {
        const priorityValue = priorityStatus[tag.priority - 1]?.value || '';
        const severityValue = severityStatus[tag.severity - 1]?.value || '';
        const statusValue = status[tag.statusId - 1]?.value || '';
        const createdOnValue = new Date(Date.parse(tag.createdOn)).toLocaleDateString();

        return {
          ...tag,
          priority: priorityValue,
          severity: severityValue,
          statusId: statusValue,
          createdOn: createdOnValue,
        };
      });
      setRawProcessedData(processedData);
    }
  }, [rawData]);

  useEffect(() => {
    if (rawProcessedData) {
      setData(rawProcessedData);
    }
  }, [rawProcessedData]);

  useEffect(() => {
    let filteredData = rawProcessedData;

    // Apply status filter
    if (statusFilters.length > 0) {
      filteredData = filterArray(statusFilters, filteredData, "statusId");
    }

    // Apply priority filter
    if (priorityFilters.length > 0) {
      filteredData = filterArray(priorityFilters, filteredData, "priority");
    }

    // Apply severity filter
    if (severityFilters.length > 0) {
      filteredData = filterArray(severityFilters, filteredData, "severity");
    }

    // Update the data state with the filtered data
    setData(filteredData);
  }, [statusFilters, priorityFilters, severityFilters, rawProcessedData]);

  if (loading)
    return (
      <div className="w-full min-h-[50vh] grid place-content-center">
        <Loader />
      </div>
    );

  const filterItems = [
    {
      title: 'Status',
      filters: statusFilters,
      options: status,
      setFilters: setStatusFilters,
    },
    {
      title: 'Priority',
      filters: priorityFilters,
      options: priorityStatus,
      setFilters: setPriorityFilters,
    },
    {
      title: 'Severity',
      filters: severityFilters,
      options: severityStatus,
      setFilters: setSeverityFilters,
    }
  ];

  const onClick = (e: any) => {
    setTag({
      action: 'showTicket',
      value: e,
    });
  };

  const handleClick = (itemValue: Item, itemAction: ActionName) => {
    setTag({
      action: itemAction,
      value: itemValue
    });
  };

  return (
    <>
      {loading && (
        <div className="fixed inset-0 grid place-content-center bg-black/70">
          <Loader />
        </div>
      )}
      {error && (
        <div className="w-full h-[80vh] grid place-content-center">
          <p>Error retrieving Tickets, try refreshing the page</p>
        </div>
      )}
      {!loading && !error && data && (
        <div className="pl-3 pt-3 pb-3 overflow-hidden overflow-y-scroll">
          <TableManager type="noBtnAction" setKeyword={setKeyword} >
            <PopoverContainer items={filterItems} />
          </TableManager>
          <div className="mt-3 h-[83vh]">
            <BorderGradient className="overflow-hidden h-full">
              <DataTable type="tickets" hasPins={false} hasActions={true} rows={rows} onClickHandler={onClick} onActionClickHandler={handleClick} />
            </BorderGradient>
          </div>
          <Modal page="tickets" isLoading={loading} refresh={refreshdata} selectedTag={tag} setSelectedTag={setTag} />
        </div>
      )}
    </>
  );
}
