import Layout from "components/layout";
import DashboardConfiguration from "components/shared/DashboardConfiguration/DashboardConfiguration";
import Alarms from "pages/alarms/Alarms";
import AllAssets from "pages/all-assets/AllAssets";
import ExpressionContainer from "pages/expressions/ExpressionContainer";
import TagContainer from "pages/tags/TagContainer";
import TicketsContainer from "pages/tickets/TicketsContainer";
import UnityContainer from "pages/unity";
import { Navigate, Route, Routes } from "react-router-dom";
import { AuthGuard } from "utilities/AuthGuard";
import "./App.css";
import UsersContainer from "pages/users/UsersContainer";
import UnityLayout from "components/layout/UnityLayout";
import NewDashboard from "pages/NewDashboard";
import HealthContainer from "pages/HealthCheck";
import Logs from "pages/Logs";
import watermarkBg from "assets/watermarkBg.png";
import Documents from "pages/documents/Documents";
import Watermark from "components/shared/Watermark/Watermark";

function App() {
  return (
    <>
      <Routes>
        <Route element={<AuthGuard />}>
          <Route element={<Layout />}>
            <Route path="/new-dashboard" element={<NewDashboard />} />
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<NewDashboard />} />
            <Route path="/dashboard-configuration">
              <Route element={<DashboardConfiguration />}>
                <Route
                  index
                  element={<Navigate to="/dashboard-configuration/tags" />}
                />
                <Route path="tags" element={<TagContainer />} />
                <Route path="expressions" element={<ExpressionContainer />} />
                <Route path="documents" element={<Documents />} />
                <Route path="all-assets" element={<AllAssets />} />
              </Route>
            </Route>
            <Route path="/alarms" element={<Alarms />} />
            <Route path="/tickets" element={<TicketsContainer />} />
            <Route path="/users" element={<UsersContainer />} />
            <Route path="/logs" element={<Logs />} />
            <Route path="/health" element={<HealthContainer />} />
          </Route>
          <Route element={<UnityLayout />}>
            <Route path="/3d-dashboard" element={<UnityContainer />} />
          </Route>
        </Route>
        <Route path="*" element={<p>Not Found</p>} />
      </Routes>
      <Watermark />
    </>
  );
}

export default App;
