import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  BsPersonFillCheck,
} from "react-icons/bs";
import useApp from "hooks/useApp";
import {
  UpdateUserRole,
} from "api/users";

export default function RoleDropdown({
  id,
  currentrole,
  rolesData,
  refreshhandler,
}: any) {
  let curr = currentrole;

  const { userRole } = useApp();

  const rolelist: any = [];
  if (rolesData) {
    rolesData.map((role: any) => {
      rolelist.push(role);
    });
  }

  const Capitalize = (str: any) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  const handleChange = (role: any) => {
    const text = "Are you sure you want to change the role ?";
    if (window.confirm(text) === true) {
      return UpdateUserRole({ userId: id, roleId: rolelist[role].id }).then(
        (response: any) => {
          curr = Capitalize(rolelist[role].name);
          refreshhandler();
        },
        (error) => { }
      );
    }
  };

  return userRole === "admin" ? (
    <DropdownMenu>
      {
        <DropdownMenuTrigger asChild>
          <div
            style={{
              border: "groove 2px",
              borderRadius: "3px",
              textAlign: "center",
              cursor: "pointer",
              fontSize: '16px',
              padding: '4px'
            }}
          >
            {curr ? curr : "Unassigned"}
          </div>
        </DropdownMenuTrigger>
      }
      <DropdownMenuContent align="end" className="bg-dark border-slate">
        <DropdownMenuItem
          className="flex gap-2 items-center cursor-pointer"
          onClick={() => handleChange(0)}
        >
          <div style={{ display: "flex", gap: "5px" }}>
            {Capitalize(rolelist[0]?.name)}
            {Capitalize(rolelist[0]?.name) === curr && (
              <BsPersonFillCheck
                style={{ marginTop: "3px" }}
              ></BsPersonFillCheck>
            )}
          </div>
        </DropdownMenuItem>
        <DropdownMenuItem
          className="flex gap-2 items-center cursor-pointer"
          onClick={() => handleChange(1)}
        >
          <div style={{ display: "flex", gap: "5px" }}>
            {Capitalize(rolelist[1]?.name)}
            {Capitalize(rolelist[1]?.name) === curr && (
              <BsPersonFillCheck
                style={{ marginTop: "3px" }}
              ></BsPersonFillCheck>
            )}
          </div>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
      </DropdownMenuContent>
    </DropdownMenu>
  ) : (
    curr
  );
}
