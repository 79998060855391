import { GenGaugeDataProps } from "../domain/types";

const genGaugeData = ({ valueDataSet, operatingBG, operatingDataSet, bgGradient, borderBg }: GenGaugeDataProps) => {
  return {
    labels: [],
    datasets: [
      {
        data: valueDataSet,
        backgroundColor: bgGradient,
        hoverBackgroundColor: bgGradient,
        borderColor: borderBg,
        weight: 35,
        borderWidth: [5, 0],
        borderRadius: [5, 0],
        datalabels: {
          labels: {
            title: null,
          },
        },
        spacing: 0,
      },
      {
        data: [100],
        backgroundColor: ["transparent"],
        hoverBackgroundColor: ["transparent"],
        borderColor: ["transparent"],
        weight: 15,
        datalabels: {
          labels: {
            title: null,
          },
        },
      },
      {
        data: operatingDataSet,
        backgroundColor: operatingBG,
        hoverBackgroundColor: operatingBG,
        borderColor: "transparent",
        weight: 15,
        datalabels: {
          labels: {
            title: null,
          },
        },
      },
      {
        data: [100],
        backgroundColor: ["transparent"],
        hoverBackgroundColor: ["transparent"],
        borderColor: ["transparent"],
        weight: 5,
        datalabels: {
          labels: {
            title: null,
          },
        },
      },
    ],
  };
};

export default genGaugeData;