import { WidgetProps } from "../domain/types";

export function findMissingPosition(widgets:WidgetProps[]) {
    const positions = new Set(widgets.map((widget:WidgetProps) => widget.position));
    for (let i = 1; i <= 5; i++) {
      if (!positions.has(i)) {
        return i;
      }
    }
    return null;
  }