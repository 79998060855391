export const loadDataFromContext = (allBubbles:any,getUnitInfo:any,
    getEquipmentInfo:any,
    getTagInfo:any) => {
    let dataCollection: any = [];
    if (allBubbles) {
      dataCollection = allBubbles.map((bubble: any) => {
        const { unitId, equipmentId } = bubble;

        const dataInfo: any = {
          id: bubble.id,
          x: bubble.x,
          y: bubble.y,
          z: bubble.z,
          unitId: bubble.unitId || 0,
          equipmentId: bubble.equipmentId || 0,
          name: bubble.name ? bubble.name : "",
          // color: -1,
          // size: -1,
        };

        if (unitId && equipmentId) {
          const equipmentInfo = getEquipmentInfo(equipmentId);

          if (equipmentInfo) {
            const { name, color, size } = equipmentInfo;
            if (equipmentInfo?.tagIds.length === 1) {
              const tagInfo = getTagInfo(equipmentInfo.tagIds[0]);
              // dataInfo.name = tagInfo?.name || equipmentInfo.name;
              dataInfo.color = tagInfo?.color || equipmentInfo.color || -1;
              dataInfo.size = tagInfo?.size || equipmentInfo.size || -1;
            } else {
              // dataInfo.name = name;
              dataInfo.color = color || -1;
              dataInfo.size = size || -1;
            }
          }
        } else if (unitId) {
          const unitInfo = getUnitInfo(unitId);
          if (unitInfo) {
            // dataInfo.name = unitInfo.name;
            dataInfo.color = unitInfo.color || -1;
            dataInfo.size = unitInfo.size || -1;
          }
        }

        return dataInfo;
      });
    }

    return dataCollection;
  };