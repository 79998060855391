import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@/components/ui/table";
import BorderGradient from "components/shared/BorderGradient/BorderGradient";
import useScene from "hooks/useScene";
import { useEffect } from "react";
import { FiEdit, FiTrash2, FiX } from "react-icons/fi";

const SceneList = ({
  handleCloseModal,
  setSceneHandlerModalOpen,
  DeleteScene,
  getSceneDetails,
}: any) => {
  const { AllScenesList, LoadScene } = useScene();

  return (
    <div className="z-50 absolute  h-full w-[50%] left-[25%] top-[15%]">
      <BorderGradient className="w-full h-max">
        <div className="w-full p-3 bg-dark rounded-md h-[65vh] overflow-hidden">
          <div className="flex flex-wrap justify-between pb-5">
            <h1>
              <span className="text-green font-bold text-xl">
                {" "}
                {"Projects"}
              </span>
            </h1>
            <div className="flex justify-end">
              <div
                className="mr-5 btn w-full flex justify-center"
                onClick={() => setSceneHandlerModalOpen(true)}
              >
                Add
              </div>
              <button
                className="cursor-pointer bg-green rounded-md p-1"
                onClick={handleCloseModal}
              >
                <FiX size={20} />
              </button>
            </div>
          </div>
          <div className="h-[85%] w-full">
            <div className="bg-dark overflow-auto p-3 mb-2 rounded-md h-full flex">
              <Table className="w-full border-collapse">
                <TableHeader className="border-b-2">
                  {
                    <TableRow className="weigh no-underline border-none">
                      <TableHead className="font-bold text-[15px] xl:text-[17px] 2xl:text-xl cursor-pointer">
                        {" "}
                        Scene
                      </TableHead>
                      <TableHead className="font-bold text-[15px] xl:text-[17px] 2xl:text-xl cursor-pointer">
                        Desc
                      </TableHead>
                      <TableHead className="font-bold text-[15px] xl:text-[17px] 2xl:text-xl cursor-pointer">
                        Date Modified
                      </TableHead>
                      <TableHead className="font-bold text-[15px] xl:text-[17px] 2xl:text-xl cursor-pointer">
                        Actions
                      </TableHead>
                    </TableRow>
                  }
                </TableHeader>

                <TableBody>
                  {!AllScenesList && (
                    <div className="flex absolute right-[45%] top-[50%] text-lg text-lightGray font-semibold">
                      No Documents Found!
                    </div>
                  )}
                  {AllScenesList &&
                    AllScenesList.length >= 1 &&
                    AllScenesList.map((Scene: any, index: any) => (
                      <TableRow
                        key={index}
                        className="border-b border-b-lightGray hover:bg-dark duration-300"
                      >
                        <TableCell
                          className="text-green cursor-pointer font-bold text-xl"
                          onClick={() => LoadScene(Scene.id)}
                        >
                          {Scene.name}
                        </TableCell>
                        <TableCell>{Scene.desc}</TableCell>
                        <TableCell>
                          {Scene.modifiedOnUTC
                            ? new Date(Scene.modifiedOnUTC).toLocaleString()
                            : new Date(Scene.createdOnUTC).toLocaleString()}
                        </TableCell>
                        <TableCell className="flex">
                          <FiEdit
                            size={18}
                            className="flex ml-4 hover:text-green cursor-pointer"
                            onClick={() => getSceneDetails(Scene.id)}
                          />
                          <FiTrash2
                            size={18}
                            className="flex ml-4 hover:text-green cursor-pointer"
                            onClick={() => DeleteScene(Scene.id)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </BorderGradient>
    </div>
  );
};
export default SceneList;
