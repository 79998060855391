import {
  Tooltip as ToolipShadcn,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Modal } from "@mui/material";
import { useState, useEffect } from "react";
import { BiAlarm, BiExpand } from "react-icons/bi";
import { RxIdCard } from "react-icons/rx";
import { Link } from "react-router-dom";
import classNames from "utilities/ClassNames";

const GraphToolTip = ({ data, alarm, setTicket, setBigGraph, type }: any) => {
  const [isAlertTagStatus, setIsAlertTagStatus] = useState<any>(null);

  useEffect(() => {
    setIsAlertTagStatus(data.isAlertTag);
  }, [data]);

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex gap-2">
          {/* 3D Unity */}
          {type === "tag" && (
            <div className="flex gap-2">
              <TooltipProvider delayDuration={0}>
                <ToolipShadcn>
                  <TooltipTrigger>
                    <div
                      className={classNames(
                        "leading-[1.15rem]",
                        "grid place-content-center duration-300 bg-green p-1.5 rounded-md cursor-pointer"
                      )}
                    >
                      <Link
                        to={`/3d-dashboard?bubbleId=${data.equipmentBubbleId}`}
                        target="_self"
                        rel="noopener noreferrer"
                        style={{ color: "white" }}
                      >
                        3D
                      </Link>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent style={{ background: "#34d399" }}>
                    <p className="text-stone-100">Open Unity</p>
                  </TooltipContent>
                </ToolipShadcn>
              </TooltipProvider>
              {/* Alarm */}
              {alarm && (
                <TooltipProvider delayDuration={0}>
                  <ToolipShadcn>
                    <TooltipTrigger>
                      <div
                        onClick={async () => {
                          try {
                            await alarm(data.id, isAlertTagStatus);
                            setIsAlertTagStatus(!isAlertTagStatus);
                          } catch (e) {
                            console.error(e);
                          }
                        }}
                        className={classNames(
                          isAlertTagStatus ? "bg-lightRed" : "bg-green",
                          "grid place-content-center duration-300 p-1.5 leading-3 rounded-md cursor-pointer"
                        )}
                      >
                        <BiAlarm size={18} />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent style={{ background: "#34d399" }}>
                      <p className="text-stone-100">
                        {isAlertTagStatus ? "Remove Alarm" : "Add Alarm"}
                      </p>
                    </TooltipContent>
                  </ToolipShadcn>
                </TooltipProvider>
              )}
              {!data.isNormal && !data.isTicketCreated && (
                <TooltipProvider delayDuration={0}>
                  <ToolipShadcn>
                    <TooltipTrigger>
                      <div
                        onClick={() => setTicket(true)}
                        className="grid place-content-center duration-300 bg-green p-1.5 leading-3 rounded-md cursor-pointer"
                      >
                        <RxIdCard size={18} />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent style={{ background: "#34d399" }}>
                      <p className="text-stone-100">Create Ticket</p>
                    </TooltipContent>
                  </ToolipShadcn>
                </TooltipProvider>
              )}
            </div>
          )}
          {/* Expand */}
          <TooltipProvider delayDuration={0}>
            <ToolipShadcn>
              <TooltipTrigger>
                <div
                  onClick={() => setBigGraph(true)}
                  className="grid place-content-center duration-300 bg-green p-1.5 leading-3 rounded-md cursor-pointer"
                >
                  <BiExpand size={18} />
                </div>
              </TooltipTrigger>
              <TooltipContent style={{ background: "#34d399" }}>
                <p className="text-stone-100">Expand</p>
              </TooltipContent>
            </ToolipShadcn>
          </TooltipProvider>
        </div>
      </div>
    </>
  );
};

export default GraphToolTip;
