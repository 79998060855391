import { handleDownload } from "../utils/DownloadLogs";
import { handleDownloadAsText } from "../utils/DownloadLogsAsText";

export const downloadPopup =[
    {
        name:"Text File",
        onclick: handleDownloadAsText
    },
    {
        name:"Raw",
        onclick: handleDownload
    }
];