import { useState, useEffect } from "react";
import { usePins } from "hooks/usePins";
import { FiX } from "react-icons/fi";
import Bar from "components/charts/Bar/Bar";
import classNames from "utilities/ClassNames";

export default function Pin({ data, unityPin }: any) {
  const { unPin } = usePins();
  const [pinToRemove, setPinToRemove] = useState<number | null>(null);
  const [deletionConfirmed, setDeletionConfirmed] = useState(false);

  useEffect(() => {
    if (deletionConfirmed) {
      unPin(pinToRemove, null, unityPin ? 2 : 1);
      setDeletionConfirmed(false);
    }
  }, [deletionConfirmed]);

  useEffect(() => {
    setPinToRemove(null);
  }, [data]);

  return (
    <div className={classNames(unityPin ? "w-[16vw] 2xl:w-[18vw]" : "2xl:w-full 2xl:min-w-[150px]", "h-full p-0.5 rounded-md gradient flex items-center relative")}>
      <div className="w-full rounded-md h-full bg-black px-2 flex flex-col items-center pt-1">
        <div className="flex justify-between w-full">
          <div className="leading-3">
            <span className="text-sm 3xl:text-lg">{data.data[0].tagNameIdentifier}</span>
          </div>
          <div
            className="ml-auto flex cursor-pointer"
            onClick={() => setPinToRemove(data.data[0].id)}
          >
            <FiX size={20} />
          </div>
        </div>
        <Bar meta={data.data[0]} barType={"unityPin"} />
      </div>
      {pinToRemove !== null && (
        <div className="absolute w-full h-full bg-dark bg-opacity-75 flex items-center justify-center right-0 rounded-md">
          <div className="flex gap-5">
            <button className="btn-sec" onClick={() => setPinToRemove(null)}>Keep</button>
            <button className="btn-danger" onClick={() => setDeletionConfirmed(true)}>Remove</button>
          </div>
        </div>
      )}
    </div>
  );
}
