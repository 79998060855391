import classNames from "utilities/ClassNames";
import Area from "../../pages/Dashboard/AllDynamicWidgets/charts/Area";
import Gauge from "../../pages/Dashboard/AllDynamicWidgets/charts/Gauge";
import GaugeBar from "../../pages/Dashboard/AllDynamicWidgets/charts/GaugeBar";
import Kpi from "../../pages/Dashboard/AllDynamicWidgets/charts/Kpi";
import Large from "../../pages/Dashboard/AllDynamicWidgets/charts/Large";
import OneGauge from "../../pages/Dashboard/AllDynamicWidgets/charts/OneGauge";
import Column from "pages/Dashboard/AllDynamicWidgets/charts/Column";
import { cn } from "@/lib/utils";

export default function TabBody({
  screen,
  tabs,
  currentTab,
  setScreen,
  type,
}: any) {
  return (
    <div className={cn("relative px-3 lg:pb-3 xl:pb-3 2xl:pb-1", "h-[90%]")}>
      {tabs[currentTab]?.configured && tabs[currentTab].type === "large" && (
        <Large tab={tabs[currentTab]} />
      )}
      {tabs[currentTab]?.configured && tabs[currentTab].type === "kpi" && (
        <Kpi tab={tabs[currentTab]} />
      )}
      {screen !== "configuration" &&
        screen !== "newTab" &&
        tabs[currentTab]?.configured &&
        tabs[currentTab].type === "area" && (
          <Area tab={tabs[currentTab]} screen={screen} type={type} />
        )}
      {screen !== "configuration" &&
        screen !== "newTab" &&
        tabs[currentTab]?.configured &&
        tabs[currentTab].type === "gauge" && (
          <Gauge tab={tabs[currentTab]} screen={screen} />
        )}
      {screen !== "configuration" &&
        screen !== "newTab" &&
        tabs[currentTab]?.configured &&
        tabs[currentTab].type === "column" && (
          <Column tab={tabs[currentTab]} screen={screen} />
        )}
      {screen !== "configuration" &&
        screen !== "newTab" &&
        tabs[currentTab]?.configured &&
        tabs[currentTab]?.type === "gaugeBar" && (
          <GaugeBar tab={tabs[currentTab]} screen={screen} />
        )}
      {screen !== "configuration" &&
        screen !== "newTab" &&
        tabs[currentTab]?.configured &&
        tabs[currentTab]?.type === "oneGaugeBar" &&
        type !== 3 && (
          <div className="h-full bg-dark rounded flex items-center justify-center">
            <OneGauge tab={tabs[currentTab]} screen={screen} />
          </div>
        )}
      {screen !== "configuration" &&
        screen !== "newTab" &&
        tabs[currentTab]?.configured &&
        tabs[currentTab]?.type === "oneGaugeBar" &&
        type === 3 && <OneGauge tab={tabs[currentTab]} screen={screen} />}
      {!tabs[currentTab]?.configurations && (
        <div className="flex justify-center items-center gap-3 h-full">
          <div className={classNames("")}>
            Tab is empty,{" "}
          </div>
          <div
            onClick={() => setScreen("configuration")}
            className={classNames(
              "cursor-pointer text-green"
            )}
          >
            Start Configuring
          </div>
        </div>
      )}
    </div>
  );
}
