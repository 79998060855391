const calcBarLength = (value: number, minValue: number, maxValue: number) => {
  const linearInterpolation = ((value - minValue) / (maxValue - minValue));
  let percentage = linearInterpolation * 100;
  percentage = Math.round(percentage);
  if (percentage > 100) {
    percentage = 100;
  }
  return percentage.toString() + '%';
};

export default calcBarLength;
