import { ERROR_MESSAGE } from "../utils/constants";
import { objectErrorsArray } from "../utils/objectErrors";

export const errorHandling = (error: any) => {
    if (!error) {
      return ["Please try again later."];
    }
    if (error.response?.status === 403) {
      return ["You are not authorized user."];
    }
  
    if (error?.response && error.response?.data && error.response.data?.ErrorId) {
      let errors: string[] = [];
      errors = [`Error Id: ${error.response.data.ErrorId}`];
      return errors;
    }
  
    if (error.response && error.response.data && error.response.data.errors) {
      let errors: string[] = [];
      errors = objectErrorsArray(Object.values(error.response.data.errors));
      return errors;
    }
    if (
      error.response &&
      error.response.data &&
      error.response.data.identityErrors
    ) {
      let errors: string[] = [];
      errors = identityErrorsArray(
        Object.values(error.response.data.identityErrors)
      );
      return errors;
    }
  
    if (
      error.response &&
      error.response.data &&
      error.response.data.Validations
    ) {
      return error.response.data.Validations;
    }
  
    const resMessage =
      (error.response && error.response.data && error.response.data?.error) ||
      (error.response && error.response.data) ||
      error.response.data?.error ||
      error.errors ||
      error.toString();
  
    if (ERROR_MESSAGE[resMessage]) {
      return [ERROR_MESSAGE[resMessage]];
    } else if (
      error.response &&
      error.response.data &&
      error.response.data?.error_description
    ) {
      return [error.response.data.error_description];
    } else {
      return [resMessage];
    }
  };

function identityErrorsArray(arg0: unknown[]): string[] {
    throw new Error("Function not implemented.");
}
