import React, { useState, useEffect } from "react";
import { validateExpression } from "api/expression";
import ExpressionCalculation from "components/ExpressionCalculator/ExpressionCalculation";
import actionApi from "components/modal/ActionModal/domain/actionApi";
import { getExpressionById } from "api/expression";
import { expressionsConfig } from "./config/expressionConfiguration";
import { ExpressionCalculatorProps } from "./domain/types";

const ExpressionCalculator: React.FC<ExpressionCalculatorProps> = ({ actionType, expId, isLoading, setIsLoading, expInfo, isSubmit, setSubmit, setModalMessage, triggerRefresh }) => {
  const [validationErrors, setValidationErrors] = useState<any>([]);
  const [validationLoading, setValidationLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [validationResult, setValidationResult] = useState(null);

  const [assetType, setAssetType] = useState<any>(0);
  const [type, setType] = useState<any>(null);
  const [id, setId] = useState<any>(0);
  const [expression, setExpression] = useState<any>("");
  const [expression1, setExpression1] = useState<any>("");
  const [expression2, setExpression2] = useState<any>("");
  const [expression3, setExpression3] = useState<any>("");
  const [equipmentId, setEquipmentId] = useState<any>(null);
  const [unitId, setUnitId] = useState<any>(null);
  const [trainId, setTrainId] = useState<any>(null);
  const [plantId, setPlantId] = useState<any>(1);
  const [equipmentName, setEquipmentName] = useState<any>(null);
  const [unitName, setUnitName] = useState<any>(null);
  const [trainName, setTrainName] = useState<any>(null);
  const [plantName, setPlantName] = useState<any>(null);
  const [expressionMessage, setExpressionMessage] = useState<any>("");
  const [message, setMessage] = useState<any>('');
  const [expData, setExpData] = useState<any>();

  const { name, desc, seqOrder, minRange, maxRange, uom, minValue, maxValue } = expInfo;

  useEffect(() => {
    if (expId) {
      setIsLoading(true);
      (async () => {
        const response: any = await getExpressionById(expId);
        if (response.status === 200) {
          setExpData(response.data);
        }
      })();
      setIsLoading(false);
    }
  }, [expId]);

  useEffect(() => {
    if (expData) {
      setAssetType(
        expData?.plantId
          ? "1"
          : expData?.trainId
            ? "2"
            : expData?.unitId
              ? "3"
              : "4"
      );
      setType(expData.type);
      setId(expData.id);
      setExpression(expData.expression);
      setExpression1(expData.expression1);
      setExpression2(expData.expression2);
      setExpression3(expData.expression3);
      setEquipmentId(expData.equipmentId);
      setUnitId(expData.unitId);
      setTrainId(expData.trainId);
      setPlantId(expData.plantId);
      setEquipmentName(expData.equipmentName);
      setUnitName(expData.unitName);
      setTrainName(expData.trainName);
      setPlantName(expData.plantName);
    }
  }, [expData]);

  useEffect(() => {
    if (unitId === "") {
      setUnitId(null);
    }
  }, [unitId]);

  useEffect(() => {
    if (unitId === "") {
      setTrainId(null);
    }
  }, [trainId]);

  useEffect(() => {
    if (equipmentId === "") {
      setEquipmentId(null);
    }
  }, [equipmentId]);

  useEffect(() => {
    const fetchData = async () => {
      const expressionInfo = {
        name,
        desc,
        assetType: parseInt(assetType),
        type,
        id,
        seqOrder: parseInt(seqOrder),
        minRange,
        maxRange,
        expression,
        expression1,
        expression2,
        expression3,
        equipmentId,
        unitId,
        trainId,
        plantId,
        uom,
        minValue,
        maxValue,
        equipmentName,
        unitName,
        trainName,
        plantName,
      };
      const apiCall = expressionsConfig[actionType];
      setIsLoading(true);
      const response = await actionApi({ tag: expressionInfo, api: apiCall });
      if (response?.message === 'failure') {
        setModalMessage({ type: 'error', message: response.value });
      }
      if (response?.message === 'success') {
        setModalMessage({ type: 'success', message: 'Expression updated' });
        triggerRefresh();
      }
      setIsLoading(false);
    };
    if (isSubmit) {
      fetchData();
      setSubmit(false);
    }
  }, [isSubmit]);


  const handleValidation = async () => {
    setValidationErrors(null);
    setValidationLoading(true);
    setErrors([]);
    const response: any = await validateExpression({
      name,
      desc,
      assetType,
      type,
      id,
      seqOrder,
      minRange,
      maxRange,
      expression,
      expression1,
      expression2,
      expression3,
      equipmentId,
      unitId,
      trainId,
      plantId,
      uom,
      minValue,
      maxValue,
      equipmentName,
      unitName,
      trainName,
      plantName,
    });
    if (response.status === 200) {
      setMessage("Expression has been validated successfully.");
      setValidationResult(response.data);
      return setValidationLoading(false);
    }
    if (response.status === 400) {
      if (response.data.Validations) {
        setValidationErrors(response.data.Validations);
      } else {
        setValidationErrors([response.data.title]);
      }
      setValidationLoading(false);
    }
  };

  return (
    <ExpressionCalculation
      loading={isLoading}
      errors={errors}
      message={message}
      editMode={false}
      handleValidation={handleValidation}
      setExpression={setExpression}
      expression={expression}
      expression1={expression1}
      setExpression1={setExpression1}
      expression2={expression2}
      setExpression2={setExpression2}
      expression3={expression3}
      setExpression3={setExpression3}
      equipmentId={equipmentId}
      setEquipmentId={setEquipmentId}
      unitId={unitId}
      setUnitId={setUnitId}
      trainId={trainId}
      setTrainId={setTrainId}
      assetType={assetType}
      setAssetType={setAssetType}
      type={type}
      setType={setType}
      setPlantId={setPlantId}
      expressionMessage={expressionMessage}
      validationResult={validationResult}
      validationLoading={validationLoading}
      setValidationLoading={setValidationLoading}
      setValidationResult={setValidationResult}
      setMessage={setMessage}
      validationErrors={validationErrors}
    />
  );
};

export default ExpressionCalculator;
