import { ChartDataFetchParams, Point } from "./types";
import { getTagFutureData, getExpressionFutureData } from "api/dashboard";

const getChartFutureData = async ({ type, itemId, isDaily }: ChartDataFetchParams): Promise<{ predictionList?: Point[]; futureValueSet?: number[]; }> => {

  try {
    const response: any = type === "tag"
      ? await getTagFutureData(itemId, isDaily ? "D" : "H")
      : await getExpressionFutureData(itemId, isDaily ? "D" : "H");

    const { data, status } = response || {};

    if (data && status === 200) {
      const predictionList = data
        .map((item: any) => ({
          x: new Date(item.createdOn).getTime(),
          y: type === 'tag' ? item.tagValue : item.expressionValue,
        }));

      const futureValueSet = data
        .map((item: any) => type === 'tag' ? item.tagValue : item.expressionValue);

      return { predictionList, futureValueSet };
    }
    const predictionList: Point[] = [];
    const futureValueSet: number[] = [];
    return { predictionList, futureValueSet };
  } catch (e) {
    console.error('Error fetching the request:', e);
    throw e;
  }
};

export default getChartFutureData;
