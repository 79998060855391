import { useEffect } from "react";
import watermarkBg from "assets/watermarkBg.png";

const Watermark = () => {
  useEffect(() => {
    const createWatermarkDiv = () => {
      const div = document.createElement("div");
      div.className = "watermark-div";
      div.style.position = "fixed";
      div.style.top = "0";
      div.style.left = "0";
      div.style.width = "100vw";
      div.style.height = "100vh";
      div.style.zIndex = "9999";
      div.style.pointerEvents = "none";
      div.style.opacity = "0.06";
      div.style.backgroundImage = `url(${watermarkBg})`;
      div.style.backgroundPosition = "30px 70px";
      div.style.backgroundRepeat = "repeat";
      div.style.backgroundSize = "400px auto";
      document.body.appendChild(div);
      return div;
    };

    // Create watermark div initially
    let watermarkDiv = createWatermarkDiv();

    // Monitor the DOM for changes
    const observer = new MutationObserver(() => {
      if (!document.body.contains(watermarkDiv)) {
        watermarkDiv = createWatermarkDiv(); // Re-add watermark div if removed
      }
    });

    observer.observe(document.body, { childList: true, subtree: false });

    // Clean up observer on unmount
    return () => {
      observer.disconnect();
      if (watermarkDiv) watermarkDiv.remove();
    };
  }, []);

  return null; // No need to render anything directly in React
};

export default Watermark;
