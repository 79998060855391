import BorderGradient from "components/shared/BorderGradient/BorderGradient";
import useScreen from "hooks/useScreen";
import classNames from "utilities/ClassNames";
import { getTagData } from "api/tags";
import { useEffect, useState } from "react";

const TicketItem = ({ title, text, underline }: any) => {
  const { isScaled } = useScreen();

  return (
    <>
      <div className="flex gap-1 flex-wrap">
        <h2 className={classNames(isScaled ? "text-lg" : "text-xl", "font-bold w-full")}>{title}</h2>
        <div className="w-full">{text}</div>
      </div>
      {underline && <span className="m-0 p-0 w-full h-0.5 bg-gray" />}
    </>
  );
};

const ShowTicketModal = ({ selectedTag }: any) => {
  const { isScaled } = useScreen();
  const { value } = selectedTag;

  const [tagName, setTagName] = useState('');
  useEffect(() => {
    const fetchData = async () => {
      const response = await getTagData([value.tagId]);
      if (response.status === 200) {
        setTagName(response.data[0].tagNameIdentifier);
      }
    };
    fetchData();
  }, [selectedTag]);

  return (
    <div className="w-full h-full bg-dark rounded-lg">
      <BorderGradient>
        <div className={classNames(isScaled ? "p-4 gap-6" : "p-6 gap-10", "flex flex-col rounded-md z-50 bg-dark")}>
          <TicketItem title="Tag" text={tagName} underline={true} />
          <TicketItem title="Title" text={value.title} underline={true} />
          <TicketItem title="Description" text={value.description || '...'} tag={value.tagId} underline={true} />
          <TicketItem title="Resolution" text={value.comments || '...'} tag={value.tagId} />
        </div>
      </BorderGradient>
    </div>
  );
};

export default ShowTicketModal;
