import { Outlet } from "react-router-dom";
import Header from "./Header";
import { cn } from "@/lib/utils";

export default function Layout() {
  return (
    <div className={cn("pb-10 2xl:pb-0", "relative overflow-hidden w-full h-full max-w-[2800px] mx-auto")}>
      <Header />
      <Outlet />
    </div>
  );
}
