import React, { useState, useCallback } from "react";
import { unitNames, sampleFile, sampleFileFormat } from "./utils/constants";
import { uploadTag } from "api/tags";
import { v4 as uuidv4 } from "uuid";
import ProgressPopup from "./ProgressPopup";
import { FileUploaderProps } from "./domain/types";

const TextBreaker: React.FC = () => {
  return (<span className="text-green">@</span>);
};

const FileUploader: React.FC<FileUploaderProps> = ({ setMessage }) => {

  const [fileDetails, setFileDetails] = useState<any>({
    fileName: "",
    errors: "",
    value: "",
  });
  const [fileLoading, setFileLoading] = useState<any>();
  const [progressPopup, setProgressPopup] = useState<any>();
  const [uuid, setId] = useState<string>();
  const [abortController, setAbortController] = useState<any>();

  const handleFile = (event: any) => {
    const fileUploaded = event.target.files[0];
    if (!fileUploaded && !fileDetails?.fileName) {
      setFileDetails({ fileName: "", errors: "required", value: "" });
    } else if (fileUploaded) {
      if (!["text/csv", ".csv"].includes(fileUploaded.type)) {
        setFileDetails({ fileName: "", errors: "fileType", value: "" });
        setMessage({ type: 'error', message: "Please upload only csv file" });
      } else {
        setFileDetails({
          fileName: fileUploaded.name,
          errors: "",
          value: event.target.files[0],
        });
        setMessage({ type: 'none', mesage: '' });
      }
    }
  };

  const handleFileSubmit = async (event: any) => {
    event.preventDefault();
    setFileLoading(true);
    const formData = new FormData();
    const _id = uuidv4();
    formData.append("File", fileDetails.value);
    formData.append("Id", _id);
    const controller = new AbortController();
    setAbortController(controller);
    setProgressPopup(true);
    setId(_id);
    const response: any = await uploadTag(formData, controller.signal);
    if (response.status === 200) {
      setFileLoading(false);
      setMessage({ type: 'success', message: 'File uploaded Successfully' });
      setAbortController(null);
      return setProgressPopup(false);
    }
    else {
      setMessage({ type: 'error', message: "Something went wrong" });
      setProgressPopup(false);
    }
    setFileLoading(false);
  };

  return (
    <>
      <form
        onSubmit={handleFileSubmit}
        className="grid gap-4 py-4"
      >
        {/* PI Name */}
        <div className="gradient p-0.5 rounded-md relative">
          <div className="absolute py-1 px-2 bg-dark -top-4 left-2 text-[16px]">
            File Upload
          </div>
          <div className="flex flex-col gap-2 bg-dark p-3 pt-5 rounded-md">
            <input
              name="tag_csv"
              onChange={handleFile}
              accept={"text/csv,.csv"}
              type="file"
              placeholder="PI Name"
              className="col-span-3 input text-[16px]"
              required
            />
            <div className="mt-2">
              <button
                className="btn"
                type="submit"
              >
                Upload
              </button>
            </div>
            <div className="file-upload">
              <div className="leading-5 mt-2">
                <label>Supports: </label>
                <div className="inline-block ml-1">
                  <span className="text-green">.csv</span>{" "}
                  files only
                </div>
              </div>
              <div className="leading-5 mt-2">
                <label className="">TagName:</label>
                <span className="ml-1">PITagName</span>
                <label className="ml-2">
                  ExpressionName:
                </label>
                <span className="ml-2">
                  {unitNames.map((name: string, index: number) => (
                    <React.Fragment key={index}>
                      {name === 'ExprName' || name === 'PlantName' ? name : `${name} or ExprName`}
                      {index !== unitNames.length - 1 && <TextBreaker />}
                    </React.Fragment>
                  ))}
                </span>
              </div>
              <div></div>
              <div className="leading-5 mt-2">
                <label>Sample File Format: </label>
                {sampleFileFormat}
              </div>
            </div>
            <h1 className="text-[16px]">
              Sample File
            </h1>
            <pre className="bg-white text-dark p-3 rounded-md text-sm">
              {sampleFile}
            </pre>
          </div>
        </div>
      </form>
      {progressPopup && <ProgressPopup setProgressPopup={setProgressPopup} setFileLoading={setFileLoading} setId={setId} uuid={uuid} abortController={abortController} />}
    </>
  );
};

export default FileUploader;