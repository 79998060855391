import Select from "components/widgets/Select";
import { ChangeEvent, useEffect, useState } from "react";
import classNames from "utilities/ClassNames";
import { chartConfigurationsProps, configDataProps } from "../../pages/Dashboard/AllDynamicWidgets/domain/types";
import { handleChange } from "../../pages/Dashboard/AllDynamicWidgets/config/handleTabConfigNameChange";
import { Item } from "types/common";
import getUniqueUomValues from "utilities/getUniqueUOM";
import PopoverContainer from "components/Popover/PopoverContainer";
import { getAllTags } from "api/tags";
export default function AllConfiguration({
  graph,
  setgraph,
  graphtype,
}: any) {
  const [chartData, setchartData] = useState<configDataProps[] | null>(null);
  const [allUom, setAllUom] = useState<any>([]);
  const [defaultUom, setDefaultUom] = useState<any>([]);
  const [uomFilters, setUomFilters] = useState<any>([]);

  const defaultChartData = [
    { id: '', name: '', type: '', value: '' },
    { id: '', name: '', type: '', value: '' },
    { id: '', name: '', type: '', value: '' },
    { id: '', name: '', type: '', value: '' }
  ];

  const defaultGraphData = {
    k1: { id: '', name: '', type: '', value: '' },
    k2: { id: '', name: '', type: '', value: '' },
    k3: { id: '', name: '', type: '', value: '' },
    k4: { id: '', name: '', type: '', value: '' },
  };

  const onClear = () => {
    setchartData(defaultChartData);
    setgraph(defaultGraphData);
  };

  useEffect(() => {
    if (graphtype === "area") {
      setchartData([graph]);
    } else {
      setchartData([graph.k1, graph.k2, graph.k3, graph.k4]);
    }
  }, [graph]);

  useEffect(() => {
    if (defaultUom.length > 0) {
      setUomFilters([...new Set(defaultUom)]);
      setDefaultUom([]);
    }
  }, [defaultUom]);

  useEffect(() => {
    const setAllTags = async () => {
      const response = await getAllTags();
      if (response.status === 200) {
        const uniqueUom = getUniqueUomValues(response.data);
        const nonEmptyUom = uniqueUom.filter((item) => item.length > 0);
        const processedUom = nonEmptyUom.map((single) => {
          return {
            value: single,
            label: single
          };
        });
        setAllUom(processedUom);
      }
    };
    setAllTags();
  }, [graphtype]);

  const filterItems = [
    {
      title: !uomFilters[0] ? 'Units' : uomFilters[0],
      filters: uomFilters,
      options: allUom,
      setFilters: setUomFilters,
    }
  ];

  return (
    <form className="flex flex-col gap-3 w-11/12 mx-auto">
      {graphtype === 'column' && <div className="flex items-center gap-1.5 px-2 pb-1 border-gray border-b-2">
        <h3 className="font-bold text-lg w-1/2">Choose UOM:</h3>
        <PopoverContainer items={filterItems} onClear={onClear} />
      </div>}
      {chartData && chartData.map(
        (data: configDataProps, index: number) =>
          data && (
            <div key={index} className="flex flex-1 gap-3 w-full items-center">
              <div className="w-1/2">
                <input
                  placeholder="Name"
                  className={classNames(
                    "input"
                  )}
                  name="name"
                  id="name"
                  type="text"
                  required
                  value={data?.name}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const updatedKey =
                      graphtype === "area" ? "name" : `k${index + 1}.name`;
                    handleChange(updatedKey, e.target.value, graphtype, setgraph);
                  }}
                />
              </div>
              <div className="w-1/2">
                <Select
                  key={data?.id}
                  id={data?.id}
                  hasFilters={graphtype === 'column'}
                  filters={uomFilters}
                  setDefaultUom={setDefaultUom}
                  selectedValue={data?.value}
                  type={data?.type}
                  clear={() => {
                    setgraph((prev: chartConfigurationsProps | configDataProps) => {
                      if (graphtype === "area") {
                        return {
                          id: "",
                          name: "",
                          type: "",
                          value: "",
                        };
                      } else {
                        return {
                          ...prev,
                          [`k${index + 1}`]: {
                            id: "",
                            name: "",
                            type: "",
                            value: "",
                          },
                        };
                      }
                    });
                  }}
                  select={(item: Item) => {
                    setgraph((prev: any) => {
                      if (graphtype === "area") {
                        return {
                          ...prev,
                          id: item.id,
                          value: item,
                          type: item.tagNameIdentifier ? "tags" : "expressions",
                        };
                      } else {
                        return {
                          ...prev,
                          [`k${index + 1}`]: {
                            ...prev[`k${index + 1}`],
                            id: item.id,
                            value: item,
                            type: item.tagNameIdentifier
                              ? "tags"
                              : "expressions",
                          },
                        };
                      }
                    });
                  }}
                />
              </div>
            </div>
          )
      )}
    </form>
  );
}
