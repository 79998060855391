import { ColorCodes } from "types/common";

const genThresholdRange = (colorCodes: ColorCodes) => {
  return {
    BG: [colorCodes.white],
    value: [100],
    shadowBG: ["transparent"],
  };
};

export default genThresholdRange;