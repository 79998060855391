import { cn } from "@/lib/utils";
import { ConfirmationDialogProps } from "pages/Dashboard/AllDynamicWidgets/domain/types";

export const generateConfirmationDialog = ({
  title,
  content,
  onCancel,
  onRemove,
}: ConfirmationDialogProps) => (
  <div className="relative w-full h-full p-3">
    <div className="flex flex-col gap-4 items-center justify-center absolute inset-0 w-full h-full">
      <h1 className={cn("text-4xl"," font-bold")}>{title}</h1>
      <p >{content}</p>
      <div className="flex gap-3 items-center mt-5">
        <div className="btn-sec" onClick={onCancel}>
          Cancel
        </div>
        <div className="btn-danger" onClick={onRemove}>
          Remove
        </div>
      </div>
    </div>
  </div>
);
