import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { FiEdit2, FiUser } from "react-icons/fi";
import "react-phone-input-2/lib/style.css";
import Edit from "./edit";
import Passwords from "./passwords";
import { getUserInfo, updateProfile,mapPicture,uploadPicture } from "api/user";
import useApp from "hooks/useApp";
import { appear } from "./config/appear";
import { slide } from "./config/slide";

export default function Profile({ closeMenu, showProfileMenu }: any) {
  const { user, setUser } = useApp();
  const [screen, setScreen] = useState("default");
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<any>(null);

  const refreshUser = async () => {
    const response: any = await getUserInfo();
    if (response?.status === 200) {
      setUser(response.data);
    }
  };

  const updateHandler = async (data: any,fileId:string) => {
    setLoading(true);
    
    if(fileId)
      await mapPicture({fileId}); 

    const response2 = await updateProfile(data);
    if (response2.status === 200) {
      refreshUser();
    } else {
      setError("There was an issue updating profile please try later");
    }
    setLoading(false);
  };




  return (
    <motion.div
      className="fixed z-50 inset-0 w-full h-full"
      initial="exit"
      animate={showProfileMenu ? "enter" : "exit"}
      variants={appear}
    >
      <motion.div
        initial="exit"
        animate={showProfileMenu ? "enter" : "exit"}
        variants={appear}
        className="fixed z-10 inset-0 w-full bg-black/90"
        onClick={closeMenu}
      />
      <motion.div
        initial="exit"
        animate={showProfileMenu ? "enter" : "exit"}
        variants={slide}
        className="absolute h-full right-0 top-0 bottom-0 w-[300px] z-20 bg-gray"
      >
        {screen === "edit" && (
          <Edit
            setScreen={setScreen}
            user={user}
            update={updateHandler}
            uploadPicture={uploadPicture}
            loading={loading}
            error={error}
          />
        )}
        {screen === "default" && user && (
          <>
            {user && (
              <div className="h-full w-full flex flex-col">
                {/* info */}
                <div className="gradient p-0.5 rounded-md m-3">
                  <div className="bg-gray rounded-md p-3 flex flex-col gap-7 pt-5">
                    {/* Avatar */}
                    <div className="flex justify-center items-center relative">
                      {user?.picture && (
                        <Avatar className="w-40 h-40 rounded-full">
                          <AvatarImage
                            src={user?.picture}
                            alt="Avatar"
                          />
                          <AvatarFallback></AvatarFallback>
                        </Avatar>
                      )}
                      {!user?.picture && (
                        <div className="w-40 h-40 grid place-content-center bg-dark rounded-full">
                          <FiUser size={80} />
                        </div>
                      )}
                      <div
                        onClick={() => setScreen("edit")}
                        className="w-7 h-7 rounded-full bg-green/50 hover:bg-green duration-300 text-white absolute bottom-0 right-16 cursor-pointer flex items-center justify-center"
                      >
                        <FiEdit2 />
                      </div>
                    </div>
                    <div className="flex flex-col gap-0.5">
                      <p className="text-white/70 text-[12px]">Name</p>
                      <p className="capitalize">{user.name}</p>
                    </div>
                    <div className="flex flex-col gap-0.5">
                      <p className="text-white/70 text-[12px]">Email</p>
                      <p>{user.email}</p>
                    </div>
                    {user.phoneNumber && (
                      <div className="flex flex-col gap-0.5">
                        <p className="text-white/70 text-[12px]">
                          Phone number
                        </p>
                        <p>{user.phoneNumber ? user.phoneNumber : "N/A"}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {screen === "passwords" && <Passwords setScreen={setScreen} />}
      </motion.div>
    </motion.div>
  );
}
