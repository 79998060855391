import SelectInput from "components/shared/SelectInput/SelectInput";
import { useEffect, useState } from "react";
import { getAllTrains } from "api/trains";
import { getUnitsByTrainId } from "api/units";

const AssetsSelects = ({ page, ancestors, setAncestors, type }: any) => {
  const [selectedTrainId, setSelectedTrainId] = useState<number | null>(null);
  const [selectedUnitId, setSelectedUnitId] = useState<number | null>(null);
  const [selectedTrain, setSelectedTrain] = useState<any>();
  const [selectedUnit, setSelectedUnit] = useState<any>(null);
  const [trainOptions, setTrainOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);

  useEffect(() => {
    setSelectedTrainId(ancestors.trainId);
    setSelectedUnitId(ancestors.unitId);
  }, [ancestors]);

  useEffect(() => {
    if (selectedTrainId !== null) {
      const fetchTrains = async () => {
        const response = await getAllTrains();
        if (response.status === 200) {
          setTrainOptions(response.data);
        }
      };
      fetchTrains();
    }

    if (selectedUnitId !== null) {
      const fetchUnits = async (trainId: any) => {
        const response = await getUnitsByTrainId(trainId);
        if (response.status === 200) {
          setUnitOptions(response.data);
        }
      };
      fetchUnits(selectedTrainId);
    }
  }, [selectedTrainId, selectedUnitId]);

  const onTrainChange = (e: any) => {
    setSelectedTrainId(e);
    setAncestors({
      trainId: e,
      unitId: null,
    });
  };

  const onUnitChange = (e: any) => {
    setAncestors({
      trainId: selectedTrainId,
      unitId: e,
    });
  };

  useEffect(() => {
    const trainValue = trainOptions.find((option: any) => option.id === selectedTrainId);
    setSelectedTrain(trainValue);
  }, [selectedTrainId, trainOptions]);

  useEffect(() => {
    const unitValue = unitOptions.find((option: any) => option.id === selectedUnitId);
    if (unitValue) {
      setSelectedUnit(unitValue);
    }
    else {
      setSelectedUnit(null);
    }
  }, [selectedUnitId, unitOptions]);

  return (
    type === 'edit' ? (
      <div className="flex gap-4">
        {trainOptions.length > 0 && selectedTrain && <SelectInput label="Trains" options={trainOptions} onValueChange={onTrainChange} value={selectedTrain.name} />}
        {page === 'equipments' && unitOptions.length > 0 && <SelectInput label="Units" options={unitOptions} onValueChange={onUnitChange} value={selectedUnit?.name || 'Select Units'} />}
      </div>
    ) : null
  );
};

export default AssetsSelects;