import splitLabels from "./splitLables";
import createGradient from "./createGradient";

const getBarChartData = ({ names, maxLabelsLength, data, maxRange }: any) => {
  const dataValues = data.map((single: any) => {
    if (single.value !== null) {
      return single.value.toFixed(2);
    }
    return null;
  });
  const splittedNames = splitLabels(names, maxLabelsLength);
  return {
    labels: splittedNames,
    datasets: [
      {
        label: data[0]?.uom || '',
        data: dataValues,
        borderColor: 'black',
        borderWidth: 1,
        borderRadius: 10,
        backgroundColor: (context: any) => {
          const ctx = context.chart.ctx;
          const chartArea = context.chart.chartArea;
          return dataValues.map((singleValue: any) => {
            if (singleValue > maxRange) {
              return createGradient(ctx, chartArea, '#FEA855', '#FA5C5F');
            }
            return createGradient(ctx, chartArea, '#64F1CE', '#489BE7');
          });
        }
      },
    ],
  };
};

export default getBarChartData;