/* eslint-disable react/display-name */
import { SignalRProvider } from "hooks/useSignalR";
import { PinsProvider } from "hooks/usePins";

const combineComponents = (...components: any[]) => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      return ({ children }: { children: any }) => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      };
    },
    ({ children }: { children: any }) => <>{children}</>
  );
};

const providers = [SignalRProvider, PinsProvider];
export const ContextProvider = combineComponents(...providers);
