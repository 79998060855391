import { getTagData } from "api/tags";
import { useEffect, useState } from "react";
import useData from "hooks/useData";
import { getExpressionData } from "api/expression";
import Column from "components/charts/Column/Column";
import { Item } from "types/common";

const ColumnChart = ({ tab }: any) => {
  const { pastDate } = useData();
  const [data, setData] = useState<Item[]>([]);
  const { configurations } = tab;
  const configEntries = Object.entries(configurations);
  const items = configEntries.map((entry: any) => {
    const info = entry[1];
    return info;
  });
  const [names, setNames] = useState<any>([]);

  useEffect(() => {
    const namesEntries = Object.entries(tab.configurations);
    const namesOfBars = namesEntries.map((single) => {
      const item: any = single[1];
      if (item.name !== '') {
        return item.name;
      }
      return item.value.name;
    });
    const filteredNames = namesOfBars.filter((name) => name !== undefined);
    setNames(filteredNames);
  }, [tab]);

  useEffect(() => {
    const fetchData = async () => {
      const newData = await Promise.all(
        items.map(async (item) => {
          if (item.type === 'tags') {
            const tagResponse = await getTagData([item.id], pastDate);
            return tagResponse.data[0];
          } else {
            const expressionResponse = await getExpressionData([item.id], pastDate);
            return expressionResponse.data[0];
          }
        })
      );
      setData(newData);
    };
    fetchData();
    const intervalId = setInterval(fetchData, 15000);
    return () => clearInterval(intervalId);
  }, [tab, pastDate]);

  return (
    <div className="h-full w-full bg-dark rounded-lg">
      <Column rawData={data} names={names} />
    </div>
  );
};

export default ColumnChart;